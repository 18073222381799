import React, { ReactElement, useEffect, useState } from 'react';
import { UserContract } from '../data';
import { GSnackbar } from '../../../shared/ui';
import { SnackbarContent, StatefulResponse } from '../../../shared/models';

export function ContractPageSnackbar(
  { signatureState, isAgreed, submitTs }: {
    signatureState: StatefulResponse<UserContract>,
    isAgreed: boolean,
    submitTs: number
  },
): ReactElement {
  const [content, setContent] = useState<SnackbarContent>({
    open: false, text: '', severity: 'info',
  });

  const closeNotification = () => setContent({ ...content, open: false });

  useEffect(() => {
    if (!isAgreed && submitTs > 0) {
      setContent({
        open: true,
        text: 'You must confirm you have read and agree to the terms of the contract.',
        severity: 'error',
      });
      setTimeout(closeNotification, 10000);
    }
    if (
      !signatureState.isLoading
      && signatureState.response != null
      && signatureState.response?.success) {
      setContent({
        open: true,
        text: 'Contract agreed successfully',
        severity: 'success',
      });
      setTimeout(closeNotification, 2000);
    } else if (!signatureState.isLoading
      && signatureState.response != null
      && !signatureState.response?.success) {
      setContent({
        open: true,
        text: 'Something went wrong',
        severity: 'error',
      });
      setTimeout(closeNotification, 10000);
    }
  }, [signatureState, isAgreed, submitTs]);

  return <GSnackbar content={content} />;
}
