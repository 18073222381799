/**
 * Parse date into
 * @param date
 */

export const roundToOneDecimal = (number: number): number => {
  const rounded = Math.round((number + Number.EPSILON) * 10) / 10;
  return rounded;
};
export const roundToTwoDecimals = (number: number): number => {
  const rounded = Math.round((number + Number.EPSILON) * 100) / 100;
  return rounded;
};
