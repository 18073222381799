import styled from 'styled-components';
import { TextDark, TextLight } from '../../theme/text.styles';

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.palette.lightGray.main};
  align-items: center;
  // end of the row margin to make the FixedSizeList row look correct
  margin-right: 1rem;
`;

export const FieldWide = styled(TextDark)`
  margin-left: 1rem;
  width: 40%;
`;

export const FieldNarrow = styled(TextDark)`
  margin-left: 1rem;
  width: 20%;
`;

export const FieldTiny = styled(TextDark)`
  margin-left: 1rem;
  width: 10%;
`;
