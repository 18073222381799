import styled from 'styled-components';
import { FormControl } from '@mui/material';

export const SelectFormControl = styled(FormControl)`
  display: flex;
  flex-wrap: wrap;
`;

export const CountrySelectFormControl = styled(FormControl)`
  display: flex;
  text-align: left;
  margin: 0;
`;
