import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { apiSlice } from '../../api';
import { loadAuthUser } from '../../auth';
import { Logger } from '../../../shared/utils';

const onboardingAPI = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    onboardingDone: builder.mutation<string, void>({
      query: () => ({
        url: '/api/users/me/onboarding-done/',
        method: 'POST',
      }),
    }),
    onboardingDoneAndUpdateUser: builder.mutation<string, void>({
      async queryFn(_, _queryApi, _extraOptions, fetchWithBQ) {
        const result = await fetchWithBQ({
          url: '/api/users/me/onboarding-done/',
          method: 'POST',
        });
        const authUser = await loadAuthUser();
        Logger.log(`authUser reloaded ${JSON.stringify(authUser)}`);
        return result.data
          ? { data: result.data as string }
          : { error: result.error as FetchBaseQueryError };
      },
    }),
  }),
});

export const {
  useOnboardingDoneMutation,
  useOnboardingDoneAndUpdateUserMutation,
} = onboardingAPI;
