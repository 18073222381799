import React, { ReactElement, useEffect, useState } from 'react';
import { TextField, Autocomplete } from '@mui/material';
import { useGrowthPartners } from '../data';
import * as S from '../../theme/styles';
import { GrowthPartnerProfile, Pair, StatefulResponse } from '../../../shared/models';

export const GrowthPartnerSearchSelect = (
  { defaultGrowthPartner, setGrowthPartnerFilter }: {
    defaultGrowthPartner?: GrowthPartnerProfile | null,
    setGrowthPartnerFilter: (growthPartnerId: string) => void
  },
): ReactElement => {
  const [searchKeyword, setSearchKeyword] = useState<string>('');
  const growthPartnersState: StatefulResponse<GrowthPartnerProfile[]> = useGrowthPartners(
    searchKeyword,
  );
  const [filters, setFilters] = useState<Pair<string, string>[]>([]);

  let initFilter: Pair<string, string> | null = null;
  if (defaultGrowthPartner) {
    initFilter = {
      key: defaultGrowthPartner.growthPartnerId,
      value: `${defaultGrowthPartner.firstName} ${defaultGrowthPartner.lastName}`,
    };
  }

  const [selectedFilter, setSelectedFilter] = useState<Pair<string, string> | null>(initFilter);

  useEffect(() => {
    if (!growthPartnersState.isLoading && growthPartnersState.response) {
      const newFilters = growthPartnersState.response.data.map((growthPartner) => ({
        key: growthPartner.growthPartnerId,
        value: `${growthPartner.firstName} ${growthPartner.lastName}`,
      }));
      newFilters.unshift({ key: 'front-office', value: 'Front-office' });
      newFilters.unshift({ key: 'all', value: 'All' });
      setFilters(newFilters);
    }
  }, [growthPartnersState]);

  useEffect(() => {
    if (selectedFilter) {
      setGrowthPartnerFilter(selectedFilter.key);
    }
  }, [selectedFilter]);

  return (
    <S.SelectFormControl
      variant="outlined"
    >
      <Autocomplete<Pair<string, string>>
        id="growth-partner-select-search"
        style={{ width: 250 }}
        options={filters}
        getOptionLabel={(option: Pair<string, string>) => option.value}
        loading={growthPartnersState.isLoading}
        value={selectedFilter}
        onChange={(event: any, option: Pair<string, string> | null) => {
          if (option) {
            setSelectedFilter(option);
          }
        }}
        inputValue={searchKeyword}
        onInputChange={(event, newInputValue) => {
          setSearchKeyword(newInputValue);
        }}
        renderInput={(params) =>

        /* eslint-disable react/jsx-props-no-spreading,implicit-arrow-linebreak */
          (
            <TextField
              {...params}
              variant="outlined"
              label="Growth Partner"
              size="small"
            />
          )}
      />
    </S.SelectFormControl>
  );
};

GrowthPartnerSearchSelect.defaultProps = {
  defaultGrowthPartner: null,
};
