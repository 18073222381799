import React, { ReactElement, useState } from 'react';
import { Container, CssBaseline } from '@mui/material';
import { useResetPassword } from '../data';
import * as S from './styles';
import { ResetPasswordSnackbar } from './reset-password-snackbar';
import { GaaslyError } from '../../../shared/models';

export const ResetPasswordView = (): ReactElement => {
  const [email, setEmail] = useState('');
  const onEmailChange = (event: any) => setEmail(event.target.value);
  const [reset, setReset] = useState(0);

  const resetPassword = (event: any) => {
    if (event) {
      event.preventDefault();
    }
    setReset(new Date().getTime());
  };

  const state = useResetPassword(email, reset);

  const getErrorDetails = ():
    GaaslyError => state.response?.errorDetails || new GaaslyError(null);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <S.Paper>
        <S.GaaslyLogo />
        <S.Text variant="h5">
          Forgot password?
        </S.Text>
        <S.Text>
          Just enter the email address you registered with and we will send you
          a link to reset your password.
        </S.Text>
        <S.Form
          noValidate
          onSubmit={resetPassword}
        >
          <S.TextField
            error={Object.prototype.hasOwnProperty.call(getErrorDetails().getErrors(), 'email')}
            helperText={getErrorDetails().getErrors().email || ''}
            name="email"
            variant="outlined"
            fullWidth
            id="email"
            label="Email"
            value={email}
            onChange={onEmailChange}
          />
          <S.Submit
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
          >
            Send password reset link
          </S.Submit>
        </S.Form>
      </S.Paper>
      <ResetPasswordSnackbar state={state} />
    </Container>
  );
};
