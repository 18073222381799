import React, { ReactElement, ReactNode } from 'react';
import * as S from './dialog.styles';

export const DialogContentInitial = (
  { children }: { children: ReactNode },
): ReactElement => (
  <S.ContentContainer>
    {children}
  </S.ContentContainer>
);
