import React, { ReactElement } from 'react';
import { CircularProgress } from '@mui/material';
import { useAppSelector } from '../../../app/hooks';
import * as S from './dialog.styles';
import { selectDialogLoadingText } from './dialog-slice';

export const DialogContentLoading = (): ReactElement => {
  const loadingText = useAppSelector(selectDialogLoadingText);
  return (
    <S.ContentContainer>
      <CircularProgress color="inherit" size="1.5rem" />
      <div>
        {loadingText}
      </div>
    </S.ContentContainer>
  );
};
