import { TimeStats } from './index';
import { roundToTwoDecimals } from '../../../shared/utils';

export const getInvoicedValue = (timeStats: TimeStats | undefined): string => {
  const { internalHours = 0, invoicedHours = 0 } = timeStats || {};
  const hours = internalHours + invoicedHours;
  if (hours === 0) {
    return '0h';
  }
  const percentage = Math.round((invoicedHours / (hours)) * 100);
  return `${roundToTwoDecimals(invoicedHours)}h (${roundToTwoDecimals(percentage)}%)`;
};

export const getInternalValue = (timeStats: TimeStats | undefined): string => {
  const { internalHours = 0, invoicedHours = 0 } = timeStats || {};
  const hours = internalHours + invoicedHours;
  if (hours === 0) {
    return '0h';
  }
  const percentage = Math.round((internalHours / (hours)) * 100);
  return `${roundToTwoDecimals(internalHours)}h (${roundToTwoDecimals(percentage)}%)`;
};

export const getHolidaysCount = (timeStats: TimeStats | undefined): string => {
  const { holidayHours = 0 } = timeStats || {};
  const holidays = holidayHours / 7.5;
  return `${roundToTwoDecimals(holidays)}d`;
};

export const getTotalValue = (timeStats: TimeStats | undefined): string => {
  const { allHours = 0 } = timeStats || {};
  return `${roundToTwoDecimals(allHours)}h`;
};

export const getRevenueValue = (timeStats: TimeStats | undefined): string => {
  const { revenue = 0 } = timeStats || {};
  return `${roundToTwoDecimals(revenue)}€`;
};

export const getAverageHourlyPriceValue = (timeStats: TimeStats | undefined): string => {
  const { averageHourlyPrice = 0 } = timeStats || {};
  return `${roundToTwoDecimals(averageHourlyPrice)}€`;
};

export const getFteValue = (timeStats: TimeStats | undefined): string => {
  const { allHours } = timeStats || {} as TimeStats;
  const fte = allHours / 157.5;
  return `${roundToTwoDecimals(fte)}`;
};

export const getTimeBalanceValue = (timeStats: TimeStats | undefined): string => {
  if (timeStats?.timeBalance) {
    const { timeBalance } = timeStats;
    const operator = timeBalance >= 0 ? '+' : '';
    return `${operator}${roundToTwoDecimals(timeBalance)}h`;
  }
  return '';
};
