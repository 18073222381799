import React, { ReactElement } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { isLoggedIn } from '../auth';
import { getAuthUserDefaultPath } from './gaasly-routes';
import { getStoreAuthUser } from '../auth/data/token-store';

export const RequireNoAuth = (
  { children }: { children: ReactElement },
): ReactElement => {
  const location = useLocation();

  if (isLoggedIn()) {
    const authUser = getStoreAuthUser();
    const defaultUserPath = getAuthUserDefaultPath(authUser);
    return (
      <Navigate
        to={defaultUserPath}
        state={{ from: location }}
        replace
      />
    );
  }

  return children;
};
