import { useEffect } from 'react';
import { useAppDispatch } from '../../../app/hooks';
import { customerProfileChange } from '../customer-profile';
import { placeholderCustomer } from '../../customers';
import { useGetCustomerProfileQuery, useSaveCustomerProfileMutation } from './profile-api-slice';

export const useCustomerProfile = () => {
  const dispatch = useAppDispatch();
  const profileRes = useGetCustomerProfileQuery();
  const [saveProfile, saveProfileRes] = useSaveCustomerProfileMutation();

  const { error } = saveProfileRes;
  const { data, isLoading, isSuccess } = profileRes;

  useEffect(() => {
    if (isSuccess && !isLoading) {
      dispatch(customerProfileChange(data ?? placeholderCustomer));
    }
  }, [profileRes]);

  return {
    saveProfile,
    saveProfileRes,
    error,
  };
};
