import React, { useEffect, useState } from 'react';
import { fetchCustomer } from './api';
import { StatefulResponse } from '../../../shared/models';
import { CustomerDetailProfile } from './types';

export const useCustomerDetail = (
  customerId: string,
): StatefulResponse<CustomerDetailProfile> => {
  const [state, setState] = useState<StatefulResponse<CustomerDetailProfile>>({
    isLoading: false,
    response: null,
  });

  useEffect(() => {
    setState({ isLoading: true, response: null });
    fetchCustomer(customerId).then((response) => {
      setState({ isLoading: false, response });
    });
  }, [customerId]);

  return state;
};
