import React, { ReactElement } from 'react';
import * as S from '../shared-ui/content.styles';

export const StepWelcome = (): ReactElement => (
  <>
    <S.Header>
      Welcome!
    </S.Header>
    <S.Content>
      <S.CenterVertical>
        <S.CenteredText>
          Thank you for choosing us. We&apos;re excited to have you on board!
          To get started, we&apos;ll need to gather a few important details. This
          information will help us set up your account and ensure everything runs smoothly.
        </S.CenteredText>
      </S.CenterVertical>
    </S.Content>
  </>
);
