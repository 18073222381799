import styled, { css } from 'styled-components';
import { FormControl, TextField, Typography } from '@mui/material';

export const CustomerListContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 7rem;
`;

export const ListOptions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 1rem;
  margin-bottom: 1rem;
`;

export const ListOptionTitle = styled(Typography)`
  margin-left: 2rem;
  margin-right: 1rem;
  font-size: 1rem;
  text-transform: uppercase;
  color: rgb(148, 138, 156);
  font-weight: bold;
`;

export const SearchField = styled(TextField)`
  font-size: 2.5rem;
`;

export const FilterPaymentFormControl = styled(FormControl)`
  display: flex;
  flex-wrap: wrap;
`;

export const CustomerList = styled.div`
  display: flex;
  flex-direction: column;
`;

const row = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100vw;
  height: 2.5rem;
  box-shadow: rgb(232 232 232) 0px -1px 0px 0px inset;
`;

const hover = css`
  &:hover {
    background-color: #f0f0f0;
  }
`;

const pointer = css`
  cursor: pointer;
`;

export const CustomerListItem = styled.div`
  ${row}
  ${hover}
  ${pointer}
`;

export const ListTextItem = styled(Typography)`
  font-size: 0.95rem;
  display: flex;
  flex-direction: row;
`;

export const SmallField = styled.div`
  margin-left: 2rem;
  width: 4rem;
  text-align: left;
`;

export const ListItemName = styled(ListTextItem)`
  justify-content: left;
  margin-left: 3rem;
  width: 45%;
`;

export const ListItemEmail = styled(ListTextItem)`
  justify-content: left;
  width: 40%;
`;

export const ListItemPaymentMethod = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  width: 15%;
  margin-right: 3rem;
`;

export const ListItemPaymentMethdOption = styled(ListTextItem)`
  color: rgb(148, 138, 156);
  //width: 3.5rem;
`;

export const Dot = styled.div`
  width: 5px;
  height: 5px;
  background-color: ${(props) => props.color};
  border-radius: 50%;
  margin-left: 1rem;
  margin-right: 0.3rem;
`;

export const ListItemPaymentMethodAddition = styled(ListTextItem)`
  color: ${({ theme }) => theme.palette.red.main};
`;

export const CustomerListHeader = styled.div`
  ${row};
  height: 3rem;
`;

const HeaderText = css`
  text-transform: uppercase;
  color: rgb(148, 138, 156);
`;

export const ListHeaderStatus = styled(SmallField)`
  ${HeaderText}
`;

export const ListHeaderName = styled(ListItemName)`
  ${HeaderText}
`;

export const ListHeaderEmail = styled(ListItemEmail)`
  ${HeaderText}
`;

export const ListHeaderPaymentMethod = styled(ListItemPaymentMethod)`
  ${HeaderText}
`;
