import React, { ReactElement } from 'react';
import * as S from './step-contract.styles';
import * as SA from './content.styles';

export const StepContractDone = (): ReactElement => (
  <S.ContractContainer>
    <SA.CenteredText>
      Your contracts are all set and your onboarding is finished.
      {' '}
      <br />
      You can now navigate to Platform.
    </SA.CenteredText>
  </S.ContractContainer>
);
