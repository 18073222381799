import React, { ReactElement, useEffect, useState } from 'react';
import { Alert } from '@mui/lab';
import * as S from './styles';
import ColumnField from './column-field';
import Section from './section';
import { ErrorSnackbar } from '../../../shared/ui';
import { useBalance, usePayNow } from '../data';

export const PayNowBalanceView = (
  { width = '45rem' }: {
    width: string
  },
): ReactElement => {
  const [fetchBalance, setFetchBalance] = useState<number>(0);
  const balanceState = useBalance(fetchBalance);
  const [submitPayNow, setSubmitPayNow] = useState<number>(0);
  const payNowState = usePayNow(submitPayNow);

  useEffect(() => {
    if (!payNowState.isLoading) {
      setFetchBalance(new Date().getTime());
    }
  }, [payNowState]);

  const onPayNow = () => {
    setSubmitPayNow(new Date().getTime());
  };

  if (balanceState.response?.data) {
    const balanceObj = balanceState.response.data;
    const { balance, vatPercentage, vatMultiplier } = balanceObj;

    return (
      <Section header="Pay Current Balance" width={width}>
        <S.PayNowContainer variant="outlined">
          <S.BalanceDetailsContainer>
            <ColumnField header="Balance" text={`${balance}€`} />
            <ColumnField header="Tax" text={`${vatPercentage}%`} />
            <ColumnField header="Total" text={`${balance * vatMultiplier}€`} />
          </S.BalanceDetailsContainer>
          <S.PayNowButton
            size="medium"
            onClick={onPayNow}
            disabled={payNowState.isLoading || balance === 0}
          >
            Pay Now
          </S.PayNowButton>
        </S.PayNowContainer>
        <ErrorSnackbar states={[payNowState]}>
          <Alert severity="error">
            Payment failed
          </Alert>
        </ErrorSnackbar>
      </Section>

    );
  }
  return <div />;
};
