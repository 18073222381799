import React, { ReactElement } from 'react';
import * as S from './customer-time-entry-list-header.styles';

export const CustomerTimeEntryListHeader = (
  { listWidth }: {listWidth: number},
): ReactElement => {
  const headerWidth = listWidth - 32;
  return (
    <S.ListHeaderRow width={`${headerWidth}px`}>
      <S.ListHeaderFieldNarrow>DATE</S.ListHeaderFieldNarrow>
      <S.ListHeaderFieldWide>DESCRIPTION</S.ListHeaderFieldWide>
      <S.ListHeaderFieldNarrow>GROWTH PARTNER</S.ListHeaderFieldNarrow>
      <S.ListHeaderFieldTiny>TIME</S.ListHeaderFieldTiny>
      <S.ListHeaderFieldTiny />
    </S.ListHeaderRow>
  );
};
