import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiSlice } from '../../api';
import { AuthToken, GoogleOAuthRequestBody } from './types';
import { bodyParser } from '../../api/api-slice';
import { UserProfile } from '../../../shared/models';
import { CustomerDetailProfile } from '../../customers';
import { storeAuthToken } from './token-store';
import { loadAuthUser } from './auth-service';
import { Logger } from '../../../shared/utils';

// TODO google-auth change return type from any to match the real type
const extendedApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUserProfile: builder.query<UserProfile | CustomerDetailProfile, any>({
      query: () => ({
        url: '/api/users/me/',
      }),
    }),
    growthPartnerSignIn:
      builder.mutation<AuthToken, GoogleOAuthRequestBody>({
        query: (body) => ({
          url: '/api/auth/google/sign-in/',
          method: 'POST',
          body: bodyParser(body),
        }),
        onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
          try {
            const { data } = await queryFulfilled;
            const authToken = data.key;
            if (authToken) {
              storeAuthToken(authToken);
              await dispatch(loadAuthUserThunk()).unwrap();
            }
          } catch (error) {
            Logger.log(`Employee sign in failed ${JSON.stringify(error)}`);
          }
        },
      }),
  }),
});

export const loadAuthUserThunk = createAsyncThunk(
  'loadAuthUser',
  async (_, { rejectWithValue }) => {
    try {
      const profileResponse = await loadAuthUser();
      return profileResponse;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const {
  useGrowthPartnerSignInMutation,
} = extendedApi;
