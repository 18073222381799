import { GaaslyResponse } from '../../../shared/models';
import { CustomerDetailProfile, CustomerListProfile } from '.';
import {
  ApiCollectionParser, ApiMethod, ApiObjectParser, ApiService,
} from '../../api-legacy';
import { getAuthToken } from '../../auth';

const API_URL = process.env.REACT_APP_API_URL;
const CUSTOMERS_URL = `${API_URL}/api/customers/`;

export function fetchCustomers(
  searchKeyword: string,
  paymentTypeFilter = 'all',
): Promise<GaaslyResponse<CustomerListProfile[]>> {
  let url = CUSTOMERS_URL.concat('?payment_method=', paymentTypeFilter);
  url = searchKeyword ? url.concat('&search=', searchKeyword) : url;

  return new ApiService<CustomerListProfile[]>(url)
    .setMethod(ApiMethod.GET)
    .setAuth(getAuthToken())
    .setParser(new ApiCollectionParser<CustomerListProfile[]>())
    .requestCollection();
}

export function fetchCustomer(
  customerId: string,
): Promise<GaaslyResponse<CustomerDetailProfile>> {
  const url = CUSTOMERS_URL.concat(customerId);
  return new ApiService<CustomerDetailProfile>(url)
    .setMethod(ApiMethod.GET)
    .setAuth(getAuthToken())
    .setParser(new ApiObjectParser<CustomerDetailProfile>())
    .request();
}
