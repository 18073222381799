import { MenuItem } from '@mui/material';
import React, { ReactNode } from 'react';
import { CustomerDetailProfile } from '../data';
import { Budget } from '../data/types';
import { PaymentMethod } from '../../payments/data/types';
import { useAppDispatch } from '../../../app/hooks';
import {
  creditCardChargeDialogOpen,
  sendInvoiceDialogOpen,
} from '../customer-dashboard-checkout/customer-checkout-slice';
import { budgetDialogOpen } from '../../customer-budget';

interface useMenuItemsArgs {
  customer: CustomerDetailProfile,
  closeMenu: () => void,
}

/**
 *  Conditionally returns list of menu items based on which ones should be available for the
 *  customer. It can also be empty list.
 */
export const useMenuItems = ({ customer, closeMenu }: useMenuItemsArgs): ReactNode[] => {
  // TODO budget, remove prop drilling -> redux
  const {
    currentBudget, openBalance, paymentMethod, hasCreditCard,
  } = customer;

  const menuItems: ReactNode[] = [
    ...useBudgetItem(currentBudget, closeMenu),
    ...useSendInvoiceItem(openBalance, paymentMethod, closeMenu),
    ...useChargeCreditCardItem(openBalance, paymentMethod, hasCreditCard, closeMenu),
  ];
  return menuItems;
};

const useBudgetItem = (budget: Budget, closeMenu: () => void): ReactNode[] => {
  const dispatch = useAppDispatch();
  if (budget.amount <= 0) {
    const onClick = () => {
      dispatch(budgetDialogOpen());
      closeMenu();
    };
    return [<MenuItem onClick={onClick}>Add Budget</MenuItem>];
  }
  return [];
};

const useSendInvoiceItem = (
  openBalance: number,
  paymentMethod: PaymentMethod,
  closeMenu: () => void,
): ReactNode[] => {
  const dispatch = useAppDispatch();
  if (openBalance > 0 && PaymentMethod.INVOICE === paymentMethod) {
    const onClick = () => {
      dispatch(sendInvoiceDialogOpen());
      closeMenu();
    };
    return [<MenuItem onClick={onClick}>Send Invoice</MenuItem>];
  }
  return [];
};

const useChargeCreditCardItem = (
  openBalance: number,
  paymentMethod: PaymentMethod,
  hasCreditCard: boolean,
  closeMenu: () => void,
): ReactNode[] => {
  const dispatch = useAppDispatch();
  if (openBalance > 0 && PaymentMethod.CARD === paymentMethod && hasCreditCard) {
    const onClick = () => {
      dispatch(creditCardChargeDialogOpen());
      closeMenu();
    };
    return [<MenuItem onClick={onClick}>Charge Credit Card</MenuItem>];
  }
  return [];
};
