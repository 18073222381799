import { useEffect, useState } from 'react';
import { confirmPasswordReset, resetPassword } from './api';
import { StatefulResponse } from '../../../shared/models';

export const useResetPassword = (
  email: string,
  reset: number,
): StatefulResponse<any> => {
  const [state, setState] = useState<StatefulResponse<any>>({
    isLoading: false,
    response: null,
  });

  useEffect(() => {
    if (email && reset > 0) {
      setState({ isLoading: true, response: null });
      resetPassword(email).then((response) => {
        setState({ isLoading: false, response });
      });
    }
  }, [reset]);

  return state;
};

export const useResetPasswordConfirm = (
  uid: string,
  token: string,
  password1: string,
  password2: string,
  reset: number,
): StatefulResponse<any> => {
  const [state, setState] = useState<StatefulResponse<any>>({
    isLoading: false,
    response: null,
  });

  useEffect(() => {
    if (reset > 0) {
      setState({ isLoading: true, response: null });
      confirmPasswordReset(uid, token, password1, password2).then((response) => {
        setState({ isLoading: false, response });
      });
    }
  }, [reset]);

  return state;
};
