import React, { ReactElement } from 'react';
import { Logger } from '../../shared/utils';
import * as S from './styles';

export const TermsOfUse = (): ReactElement => {
  Logger.log('TermsPrivacyPolicyPage()');
  return (
    <S.Container>
      <h1>Terms of Use</h1>

      <S.h2>1. Acceptance of Terms</S.h2>
      <p>
        By using this web application, you agree to these terms. If you do not agree, please do not
        use the application.
      </p>

      <S.h2>2. User Responsibilities</S.h2>
      <p>
        Users are responsible for providing accurate billing information for invoicing purposes.
        Misuse of the platform, including fraudulent information, may result in access restrictions.
      </p>

      <S.h2>3. Account Security</S.h2>
      <p>
        “Users must keep their login information confidential. We securely store only a
        cryptographic hash of passwords to verify identity and cannot retrieve original passwords.”
      </p>

      <S.h2>4. Modifications to Service</S.h2>
      <p>
        Voizy Oy (Gaasly) reserves the right to modify or discontinue the service at any time.
      </p>

      <S.h2>5. Limitation of Liability</S.h2>
      <p>
        To the maximum extent permitted by law, Voizy Oy (Gaasly) is not liable for any damages
        resulting from the use or inability to use this service.
      </p>

      <S.h2>6. Governing Law</S.h2>
      <p>
        These terms are governed by the laws of Finland, and any disputes will be settled in Finnish
        courts.
      </p>

      <S.h2>7. Contact Information</S.h2>
      <p>
        For questions about these Terms of Service, please contact us at
        {' '}
        <a
          href="mailto:jani@gaasly.com"
        >
          jani@gaasly.com
        </a>
        .
      </p>
    </S.Container>
  );
};
