import React, { ReactElement } from 'react';
import { Alert } from '@mui/lab';
import { ErrorSnackbar } from '../../../shared/ui';
import { StatefulResponse } from '../../../shared/models';

export const CardLoadingErrorSnackbar = (
  { state }: {
      state: StatefulResponse<any>
    },
): ReactElement => (
  <ErrorSnackbar states={[state]}>
    <Alert severity="error">
      Failed to load card details
    </Alert>
  </ErrorSnackbar>
);
