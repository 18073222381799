import { useEffect, useRef, useState } from 'react';
import lodash from 'lodash';
import { fetchCustomers } from './api';
import { StatefulResponse } from '../../../shared/models';
import { CustomerListProfile } from './types';

export const useCustomerList = (
  searchKeyword: string,
  paymentTypeFilter = 'all',
): StatefulResponse<CustomerListProfile[]> => {
  const [state, setState] = useState<StatefulResponse<CustomerListProfile[]>>({
    isLoading: false,
    response: null,
  });

  const delayedSearch = useRef(
    lodash.debounce((keyword, paymentFilter) => {
      fetchCustomers(keyword, paymentFilter).then((response) => {
        setState({ isLoading: false, response });
      });
    }, 500),
  );

  useEffect(() => {
    setState({ isLoading: true, response: null });
    delayedSearch.current(searchKeyword, paymentTypeFilter);
  }, [searchKeyword, paymentTypeFilter]);
  return state;
};
