import {
  GaaslyResponse,
  InvitationRegistrationInfo,
  InvitationRegistrationResponse,
  UserProfile,
} from '../../../shared/models';

import { ApiMethod, ApiObjectParser, ApiService } from '../../api-legacy';
import { getAuthToken } from './auth-service';
import { AuthToken, LoginRequestBody, RegistrationInfo } from './types';
import { CustomerDetailProfile } from '../../customers';

const API_URL = process.env.REACT_APP_API_URL;
const REGISTRATION_URL = `${API_URL}/api/registration/`;
const LOGIN_URL = `${API_URL}/api/auth/login/`;
const LOGOUT_URL = `${API_URL}/api/auth/logout/`;
const INVITATIONS_URL = `${API_URL}/api/invitations/`;
const PROFILE_URL = `${API_URL}/api/users/me/`;

function getInvitationRegisterUrl(key: string): string {
  return `${INVITATIONS_URL}${key}/register/`;
}

/**
 * @param user
 * @param invitation whether user has been invited to register. Default = false
 */
export function register(
  user: RegistrationInfo,
): Promise<GaaslyResponse<AuthToken>> {
  const payload = {
    email: user.email, password1: user.password1, password2: user.password2,
  };

  return new ApiService<AuthToken>(REGISTRATION_URL)
    .setMethod(ApiMethod.POST)
    .setRequestBody(payload)
    .setParser(new ApiObjectParser<AuthToken>())
    .request();
}

export function invitationRegister(
  data: InvitationRegistrationInfo,
): Promise<GaaslyResponse<InvitationRegistrationResponse>> {
  const payload = {
    password1: data.password1,
    password2: data.password2,
  };
  const url = getInvitationRegisterUrl(data.key);

  return new ApiService<InvitationRegistrationResponse>(url)
    .setMethod(ApiMethod.POST)
    .setRequestBody(payload)
    .setParser(new ApiObjectParser<InvitationRegistrationResponse>())
    .request();
}

export function login(email: string, password: string): Promise<GaaslyResponse<AuthToken>> {
  const payload: LoginRequestBody = { email, password };
  return new ApiService<AuthToken>(LOGIN_URL)
    .setMethod(ApiMethod.POST)
    .setRequestBody(payload)
    .setParser(new ApiObjectParser<AuthToken>())
    .request();
}

export function logout(): Promise<GaaslyResponse<never>> {
  return new ApiService<never>(LOGOUT_URL)
    .setMethod(ApiMethod.POST)
    .setAuth(getAuthToken())
    .setParser(new ApiObjectParser<never>())
    .request();
}

export const fetchUserProfile = ():
  Promise<GaaslyResponse<UserProfile | CustomerDetailProfile>> => new
ApiService<CustomerDetailProfile>(PROFILE_URL)
  .setMethod(ApiMethod.GET)
  .setAuth(getAuthToken())
  .setParser(new ApiObjectParser<UserProfile>())
  .request()
  .then((response) => {
    if (response.success && response.data && response.data.isGrowthPartner) {
      return response as GaaslyResponse<UserProfile>;
    }
    return response;
  });
