/* Time entries */
import { TimeBalance, TimeEntry } from './types';
import {
  ApiCollectionParser, ApiMethod, ApiObjectParser, ApiService,
} from '../../api-legacy';
import { GaaslyResponse } from '../../../shared/models';
import { getAuthToken } from '../../auth/data';
import { CustomerDetailProfile } from '../../customers';

const API_URL = process.env.REACT_APP_API_URL;
const TIME_ENTRIES_URL = `${API_URL}/api/time-entries/`;
const CUSTOMERS_URL = `${API_URL}/api/customers/`;
const GROWTH_PARTNERS_URL = `${API_URL}/api/growth-partners/`;

const getTimeEntryDetailUrl = (
  id: string,
): string => `${TIME_ENTRIES_URL}${id}/`;

const getCustomerDetailUrl = (
  customerId: string,
): string => `${CUSTOMERS_URL}${customerId}/`;

const getCustomerUpdatePriceUrl = (
  customerId: string,
): string => `${CUSTOMERS_URL}${customerId}/hourly-price/`;

const getGpTimeEntriesUrl = (
  growthPartnerId: string,
): string => `${GROWTH_PARTNERS_URL}${growthPartnerId}/time-entries/`;

const getGpTimeBalanceUrl = (
  growthPartnerId: string,
): string => `${GROWTH_PARTNERS_URL}${growthPartnerId}/time-balance/`;

export const logTimeEntry = (timeEntry: TimeEntry):
  Promise<GaaslyResponse<TimeEntry>> => new ApiService<TimeEntry>(TIME_ENTRIES_URL)
  .setMethod(ApiMethod.POST)
  .setAuth(getAuthToken())
  .setRequestBody(timeEntry)
  .setParser(new ApiObjectParser())
  .request();

export const fetchGpTimeEntries = (growthPartnerId: string):
  Promise<GaaslyResponse<TimeEntry[]>> => {
  const url = getGpTimeEntriesUrl(growthPartnerId);
  return new ApiService<TimeEntry[]>(url)
    .setMethod(ApiMethod.GET)
    .setAuth(getAuthToken())
    .setParser(new ApiCollectionParser<TimeEntry[]>())
    .requestCollection();
};

export const deleteTimeEntry = (id: string):
  Promise<GaaslyResponse<any>> => new ApiService<never>(getTimeEntryDetailUrl(id))
  .setMethod(ApiMethod.DELETE)
  .setAuth(getAuthToken())
  .setParser(new ApiObjectParser<any>())
  .request();

export const updateTimeEntry = (
  timeEntry: TimeEntry,
): Promise<GaaslyResponse<TimeEntry>> => new ApiService<never>(
  getTimeEntryDetailUrl(timeEntry.uuid),
)
  .setMethod(ApiMethod.PUT)
  .setAuth(getAuthToken())
  .setRequestBody(timeEntry)
  .setParser(new ApiObjectParser<any>())
  .request();

export const updateCustomerHourlyPrice = (
  hourlyPriceEur: number,
  customerId: string,
): Promise<GaaslyResponse<string>> => {
  const payload = { hourlyPriceEur };
  const url = getCustomerUpdatePriceUrl(customerId);

  return new ApiService<string>(url)
    .setMethod(ApiMethod.PUT)
    .setAuth(getAuthToken())
    .setRequestBody(payload)
    .setParser(new ApiObjectParser<CustomerDetailProfile>())
    .request();
};

export const fetchTimeBalance = (
  growthPartnerId: string,
):
  Promise<GaaslyResponse<TimeBalance>> => new ApiService<TimeBalance>(
    getGpTimeBalanceUrl(growthPartnerId),
  )
  .setMethod(ApiMethod.GET)
  .setAuth(getAuthToken())
  .setParser(new ApiObjectParser<TimeBalance>())
  .request();
