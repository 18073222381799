import styled from 'styled-components';
import { Checkbox as MCheckbox } from '@mui/material';

export const ContractContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto 0;
`;

export const CheckBoxRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-self: center;
`;

export const CheckBox = styled(MCheckbox)`
  color: ${({ theme }) => theme.palette.blue.main};

  &.Mui-checked {
    color: ${({ theme }) => theme.palette.blue.main};
  }
`;
