import { GrowthPartnerProfile, UserProfile } from '../../../shared/models';
import { CustomerDetailProfile } from '../../customers';

const AUTH_TOKEN_KEY = 'auth_token';
const AUTH_USER_KEY = 'auth_user';
const INVALID_TOKEN = '';

export const getStoreAuthUser = ():
  UserProfile |
  CustomerDetailProfile |
  GrowthPartnerProfile |
  null => {
  const userJson = localStorage.getItem(AUTH_USER_KEY);
  if (userJson) {
    const userProfile = JSON.parse(userJson) as UserProfile;
    if (userProfile.isCustomer) {
      return JSON.parse(userJson) as CustomerDetailProfile;
    }
    return userProfile;
  }
  return null;
};

export const getStoreAuthToken = ():
  string => localStorage.getItem(AUTH_TOKEN_KEY) ?? INVALID_TOKEN;

export const hasValidAuthToken = (): boolean => {
  const token = getStoreAuthToken();
  if (typeof token === 'undefined' || token === INVALID_TOKEN) {
    return false;
  }
  return true;
};

export const storeAuthToken = (token: string): void => {
  localStorage.setItem(AUTH_TOKEN_KEY, token);
};

export const removeAuthToken = (): void => {
  localStorage.setItem(AUTH_TOKEN_KEY, INVALID_TOKEN);
};

export const storeAuthUser = (user: UserProfile | CustomerDetailProfile): void => {
  localStorage.setItem(AUTH_USER_KEY, JSON.stringify(user));
};

export const removeAuthUser = (): void => {
  localStorage.setItem(AUTH_USER_KEY, '');
};
