import React, { ReactElement } from 'react';
import * as S from './styles';
import { toRelativeTimeFromString } from '../../../shared/utils';
import { Notification } from '../data';

export const NotificationItem = (
  { notification }: { notification: Notification, },
): ReactElement => (
  <S.NotificationRow>
    <S.NotificationDate>{toRelativeTimeFromString(notification.createdAt)}</S.NotificationDate>
    <S.NotificationTitle>{notification.text}</S.NotificationTitle>
  </S.NotificationRow>
);
