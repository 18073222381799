import React, { ReactElement } from 'react';
import { ContractDisplaySection } from './contract-display-section';
import { UserContract } from '../data';

export const ContractDisplayCustomerUpdate = (
  { userContract }: {userContract: UserContract},
): ReactElement => (
  <ContractDisplaySection
    title="Service Agreement has been updated"
    description="To continue, you need to agree to the terms of this Service Agreement.
    If you have any questions, please contract your consultant for more information."
    link={userContract.contract.url}
    contractName={userContract.contract.title}
  />
);
