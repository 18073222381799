import React, { ReactElement } from 'react';
import { capitalizeFirstLetter } from '../../../shared/utils';
import * as S from './styles';
import { useCustomerListItem } from '../data/customer-list-item-context';

export const ListItemPaymentMethodOption = (): ReactElement => {
  const { paymentMethod } = useCustomerListItem();
  return (
    <S.ListItemPaymentMethdOption>
      {`${capitalizeFirstLetter(paymentMethod)} `}
    </S.ListItemPaymentMethdOption>
  );
};
