import { ApiMethod, ApiService, ApiObjectParser } from '../../api-legacy';
import { ConfirmEmailRequestBody } from './types';
import { GaaslyResponse } from '../../../shared/models';

const API_URL = process.env.REACT_APP_API_URL;
const CONFIRM_EMAIL_URL = `${API_URL}/api/confirm-email/`;

export const confirmEmail = (
  key: string,
): Promise<GaaslyResponse<any>> => {
  const payload: ConfirmEmailRequestBody = { key };
  return new ApiService<any>(CONFIRM_EMAIL_URL)
    .setMethod(ApiMethod.POST)
    .setRequestBody(payload)
    .setParser(new ApiObjectParser<any>())
    .request();
};
