import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { apiSlice, PaginatedResponse } from '../../api';
import { ContractSignArgs, UserContract } from './types';
import { loadAuthUser } from '../../auth';

const contractsAPI = apiSlice.injectEndpoints({
  endpoints: (builder) => ({

    getPendingContracts: builder.query<PaginatedResponse<UserContract[]>, string>({
      query: (userId) => ({
        url: `/api/users/${userId}/contracts?is_signed=false`,
      }),
    }),

    signContract: builder.mutation<UserContract, ContractSignArgs>({
      query: ({ userId, userContractId }) => ({
        url: `/api/users/${userId}/contracts/${userContractId}/sign/`,
        method: 'PUT',
      }),
    }),

    signContractAndUpdateUser: builder.mutation<UserContract, ContractSignArgs>({
      async queryFn({ userId, userContractId }, _queryApi, _extraOptions, fetchWithBQ) {
        const result = await fetchWithBQ({
          url: `/api/users/${userId}/contracts/${userContractId}/sign/`,
          method: 'PUT',
        });
        await loadAuthUser();
        return result.data
          ? { data: result.data as UserContract }
          : { error: result.error as FetchBaseQueryError };
      },
    }),

  }),
});

export const {
  useGetPendingContractsQuery,
  useSignContractMutation,
  useSignContractAndUpdateUserMutation,
} = contractsAPI;
