import React, { ReactElement } from 'react';
import * as S from './styles';
import {
  getAverageHourlyPriceValue,
  getFteValue,
  getHolidaysCount,
  getInternalValue,
  getRevenueValue,
  getTimeBalanceValue,
  getTotalValue,
  TimeStats,
} from '../data';
import { TimeBalanceDescription } from './time-balance-description';
import { StatefulResponse } from '../../../shared/models';
import { GridItemFlexible } from './grid-item-flexible';
import { GridItem } from './grid-item';
import { getInvoicedValue } from '../data/utils';

export const TimeStatsGrid = (
  { state }: { state: StatefulResponse<TimeStats> },
): ReactElement => (
  <S.TimeStatsGrid>
    <GridItem
      state={state}
      header="Invoiced"
      value={getInvoicedValue(state.response?.data)}
    />
    <GridItem
      state={state}
      header="Internal"
      value={getInternalValue(state.response?.data)}
    />
    <GridItem
      state={state}
      header="Total"
      value={getTotalValue(state.response?.data)}
    />
    <GridItem
      state={state}
      header="Holidays"
      value={getHolidaysCount(state.response?.data)}
    />
    <GridItem
      state={state}
      header="Revenue"
      value={getRevenueValue(state.response?.data)}
    />
    <GridItem
      state={state}
      header="Average Hourly Price"
      value={getAverageHourlyPriceValue(state.response?.data)}
    />
    <GridItem
      state={state}
      header="FTE"
      value={getFteValue(state.response?.data)}
    />
    <GridItemFlexible>
      <TimeBalanceDescription state={state} />
      <S.ValueText variant="body1">
        {getTimeBalanceValue(state.response?.data)}
      </S.ValueText>
    </GridItemFlexible>

  </S.TimeStatsGrid>
);
