import React, { ReactElement, ReactNode } from 'react';
import * as S from './styles';

export const GridItemFlexible = (
  { children }: { children: ReactNode },
): ReactElement => (
  <S.GridItem>
    {children}
  </S.GridItem>
);
