import * as React from 'react';
import {
  PropsWithChildren, useCallback, useMemo, useRef, useState,
} from 'react';
import { Handler, WizardProps } from './types';
import { Logger } from '../../../shared/utils';
import { WizardContext } from './wizard-context';

/*
Using https://github.com/devrnt/react-use-wizard/blob/main/src/wizard.tsx as reference, but made
some changes.
 */

export const Wizard = React.memo(<T, >
  ({
    header,
    footer,
    children,
    wrapper: Wrapper,
    onStepChange,
    stepMap,
  }: PropsWithChildren<WizardProps>) => {
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isNextBlocked, setIsNextBlocked] = useState(false);
  const hasNextStep = useRef(true);
  const hasPreviousStep = useRef(false);
  const nextStepHandler = useRef<Handler>(null);
  const stepCount = React.Children.toArray(children).length;

  hasNextStep.current = activeStepIndex < stepCount - 1;
  hasPreviousStep.current = activeStepIndex > 0;

  const goToNextStep = useCallback(() => {
    if (hasNextStep.current && !isNextBlocked) {
      const newActiveStepIndex = activeStepIndex + 1;

      setActiveStepIndex(newActiveStepIndex);
      onStepChange?.(newActiveStepIndex);
    }
  }, [isNextBlocked, hasNextStep, activeStepIndex, onStepChange]);

  const goToPreviousStep = useCallback(() => {
    if (hasPreviousStep.current) {
      nextStepHandler.current = null;
      const newActiveStepIndex = activeStepIndex - 1;

      setActiveStepIndex(newActiveStepIndex);
      onStepChange?.(newActiveStepIndex);
    }
  }, [activeStepIndex, onStepChange]);

  const handleStep = useRef((handler: Handler) => {
    nextStepHandler.current = handler;
  });

  const goToStep = React.useCallback(
    (stepIndex: number) => {
      if (stepIndex >= 0 && stepIndex < stepCount) {
        nextStepHandler.current = null;
        setActiveStepIndex(stepIndex);
        onStepChange?.(stepIndex);
      } else if (process.env.NODE_ENV === 'development') {
        Logger.log(
          [
            `Invalid step index [${stepIndex}] passed to 'goToStep'. `,
            'Ensure the given stepIndex is not out of boundaries.',
          ].join(''),
        );
      }
    },
    [stepCount, onStepChange],
  );

  const doNextStep = useCallback(async () => {
    if (hasNextStep.current && nextStepHandler.current && !isNextBlocked) {
      try {
        setIsLoading(true);
        await nextStepHandler.current();
        setIsLoading(false);
        nextStepHandler.current = null;
        goToNextStep();
      } catch (error) {
        setIsLoading(false);
        throw error;
      }
    } else {
      goToNextStep();
    }
  }, [goToNextStep]);

  const wizardValue = useMemo(
    () => ({
      nextStep: doNextStep,
      previousStep: goToPreviousStep,
      handleStep: handleStep.current,
      isLoading,
      activeStepIndex,
      stepCount,
      isFirstStep: !hasPreviousStep.current,
      isLastStep: !hasNextStep.current,
      stepMap,
      setIsNextBlocked,
      isNextBlocked,
      goToStep,
    }),
    [
      doNextStep,
      goToPreviousStep,
      isLoading,
      activeStepIndex,
      stepCount,
      stepMap,
      setIsNextBlocked,
      isNextBlocked,
      goToStep,
    ],
  );

  const activeStepContent = useMemo(() => {
    const reactChildren = React.Children.toArray(children);
    return reactChildren[activeStepIndex];
  }, [activeStepIndex, children, header, footer]);

  const enhancedActiveStepContent = useMemo(() => (Wrapper
    ? React.cloneElement(Wrapper, { children: activeStepContent })
    : activeStepContent), [activeStepContent, Wrapper]);

  Logger.log('Wizard()');
  return (
    <WizardContext.Provider value={wizardValue}>
      {header}
      {enhancedActiveStepContent}
      {footer}
    </WizardContext.Provider>
  );
});
