import {
  Dispatch, useEffect, useReducer,
} from 'react';
import { GaaslyResponse, StatefulResponse } from '../../../shared/models';
import { confirmEmail } from './api';
import { EmailConfirmationActions } from './types';

export const useConfirmEmail = (
  key: string,
): [StatefulResponse<any>, Dispatch<EmailConfirmationActions>] => {
  const [state, dispatch] = useReducer<(s: StatefulResponse<any>, a: EmailConfirmationActions
  ) => StatefulResponse<any>>(confirmEmailReducer, { isLoading: false, response: null });

  useEffect(() => {
    if (state.isLoading) {
      confirmEmail(key).then((response) => {
        dispatch({ type: 'confirm-done', payload: response });
      });
    }
  }, [state]);

  return [state, dispatch];
};

const confirmEmailReducer = (
  state: StatefulResponse<any>,
  action: EmailConfirmationActions,
): StatefulResponse<any> => {
  switch (action.type) {
    case 'confirm': {
      return { ...state, isLoading: true };
    }
    case 'confirm-done': {
      return { isLoading: false, response: action.payload as GaaslyResponse<any> };
    }
    default: {
      return state;
    }
  }
};
