import { apiSlice } from '../../api';
import { PaymentProfile, updatePaymentMethodArgs } from './types';
import { bodyParser } from '../../api/api-slice';

const paymentsAPI = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPaymentMethod:
      builder.query<PaymentProfile, string>({
        query: (customerId) => ({
          url: `api/customers/${customerId}/payment-method/`,
          method: 'GET',
        }),
      }),
    updatePaymentMethod:
      builder.mutation<PaymentProfile, updatePaymentMethodArgs>({
        query: ({ customerId, paymentMethod }) => ({
          url: `api/customers/${customerId}/payment-method/`,
          method: 'PUT',
          body: bodyParser({ paymentMethod }),
        }),
      }),
  }),
});

export const {
  useGetPaymentMethodQuery,
  useUpdatePaymentMethodMutation,
} = paymentsAPI;
