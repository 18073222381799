import React, { ReactElement } from 'react';
import * as S from './styles';
import { useCustomerListItem } from '../data/customer-list-item-context';

export const ListItemName = (): ReactElement => {
  const {
    firstName,
    lastName,
    companyName,
  } = useCustomerListItem();
  let nameText = `${firstName} ${lastName}`;
  if (companyName) {
    nameText = nameText.concat(` - ${companyName}`);
  }
  return (
    <S.ListItemName>
      {nameText}
    </S.ListItemName>
  );
};
