import React, { Dispatch, ReactElement } from 'react';
import { DeleteOutlined, EditOutlined } from '@mui/icons-material';
import {
  DataGrid, GridCellParams, GridColDef, GridRenderCellParams,
} from '@mui/x-data-grid';
import { EditTimeEntryDialog } from './edit-time-entry-dialog';
import {
  TimeEntriesActions, TimeEntriesState, TimeEntryRow, useListTimeEntries,
} from '../data';
import { TimeEntryGridActionButton } from './time-entry-grid-action-button';
import { DeleteTimeEntryDialog } from './delete-time-entry-dialog';
import * as S from './styles';
import { useAuthUser } from '../../auth/data';
import { GrowthPartnerProfile } from '../../../shared/models';

function getColumns(
  renderActions: (params: GridRenderCellParams) => ReactElement,
): GridColDef[] {
  return [
    {
      field: 'workDoneDate', headerName: 'Work done', type: 'date', width: 120,
    },
    {
      field: 'description', headerName: 'Description', type: 'string', flex: 2.5,
    },
    {
      field: 'customerEmail', headerName: 'Customer', type: 'string', flex: 1,
    },
    {
      field: 'timeInHours', headerName: 'Work time', type: 'number', width: 120,
    },
    {
      field: 'pricePerHour', headerName: 'Price per hour', type: 'number', width: 120,
    },
    {
      field: 'total', headerName: 'Total value', type: 'number', width: 120,
    },
    {
      field: '',
      headerName: '',
      sortable: false,
      width: 120,
      renderCell: renderActions,
    },
  ];
}

/*
Refactoring:
- We should move columns & rowActions out of here
- We could most likely get the dispatch to rowActions without prop drilling with redux
- Pass the timeEntry Actions to getColumns util function
- Clicking row action button renders all rowActions again
- We could maybe also just remove the whole datagrid and replace it with list
 */
export const TimeEntryList = (
  { state, dispatch }: {
    state: TimeEntriesState,
    dispatch: Dispatch<TimeEntriesActions>,
  },
): ReactElement => {
  const user = useAuthUser() as GrowthPartnerProfile;
  useListTimeEntries(user.growthPartnerId, state, dispatch);

  const actionsField = (params: GridCellParams): ReactElement => {
    const timeEntryRow = state.list.timeEntryRows.find(
      (item: TimeEntryRow) => item.id === params.id,
    );

    const isInvoiced = (entry: TimeEntryRow | undefined) => entry?.receiptUuid !== null;
    const actionButtons = (
      <>
        <TimeEntryGridActionButton
          params={params}
          dispatch={dispatch}
          action="edit"
          icon={<EditOutlined />}
        />
        <TimeEntryGridActionButton
          params={params}
          dispatch={dispatch}
          icon={<DeleteOutlined />}
          action="delete"
        />
      </>
    );

    const invoicedTag = (<S.InvoicedTag>Invoiced</S.InvoicedTag>);

    return isInvoiced(timeEntryRow) ? invoicedTag : actionButtons;
  };

  const isLoading = (): boolean => (
    state.create.isLoading || state.list.isLoading
  );

  return (
    <S.Paper>
      <EditTimeEntryDialog state={state} dispatch={dispatch} />
      <DeleteTimeEntryDialog state={state} dispatch={dispatch} />
      <DataGrid
        sortModel={[{ field: 'workDoneDate', sort: 'desc' }]}
        loading={isLoading()}
        autoHeight
        columns={getColumns(actionsField)}
        rows={state.list.timeEntryRows}
        pageSize={10}
      />
    </S.Paper>
  );
};
