import { createSlice } from '@reduxjs/toolkit';
import { CustomerDetailProfile } from '../data';

interface DashboardCustomerState {
  customer: CustomerDetailProfile | undefined
}

const initialState: DashboardCustomerState = {
  customer: undefined,
};

export const dashboardCustomerSlice = createSlice({
  name: 'dashboardCustomerSlice',
  initialState,
  reducers: {},
});

// export const {} = dashboardCustomerSlice.actions;

export default dashboardCustomerSlice.reducer;
