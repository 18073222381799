import React, { ReactElement } from 'react';
import { Logger } from '../../shared/utils';
import { RequireAuth } from './require-auth';
import { RequireOnboardingFinished } from './require-onboarding-finished';
import { RequireContractsSigned } from './require-contracts-signed';

export const RequireAuthAndForcedRoutes = (
  { children }: { children: ReactElement },
): ReactElement => {
  Logger.log('RequireAuthAndForcedRoutes()');
  return (
    <RequireAuth>
      <RequireOnboardingFinished>
        <RequireContractsSigned>
          {children}
        </RequireContractsSigned>
      </RequireOnboardingFinished>
    </RequireAuth>
  );
};
