import styled from 'styled-components';
import {
  Avatar as MAvatar,
  Button,
  Grid as MGrid,
  TextField as MTextField,
  Typography,
} from '@mui/material';

// TODO styles this is duplicated, search for it
export const Paper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${({ theme }) => theme.spacing(8)};
`;

// TODO styles this is duplicated, search for it
export const Avatar = styled(MAvatar)`
  margin: ${({ theme }) => theme.spacing(1)};
  color: ${({ theme }) => theme.palette.secondary.main};
  background-color: ${({ theme }) => theme.palette.primary.main};
`;

export const Form = styled.form`
  width: 100%;
  margin: ${({ theme }) => theme.spacing(3)};
`;

export const Submit = styled(Button)`
  margin: ${({ theme }) => theme.spacing(3, 0, 2)};
`;

export const Grid = styled(MGrid)`
  margin-top: ${({ theme }) => theme.spacing(3)};
`;

// TODO styles this is duplicated, search for it and make reusable
export const Header = styled(Typography)`
  margin-top: ${({ theme }) => theme.spacing(3)};
`;

// TODO styles this is duplicated, search for it and make reusable
export const Text = styled(Typography)`
  margin-top: ${({ theme }) => theme.spacing(2)};
  text-align: center;
`;

// TODO styles this is duplicated, search for it
export const GaaslyLogo = styled.div`
  width: 150px;
  height: 50px;
`;

export const TextField = styled(MTextField)`
  margin-top: ${({ theme }) => theme.spacing(2)};
`;
