import React, { useEffect, useState } from 'react';
import {
  FormHelperText, InputLabel, MenuItem, Select,
} from '@mui/material';
import { IsoCountry, isoCountryList } from '../data';
import { CountrySelectFormControl } from '../../theme';

export const CountrySelect = (
  {
    selectedCountryCode, setCountryCode, isError, errorText,
  }: {
    selectedCountryCode: string,
    setCountryCode: (code: string) => void,
    isError: boolean,
    errorText: string,
  },
) => {
  const countryList = isoCountryList;
  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  useEffect(() => {
    const defaultCountryIndex = countryList.findIndex(
      (c: IsoCountry) => c.code === selectedCountryCode,
    ) ?? 0;
    setSelectedIndex(defaultCountryIndex);
  }, [selectedCountryCode]);

  const onCountryChange = (event: any) => {
    setSelectedIndex(event.target.value);
    const isoCountry = countryList[event.target.value];
    if (isoCountry) {
      setCountryCode(isoCountry.code);
    }
  };

  return (
    <CountrySelectFormControl
      variant="outlined"
      fullWidth={false}
      error={isError}
      sx={{
        '& .MuiInputBase-input': {
          backgroundColor: 'white !important', // Using !important to ensure the style is applied
        },
      }}
    >
      <InputLabel
        id="country-simple-select-outlined-label"
      >
        Country
      </InputLabel>
      <Select
        variant="outlined"
        labelId="country-select"
        id="country-select"
        value={selectedIndex}
        onChange={onCountryChange}
        label="country"
      >
        {
          isoCountryList.map((c: IsoCountry, index: number) => (
            <MenuItem value={index} key={c.code}>
              {c.name}
            </MenuItem>
          ))
        }
      </Select>
      {isError && <FormHelperText>{errorText}</FormHelperText>}
    </CountrySelectFormControl>
  );
};
