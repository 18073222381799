import React, { ReactElement } from 'react';
import StripeCardDetails from './stripe-card-details';
import { SetupStripeCard } from './setup-stripe-card';
import { StripePaymentMethods } from '../data/types';
import { StatefulResponse } from '../../../shared/models';

export const CardPaymentMethodLayouts = (
  { stripePaymentMethodState, reloadStripePaymentMethods }: {
    stripePaymentMethodState: StatefulResponse<StripePaymentMethods>,
    reloadStripePaymentMethods: () => void,
  },
): ReactElement => {
  const isShowSetCardLayout = (): boolean => !stripePaymentMethodState.isLoading
      && stripePaymentMethodState.response != null
      && !stripePaymentMethodState.response?.data.hasCreditCard;
  const isShowCardDetailsLayout = (): boolean => !stripePaymentMethodState.isLoading
      && stripePaymentMethodState.response != null
      && stripePaymentMethodState.response?.data.hasCreditCard;

  return (
    <div>
      {isShowCardDetailsLayout()
        && (
        <StripeCardDetails
          stripePaymentMethod={stripePaymentMethodState.response!.data.data[0]}
          onReloadStripePaymentMethods={reloadStripePaymentMethods}
        />
        )}
      {isShowSetCardLayout()
        && <SetupStripeCard onReloadStripePaymentMethods={reloadStripePaymentMethods} />}
    </div>
  );
};
