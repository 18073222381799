import React, { ReactElement, useEffect, useState } from 'react';
import { Logger } from '../../../shared/utils';
import { CreateTimeEntryResponse } from '../data';
import { BillingInfoWarning } from './billing-info-warning';
import { ContractWarning } from './contract-warning';
import { SimpleDialog } from '../../core/simple-dialog';
import { GaaslyResponse } from '../../../shared/models';

interface CustomerActionNeededDialogArgs {
  create: {
    isLoading: boolean,
    response: GaaslyResponse<CreateTimeEntryResponse> | null,
  }
}

export const CustomerActionNeededDialog: React.FC<CustomerActionNeededDialogArgs> = (
  { create },
): ReactElement => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { response } = create;
  const warnings = response?.data.warnings;

  useEffect(() => {
    const hasWarnings = warnings !== undefined;
    setIsOpen(hasWarnings);
  }, [response]);

  const onCloseDialog = () => setIsOpen(false);

  Logger.log('CustomerMissingInfoDialog()');
  return (
    <SimpleDialog
      isOpen={isOpen}
      onCloseDialog={onCloseDialog}
      title="Customer action needed "
    >
      <BillingInfoWarning warnings={warnings} />
      <ContractWarning warnings={warnings} />
    </SimpleDialog>
  );
};
