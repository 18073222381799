import React, { ReactElement } from 'react';
import { Logger } from '../../../shared/utils';
import * as SC from '../../core/buttons/button.styles';
import { useWizard } from '../../core';

export const PreviousButton = (): ReactElement => {
  Logger.log('PreviousButton()');
  const { previousStep } = useWizard();
  return (
    <SC.Button
      variant="outlined"
      color="blue"
      onClick={() => previousStep()}
    >
      Previous
    </SC.Button>
  );
};
