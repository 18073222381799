/**
 * Time in hours with two decimal points
 */
export const timeInHours = (timeInMinutes: number): number => {
  const timeInH = timeInMinutes / 60;
  const roundedTime = Math.round((timeInH + Number.EPSILON) * 100) / 100;
  return roundedTime;
};
/**
 * Relative time from current (longer than days). Ie. today, yesterday, 2d ago...
 * @param date
 */
export const toRelativeTime = (date: Date): string => {
  const now = new Date();

  const yearDiff = now.getFullYear() - date.getFullYear();
  if (yearDiff > 1) {
    return 'Over year ago';
  }
  if (yearDiff === 1) {
    return 'Last year';
  }

  const monthDiff = now.getMonth() - date.getMonth();
  if (monthDiff === 1) {
    return 'Last month';
  }
  if (monthDiff > 1) {
    return `${monthDiff} months ago`;
  }

  const dayDiff = now.getDate() - date.getDate();
  if (dayDiff === 0) {
    return 'Today';
  }
  if (dayDiff === 1) {
    return 'Yesterday';
  }
  if (dayDiff > 1) {
    return `${dayDiff} days ago`;
  }

  return 'Today';
};

export const toRelativeTimeFromString = (dateString: string): string => {
  const timeString = toRelativeTime(new Date(dateString));
  return timeString;
};

export const formatDate = (date: Date): string => {
  if (date === null) return '';
  return date.toLocaleDateString('en-GB', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  });
};

export const dateToIsoString = (date: Date): string => date.toLocaleDateString('sv');

export const lastDayOfCurrentMonth = (): Date => {
  const today = new Date();
  return new Date(today.getFullYear(), today.getMonth() + 1, 0);
};

export const firstDayOfPreviousMonth = (): Date => {
  const today = new Date();
  return new Date(today.getFullYear(), today.getMonth() - 1, 1);
};

export const formatDateForUi = (date: Date): string => {
  const formattedDate = `${date.getDate()}.${date.getUTCMonth() + 1}.${date.getFullYear()}`;
  return formattedDate;
};

export const formatDateForQuery = (date: Date): string => {
  const year = date.toLocaleString('default', { year: 'numeric' });
  const month = date.toLocaleString('default', { month: '2-digit' });
  const day = date.toLocaleString('default', { day: '2-digit' });
  return `${year}-${month}-${day}`;
};
