import React, { ReactElement } from 'react';
import {
  CustomerAccountInfoContent,
} from '../../profile/customer-profile/customer-account-info-content';
import { useCustomerProfile } from '../../profile/data/use-customer-profile';
import { CustomerDetailProfile } from '../../customers';
import { MutationResult } from '../../api';
import { useNextBlockedHandler } from '../data/use-next-blocked-handler';

export const StepAccountInfo = (): ReactElement => {
  const {
    saveProfile,
    saveProfileRes,
    error,
  } = useCustomerProfile();

  useNextBlockedHandler(saveProfileRes as MutationResult<CustomerDetailProfile>);

  return (
    <CustomerAccountInfoContent
      saveProfile={saveProfile}
      saveProfileRes={saveProfileRes as MutationResult<CustomerDetailProfile>}
      error={error}
    />
  );
};
