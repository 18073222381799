import React, { ReactElement } from 'react';
import { useAppSelector } from '../../../app/hooks';
import * as S from './dialog.styles';
import { selectDialogErrorText } from './dialog-slice';

export const DialogContentError = (): ReactElement => {
  const errorText = useAppSelector(selectDialogErrorText);
  return (
    <S.ContentContainer>
      {errorText}
    </S.ContentContainer>
  );
};
