import styled from 'styled-components';

export const Container = styled.div`
  width: 95vw;
  position: relative;
  margin: auto;
  display: grid;
  grid-template-columns: 35vw 59vw;
  grid-template-rows: 6rem 20.5rem 21rem;
  grid-template-areas:
    "info     top"
    "info     time-entries"
    "invoices time-entries";
  column-gap: 1.5rem;
  row-gap: 1.5rem;
`;

export const GridTopSection = styled.div`
  grid-area: top;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const CardsContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
