import styled from 'styled-components';
import { Paper } from '@mui/material';

export const WizardContainer = styled(Paper)`
  width: 55vw;
  height: 75vh;
  position: relative;
  margin: 5rem auto;
  display: grid;
  grid-template-columns: 15vw 40vw;
  grid-template-rows: 7vh 60vh 8vh;
  grid-template-areas:
    "side   header"
    "side   content"
    "side   footer";
  border-radius: 1rem;
`;
