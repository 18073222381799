import styled from 'styled-components';
import { Paper } from '@mui/material';
import { H3 } from '../../theme/text.styles';

export const GridMainSection = styled.div`
  grid-area: time-entries;
`;

export const Container = styled(Paper)`
  padding: 1rem;
  height: 100%;
`;

export const SectionHeader = styled(H3)`
`;
