import React, { ReactElement, ReactNode } from 'react';
import * as S from './styles';

const Section = (
  { width, header, children }: {
    width: string,
    header: string,
    children: ReactNode,
  },
): ReactElement => (
  <S.Container width={width}>
    <S.Header>{header}</S.Header>
    {children}
  </S.Container>
);

export default Section;
