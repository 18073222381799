import React, { Dispatch, ReactElement, useState } from 'react';

import * as S from './styles';
import {
  placeholderTimeEntry,
  TimeEntriesActions,
  TimeEntriesState,
  TimeEntry,
  TimeEntryActionType,
  useSubmitTimeEntries,
} from '../data';
import { CustomerSelectSearch } from '../../../shared/ui';
import { TimeInputBudget } from './time-input-budget';
import { CustomerListProfile, placeholderCustomer } from '../../customers';

export const TimeTrackingInput = (
  { state, dispatch }: {
    state: TimeEntriesState,
    dispatch: Dispatch<TimeEntriesActions>,
  },
): ReactElement => {
  const [timeEntry, setTimeEntry] = useState<TimeEntry>(placeholderTimeEntry);
  const [selectedCustomer, setSelectedCustomer] = useState<CustomerListProfile>(
    placeholderCustomer,
  );

  useSubmitTimeEntries(timeEntry, selectedCustomer, state, dispatch);

  const onDateChange = (event: any) => {
    setTimeEntry({ ...timeEntry, workDoneDate: new Date(event.target.value) } as TimeEntry);
  };
  const onDescriptionChange = (event: any) => {
    setTimeEntry({ ...timeEntry, description: event.target.value } as TimeEntry);
  };

  const onCustomerChange = (customer: CustomerListProfile) => {
    setTimeEntry({
      ...timeEntry,
      customerId: customer.customerId,
      pricePerHour: customer.hourlyPriceEur,
    } as TimeEntry);
    setSelectedCustomer(customer);
  };
  const onTimeSpentChange = (event: any) => {
    const timeSpentMinutes = event.target.value * 60;
    setTimeEntry({ ...timeEntry, timeSpentMinutes } as TimeEntry);
  };
  const onPricePerHourChange = (event: any) => {
    setTimeEntry({ ...timeEntry, pricePerHour: event.target.value } as TimeEntry);
  };
  const onSubmit = () => {
    dispatch({ type: TimeEntryActionType.CREATE });
  };

  const getErrors = () => state.create.response?.errorDetails.getErrors() || {};
  const hasErrors = () => state.create.response?.errorDetails?.hasErrors() || false;

  return (
    <S.TimeEntryInputContainer>

      <S.MidField
        id="date"
        label="Work done date"
        variant="outlined"
        type="date"
        defaultValue={timeEntry.workDoneDate.toISOString().split('T')[0]}
        InputLabelProps={{
          shrink: true,
        }}
        onChange={onDateChange}
        error={Object.prototype.hasOwnProperty.call(getErrors(), 'workDoneDate')}
        helperText={getErrors().workDoneDate || ''}
      />
      <S.LargeField
        id="description"
        label="Description"
        variant="outlined"
        type="text"
        onChange={onDescriptionChange}
        error={Object.prototype.hasOwnProperty.call(getErrors(), 'description')}
        helperText={getErrors().description || ''}
      />
      <S.SolidWrap>
        <CustomerSelectSearch
          errors={state.create.response?.errorDetails}
          setCustomer={onCustomerChange}
        />
      </S.SolidWrap>
      <S.SmallField
        id="time-entry-work-time"
        label="Work time in hours"
        variant="outlined"
        type="number"
        InputLabelProps={{
          shrink: true,
        }}
        defaultValue={timeEntry.timeSpentMinutes / 60}
        onChange={onTimeSpentChange}
        error={Object.prototype.hasOwnProperty.call(getErrors(), 'timeSpentMinutes')}
        helperText={getErrors().timeSpentMinutes || ''}
      />
      <S.SmallField
        id="time-entry-price-per-hour"
        label="Price per hour (€)"
        variant="outlined"
        type="number"
        fullWidth={false}
        defaultValue={timeEntry.pricePerHour}
        value={timeEntry.pricePerHour}
        onChange={onPricePerHourChange}
        error={Object.prototype.hasOwnProperty.call(getErrors(), 'pricePerHour')}
        helperText={getErrors().pricePerHour || ''}
      />
      <S.SubmitButton
        type="submit"
        fullWidth={false}
        variant="contained"
        color="primary"
        onClick={onSubmit}
        disabled={state.create.isLoading}
      >
        Submit
      </S.SubmitButton>
      {
        selectedCustomer.customerId !== ''
        && <TimeInputBudget selectedCustomerId={selectedCustomer.customerId} />
      }
    </S.TimeEntryInputContainer>
  );
};
