import styled from 'styled-components';
import { Typography } from '@mui/material';

export const GrayTypography = styled(Typography)`
  color: rgb(148, 138, 156);
`;

export const H1 = styled(Typography)`
  font-size: 1.5rem; // 24px
  font-weight: 500;
  color: ${({ theme }) => theme.palette.darkText.main};
`;

export const H2 = styled(Typography)`
  font-size: 1.25rem; // 20px
  font-weight: 600;
  color: ${({ theme }) => theme.palette.darkText.main};
`;

export const H3 = styled(Typography)`
  font-size: 1rem; // 16px
  font-weight: 600;
  color: ${({ theme }) => theme.palette.darkText.main};
`;

export const SmallLightHeader = styled(Typography)`
  font-size: 0.625rem; // 10px
  font-weight: 700;
  color: ${({ theme }) => theme.palette.lightText.main};
`;

export const TextDark = styled(Typography)`
  font-size: 0.875rem; // 14px
  font-weight: 400;
  color: ${({ theme }) => theme.palette.darkText.main};
`;

export const TextLight = styled(Typography)`
  font-size: 0.875rem; // 14px
  font-weight: 400;
  color: ${({ theme }) => theme.palette.lightText.main};
`;

export const TextDarkBold = styled(Typography)`
  font-size: 0.875rem; // 14px
  font-weight: 700;
  color: ${({ theme }) => theme.palette.darkText.main};
`;

export const TextLightSmall = styled(Typography)`
  font-size: 0.75rem; // 12px
  font-weight: 300;
  color: ${({ theme }) => theme.palette.lightText.main};
`;

export const TextDarkSmall = styled(Typography)`
  font-size: 0.75rem; // 12px
  font-weight: 400;
  color: ${({ theme }) => theme.palette.darkText.main};
`;

export const TextLightSmallBold = styled(Typography)`
  font-size: 0.75rem; // 12px
  font-weight: 700;
  color: ${({ theme }) => theme.palette.lightText.main};
`;

export const TextDarkSmallBold = styled(Typography)`
  font-size: 0.75rem; // 12px
  font-weight: 600;
  color: ${({ theme }) => theme.palette.darkText.main};
`;

export const TextLightTinyBold = styled(Typography)`
  font-size: 0.625rem; // 10px
  font-weight: 700;
  color: ${({ theme }) => theme.palette.lightText.main};
`;
