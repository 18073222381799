import React, { ReactElement, ReactNode } from 'react';
import { Snackbar } from '@mui/material';
import { StatefulResponse } from '../models';

export const ErrorSnackbar = (
  { states, children }: {
      states: [StatefulResponse<any>],
      children: ReactNode,
    },
): ReactElement => {
  const hasErrors = states.map(
    (state) => state.response?.success,
  ).includes(false);

  return (
    <Snackbar open={hasErrors}>
      <div>{children}</div>
    </Snackbar>
  );
};
