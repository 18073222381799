import React, { ReactElement } from 'react';
import { useAppSelector } from '../../../app/hooks';
import * as S from './dialog.styles';
import { selectDialogSuccessText } from './dialog-slice';

export const DialogContentSuccess = (): ReactElement => {
  const successText = useAppSelector(selectDialogSuccessText);
  return (
    <S.ContentContainer>
      {successText}
    </S.ContentContainer>
  );
};
