import React, { ReactElement } from 'react';
import { BillingInfo } from '../customer-dashboard-billing-info';
import * as S from './customer-dashboard.styles';
import { BalanceCard, BudgetCard } from '../customer-dashboard-cards';
import { CustomerTimeEntryList } from '../customer-dashboard-time-entry-list';
import { useAuthUser } from '../../auth';
import { CustomerDetailProfile, useCustomerDetail } from '../data';
import { CustomerInvoiceList } from '../customer-dashboard-invoice-list';

export const CustomerDashboard = (): ReactElement => {
  const { customerId } = useAuthUser() as CustomerDetailProfile;
  const customerState = useCustomerDetail(customerId);

  if (customerState.response === null) {
    return <div />;
  }

  return (
    <S.Container>
      <BillingInfo customer={customerState.response.data} />
      <CustomerInvoiceList customerId={customerId} />
      <S.GridTopSection>
        <S.CardsContainer>
          <BalanceCard balance={customerState.response.data.openBalance} />
          <BudgetCard budget={customerState.response.data.currentBudget} />
        </S.CardsContainer>
      </S.GridTopSection>
      <CustomerTimeEntryList customerId={customerState.response.data.customerId} />
    </S.Container>
  );
};

type TreeNode = { value: string }

const mapNode = <T extends TreeNode>(node: T, f: (value: string) => string): T => ({
  ...node,
  value: f(node.value),
});
