import styled from 'styled-components';

export const FooterContainer = styled.div`
  grid-area: footer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  //border-top: 1px solid #D3d9E0;
  margin: auto 2rem;
`;
