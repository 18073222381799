import React, { ReactElement } from 'react';
import * as S from './styles';
import { ListItemName } from './list-item-name';
import { ListItemEmail } from './list-item-email';
import { ListItemPaymentMethod } from './list-item-payment-method';
import { useCustomerListItem } from '../data/customer-list-item-context';
import { CustomerListProfile } from '../data';

export const CustomerListItem = (
  { onClick }: {
    onClick: (item: CustomerListProfile) => void,
  },
): ReactElement => {
  const customer = useCustomerListItem();
  const handleClick = () => {
    onClick(customer);
  };

  return (
    <S.CustomerListItem onClick={handleClick}>
      <ListItemName />
      <ListItemEmail />
      <ListItemPaymentMethod />
    </S.CustomerListItem>
  );
};
