import camelcaseKeys from 'camelcase-keys';

import { GaaslyCollectionResponse, GaaslyError } from '../../shared/models';
import { ApiParser } from './api-parser';

export class ApiCollectionParser<T> extends ApiParser<GaaslyCollectionResponse<T>> {
  async parseResponse(response: Response): Promise<GaaslyCollectionResponse<T>> {
    let apiObject = await this.parseAPIObject(response);

    if (!response.ok) {
      const error = this.parseError(apiObject);
      return new GaaslyCollectionResponse(false, response.status, apiObject, error, 0, '', '');
    }

    apiObject = camelcaseKeys(apiObject, { deep: true });
    const modelObject: T = apiObject.results as T;

    return new GaaslyCollectionResponse<T>(
      true,
      response.status,
      modelObject,
      new GaaslyError(null),
      apiObject.count,
      apiObject.next,
      apiObject.previous,
    );
  }
}
