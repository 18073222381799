import React, { useEffect, useState } from 'react';
import { Notification } from './types';
import { fetchNotifications } from './api';
import { StatefulResponse } from '../../../shared/models';

export const useNotifications = (): StatefulResponse<Notification[]> => {
  const [state, setState] = useState<StatefulResponse<Notification[]>>({
    isLoading: false,
    response: null,
  });

  useEffect(() => {
    setState({ ...state, isLoading: true });
    fetchNotifications().then((response) => {
      setState({ isLoading: false, response });
    });
  }, []);

  return state;
};
