import { useEffect, useState } from 'react';
import {
  AuthToken,
  customerLogin,
  customerRegister,
  inviteRegister,
  RegistrationInfo,
} from './index';
import {
  GaaslyResponse,
  InvitationRegistrationInfo,
  InvitationRegistrationResponse,
  StatefulResponse,
} from '../../../shared/models';

export const useCustomerRegister = (
  registrationInfo: RegistrationInfo,
  registerEvent: number,
): StatefulResponse<AuthToken> => {
  const [state, setState] = useState<StatefulResponse<AuthToken>>({
    isLoading: false,
    response: null,
  });

  useEffect(() => {
    if (registerEvent !== 0) {
      setState({ isLoading: true, response: null });
      customerRegister(registrationInfo).then((response) => {
        setState({ isLoading: false, response });
      });
    }
  }, [registerEvent]);

  return state;
};

export const useInvitationRegister = (
  registrationInfo: InvitationRegistrationInfo,
  registerEvent: number,
): StatefulResponse<InvitationRegistrationResponse> => {
  const [state, setState] = useState<StatefulResponse<InvitationRegistrationResponse>>({
    isLoading: false,
    response: null,
  });

  useEffect(() => {
    if (registerEvent !== 0) {
      setState({ isLoading: true, response: null });
      registerAndLogin(registrationInfo).then((response) => {
        setState({ isLoading: false, response });
      });
    }
  }, [registerEvent]);

  return state;
};

async function registerAndLogin(
  registrationInfo: InvitationRegistrationInfo,
): Promise<GaaslyResponse<InvitationRegistrationResponse>> {
  const registerRes = await inviteRegister(registrationInfo);
  if (registerRes.success) {
    const loginRes = await customerLogin(registerRes.data.email, registrationInfo.password1);
    if (loginRes.success) {
      // In case login success we'll return the successful registration response
      return registerRes;
    }
    // In case login fails we take the error message and convert to correct type of response
    return new GaaslyResponse<InvitationRegistrationResponse>(
      true,
      400,
      { detail: '', email: '' },
      loginRes.errorDetails,
    );
  }
  return registerRes;
}
