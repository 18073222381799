import React, { ReactElement, useEffect, useState } from 'react';
import { CardContent } from '@mui/material';
import { Alert } from '@mui/lab';
import * as S from './styles';
import StripeCardSection from './stripe-card-section';
import { ErrorSnackbar } from '../../../shared/ui';
import { useSetupStripePaymentMethod } from '../data';

export const SetupStripeCard = (
  { onReloadStripePaymentMethods }: {
    onReloadStripePaymentMethods: () => void,
  },
): ReactElement => {
  const [submit, setSubmit] = useState<number>(0);
  const setupCardState = useSetupStripePaymentMethod(submit);

  const isCardSetupSuccess = () => (
    !setupCardState.isLoading
    && setupCardState.response?.success
  );

  useEffect(() => {
    if (isCardSetupSuccess()) {
      onReloadStripePaymentMethods();
    }
  }, [setupCardState]);

  const onSaveCard = () => {
    setSubmit(new Date().getTime());
  };

  return (
    <div>
      <S.CardPaymentContainer>
        <S.SetupStripeCardContainer variant="outlined">
          <CardContent>
            <StripeCardSection />
          </CardContent>
        </S.SetupStripeCardContainer>
        <S.SaveButton
          size="medium"
          onClick={onSaveCard}
          disabled={setupCardState.isLoading}
        >
          Save
        </S.SaveButton>
      </S.CardPaymentContainer>
      <S.Text>
        By adding card, I authorize Gaasly to send instructions to the financial institution
        that issued my card to take payments from my card account in accordance with the terms of
        my agreement with Gaasly.
      </S.Text>
      <ErrorSnackbar states={[setupCardState]}>
        <Alert severity="error">
          Failed to save the credit card
        </Alert>
      </ErrorSnackbar>
    </div>
  );
};
