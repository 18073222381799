import React, { useEffect, useState } from 'react';
import { GSnackbar } from '../../../shared/ui';
import { AuthToken } from '../data';
import { SnackbarContent, StatefulResponse } from '../../../shared/models';

type LoginNotificationProps = {
  state: StatefulResponse<AuthToken>
}

export default function LoginSnackbar({ state }: LoginNotificationProps) {
  const [content, setContent] = useState<SnackbarContent>({
    open: false, text: '', severity: 'info',
  });

  const closeNotification = () => {
    setContent({ open: false, text: '', severity: 'info' });
  };

  useEffect(() => {
    if (state.isLoading) {
      setContent({ open: true, text: 'Authenticating...', severity: 'info' });
    } else if (!state.isLoading && state.response?.errorDetails.hasNonFieldErrors()) {
      setContent({
        open: true, text: state.response?.errorDetails.getNonFieldError(), severity: 'error',
      });
      setTimeout(closeNotification, 1500);
    } else if (!state.response?.success) {
      closeNotification();
    }
  }, [state]);

  return (
    <GSnackbar content={content} />
  );
}
