import styled from 'styled-components';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { Typography } from '@mui/material';
import React from 'react';
import { ColorTypographyProps } from '../../core';

export const GREEN = '#6CC89A';
export const BLUE = '#5771E5';

export const GRAY = '#CFCDDC';

export const SideContainer = styled.div`
  grid-area: side;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  //border-right: 1px solid #D3d9E0;
  border-right: 1px solid ${({ theme }) => theme.palette.lightGray.main};
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 1rem 0;
`;

export const Text = styled(Typography)`
  font-size: 1rem;
  font-weight: 400;
`;

export const CompletedText = styled(Text)`
  color: #6CC89A;
`;

export const CurrentText = styled(Text)`
  color: #5771E5;
`;

export const UpcomingText = styled(Text)`
  color: #CFCDDC;
`;

export const CheckedIcon = styled(CheckCircleRoundedIcon)`
  margin-right: 0.5rem;
  color: #6AC69A;
`;

// eslint-disable-next-line react/jsx-props-no-spreading
export const CircledNumber = styled(({ color, ...otherProps }: ColorTypographyProps) => <Text {...otherProps} />)`
  font-size: 0.875rem;
  margin-right: 0.5rem;
  width: 1.25rem;
  line-height: 1.25rem;
  border-radius: 50%;
  text-align: center;
  background-color: ${(props) => props.color};
  color: white;
`;
