import React, { ReactElement } from 'react';
import * as S from './dialog.styles';
import { useAppSelector } from '../../../app/hooks';
import { selectDialogTitle } from './dialog-slice';

export const DialogTitle = (): ReactElement => {
  const title = useAppSelector(selectDialogTitle);
  return (
    <S.DialogHeader>
      {title}
    </S.DialogHeader>
  );
};
