import React, { ReactElement } from 'react';
import { ContractDisplaySection } from './contract-display-section';
import { UserContract } from '../data';

export const ContractDisplayGpFirst = (
  { userContract }: {userContract: UserContract},
): ReactElement => (
  <ContractDisplaySection
    title="Welcome to Gaasly!"
    description="We are thrilled to have you with us. To get started let's agree on some terms
        of collaboration. Please read and agree our Subcontractor Agreement to continue."
    link={userContract.contract.url}
    contractName={userContract.contract.title}
  />
);
