import styled from 'styled-components';
import { TextField } from '@mui/material';
import { H1, H3, TextDark } from '../../theme/text.styles';
import * as SC from '../../core/buttons';

export const Header = styled(H1)`
  grid-area: header;
  //text-align: center;
  margin: auto 1rem;
`;

export const Content = styled.div`
  grid-area: content;
  display: flex;
  flex-direction: column;
  border-top: 1px solid ${({ theme }) => theme.palette.lightGray.main};
  border-bottom: 1px solid ${({ theme }) => theme.palette.lightGray.main};
  background: #f6f8fb;
`;

export const HorizontallyCenteredContent = styled.div`
  grid-area: content;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 1px solid ${({ theme }) => theme.palette.lightGray.main};
  border-bottom: 1px solid ${({ theme }) => theme.palette.lightGray.main};
  background: #f6f8fb;
`;

export const CenteredContent = styled(HorizontallyCenteredContent)`
  justify-content: center;
`;

export const CenterVertical = styled.div`
  height: 40vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const CenteredTextNoMargin = styled(TextDark)`
  font-size: 1rem;
  margin: 1rem;
  text-align: center;
`;

export const CenteredText = styled(TextDark)`
  font-size: 1rem;
  margin: 1rem 3rem;
  text-align: center;
`;

export const ContentText = styled(TextDark)`
  font-size: 1rem;
  margin: 1rem 1rem 1rem 0;
`;

export const MarginedText = styled(H3)`
  margin: 1rem;
`;

export const MarginedTextSmall = styled(TextDark)`
  font-size: 1rem;
  margin: 1rem;
`;

export const LoadingButton = styled(SC.LoadingButton)`
  margin-top: 1rem;
  align-self: center;
  width: 6rem;
`;

export const LoadingButtonExtra = styled(LoadingButton)`
  width: 7rem;
`;

// Budget

export const BudgetField = styled(TextField)`
  width: 12rem;
  background-color: white;

  & .MuiInputBase-input {
    text-align: center;
  }
`;
