import moment from 'moment/moment';
import { TimeEntry, TimeEntryRow } from './types';
import { timeInHours } from '../../../shared/utils';

export const timeEntriesToRows = (entries: TimeEntry[]): TimeEntryRow[] => entries.map(
  (entry) => ({
    id: entry.uuid,
    workDoneDate: moment(entry.workDoneDate).format('YYYY-MM-DD'),
    description: entry.description,
    customerEmail: entry.customer !== null ? entry.customer.email : '',
    timeInHours: `${timeInHours(entry.timeSpentMinutes)} h`,
    pricePerHour: `${entry.pricePerHour} €`,
    total: `${entry.pricePerHour * timeInHours(entry.timeSpentMinutes)} €`,
    receiptUuid: entry.receiptUuid,
  }),
);
