import React, { ReactElement, ReactNode } from 'react';
import * as S from './sidebar.styles';

export const SidebarUpcomingItem = (
  { stepNumber, children }: { stepNumber: number, children: ReactNode },
): ReactElement => (
  <S.Row>
    <S.CircledNumber color={S.GRAY}>{stepNumber}</S.CircledNumber>
    <S.UpcomingText>
      {children}
    </S.UpcomingText>
  </S.Row>
);
