import { toCamelCase } from '../utils/parse-utils';

export class GaaslyError {
  private readonly errors: any | null = null;

  private readonly nonFieldErrors: any | null = null;

  private readonly detail: string | null = null;

  constructor(errorsData: any | null) {
    this.errors = GaaslyError.parseErrors(errorsData);
    if (this.errors !== null) {
      this.nonFieldErrors = this.errors.nonFieldErrors || null;
      this.detail = this.errors.detail || null;
    }
  }

  hasErrors(): boolean {
    return this.errors !== null;
  }

  hasNonFieldErrors(): boolean {
    return this.nonFieldErrors !== null || this.detail !== null;
  }

  getErrors(): any {
    return this.errors || {};
  }

  getNonFieldError(): string {
    if (this.nonFieldErrors !== null) {
      return this.nonFieldErrors[0];
    } if (this.detail !== null) {
      return this.detail;
    }
    return '';
  }

  static parseErrors(errorsData: any | null): any | null {
    if (errorsData === null) {
      return null;
    }
    const transformedData: any = {};
    Object.keys(errorsData).forEach((originalKey: string) => {
      const transformedKey = toCamelCase(originalKey);
      transformedData[transformedKey] = errorsData[originalKey];
    });

    return transformedData;
  }

  static getDefaultError(): GaaslyError {
    return new GaaslyError({ non_field_error: ['Something went wrong, try again later'] });
  }
}
