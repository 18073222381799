import React, { ReactElement } from 'react';
import { IconButton } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import * as S from './styles';
import { TimeStats } from '../data';
import { StatefulResponse } from '../../../shared/models';

export const TimeBalanceDescription = (
  { state }: {state: StatefulResponse<TimeStats>},
): ReactElement => {
  if (state.response?.data.timeBalance) {
    return (
      <S.TimeBalanceDescriptionContainer>
        <S.DescriptionText variant="body1">
          Time Balance
        </S.DescriptionText>
        <S.TimeBalanceToolTip
          title="Time balance calculated since beginning of the contract."
        >
          <IconButton size="large">
            <InfoOutlined />
          </IconButton>
        </S.TimeBalanceToolTip>
      </S.TimeBalanceDescriptionContainer>
    );
  }
  return <div />;
};
