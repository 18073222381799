// eslint-disable-next-line import/no-extraneous-dependencies
import { UserProfile } from '../../shared/models';

export const GaaslyRoutes = {
  login: '/login',
  register: '/register',
  growthPartnerSignIn: '/growth-partner-sign-in',
  invitationRegister: '/invitations/:key/register/',
  confirmEmailSent: '/confirm-email-sent',
  resetPassword: '/reset-password',
  resetPasswordConfirm: '/reset-password-confirm/:uid/:token',
  paymentMethod: '/payments',
  timeTracking: '/timetracking',
  customersListing: '/customers',
  customerDetail: '/customers/:customerId',
  confirmEmail: '/confirm-email/:key/',
  userContracts: '/user-contracts',
  termsOfUse: '/terms-of-use',
  privacyPolicy: '/privacy-policy',
  // growth-partner
  growthPartnerOnboarding: '/growth-partner-onboarding',
  growthPartnerDashboard: '/growth-partner-dashboard',
  // customer
  customerOnboarding: '/customer-onboarding',
  customerDashboard: '/customer-dashboard',
  customerProfile: '/customer-profile',
};

export const NonNavBarRouters = [
  GaaslyRoutes.customerOnboarding,
  GaaslyRoutes.growthPartnerOnboarding,
];

export function getCustomerDetailUrl(customerId: string): string {
  return `${GaaslyRoutes.customersListing}/${customerId}`;
}

export const getAuthUserDefaultPath = (authUser: UserProfile | null): string => {
  if (authUser?.isGrowthPartner) {
    return GaaslyRoutes.growthPartnerDashboard;
  }
  if (authUser?.isCustomer) {
    return GaaslyRoutes.customerDashboard;
  }
  return '';
};
