import { GaaslyResponse } from './gaasly-response';
import { GaaslyError } from './gaasly-error';

export class GaaslyCollectionResponse<T> extends GaaslyResponse<T> {
  count: number;

  nextPageUrl: string;

  previousPageUrl: string;

  constructor(
    success: boolean,
    status: number,
    data: T,
    error: GaaslyError,
    count: number,
    nextPageUrl: string,
    previousPageUrl: string,
  ) {
    super(success, status, data, error);
    this.count = count;
    this.nextPageUrl = nextPageUrl;
    this.previousPageUrl = previousPageUrl;
  }

  static getDefaultError(): GaaslyCollectionResponse<any> {
    return new GaaslyCollectionResponse(
      false,
      400,
      null,
      GaaslyError.getDefaultError(),
      0,
      '',
      '',
    );
  }
}
