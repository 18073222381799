import React, { ReactElement } from 'react';
import * as S from './styles';
import { useTimeBalance } from '../data';
import { roundToTwoDecimals } from '../../../shared/utils';
import { GrowthPartnerProfile } from '../../../shared/models';
import { getStoreAuthUser } from '../../auth/data/token-store';

export const TimeBalanceView = (
  { loadTimeBalance = 0 }: { loadTimeBalance?: number },
): ReactElement => {
  const authGrowthPartner = getStoreAuthUser() as GrowthPartnerProfile;
  const state = useTimeBalance(loadTimeBalance, authGrowthPartner.growthPartnerId);

  const hourBalance = roundToTwoDecimals(state.response?.data.hourBalance || 0);
  const timeBalance = hourBalance > 0 ? `+${hourBalance}h` : `${hourBalance}h`;
  const textColor = (hourBalance > 40 || hourBalance < (-40)) ? 'red' : 'black';

  const isShowTimeBalance = () => state.response != null && state.response.success;

  return (
    <div>
      {
        isShowTimeBalance() && (
          <S.TimeBalanceViewContainer>
            <S.TimeBalanceText>
              Time
              <br />
              Balance
            </S.TimeBalanceText>
            <S.TimeBalanceValue color={textColor}>
              {timeBalance}
            </S.TimeBalanceValue>
          </S.TimeBalanceViewContainer>
        )
      }
    </div>
  );
};

TimeBalanceView.defaultProps = {
  loadTimeBalance: 0,
};
