import { ApiCollectionParser, ApiMethod, ApiService } from '../../api-legacy';
import { GaaslyResponse, GrowthPartnerProfile } from '../../../shared/models';
import { getAuthToken } from '../../auth/data';

const API_URL = process.env.REACT_APP_API_URL;
const GROWTH_PARTNERS_URL = `${API_URL}/api/growth-partners/`;

export const fetchGrowthPartners = ():
  Promise<GaaslyResponse<GrowthPartnerProfile[]>> => new
ApiService<GrowthPartnerProfile[]>(
  GROWTH_PARTNERS_URL,
)
  .setMethod(ApiMethod.GET)
  .setAuth(getAuthToken())
  .setParser(new ApiCollectionParser())
  .request();
