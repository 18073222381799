import React, { ReactElement, useEffect } from 'react';
import * as S from './content.styles';
import { useGetPendingContractsQuery } from '../../contracts/data/contracts-api-slice';
import { useAuthUser } from '../../auth';
import { StepContractPendingContract } from './step-contract-pending-contract';
import { StepContractDone } from './step-contract-done';
import { useWizard } from '../../core';
import { Logger } from '../../../shared/utils';

export const StepContract = (
  { title }: { title: string },
): ReactElement => {
  const { setIsNextBlocked } = useWizard();
  const authUser = useAuthUser();
  const userId = authUser?.userId ?? '';
  const {
    data: contractsData,
    refetch: refetchContracts,
    isLoading: isFetchingContracts,
  } = useGetPendingContractsQuery(userId);

  useEffect(() => () => {
    // Clean up on unmount, unblocking next
    setIsNextBlocked(false);
  }, []);

  const isContractPending = contractsData && contractsData?.count > 0;
  setIsNextBlocked(isContractPending ?? false);
  Logger.log(`contractsData ${JSON.stringify(contractsData)}`);

  const ContractContentView = isContractPending
    ? (
      <StepContractPendingContract
        title={title}
        userId={userId}
        userContract={contractsData.results[0]}
        isFetchingContracts={isFetchingContracts}
        refetchContracts={refetchContracts}
      />
    )
    : <StepContractDone />;

  return (
    <>
      <S.Header>
        {title}
      </S.Header>
      <S.Content>
        {ContractContentView}
      </S.Content>
    </>
  );
};
