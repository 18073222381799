import styled from 'styled-components';
import { TextLightSmallBold } from '../../theme/text.styles';

export const ListHeaderRow = styled.div<{ width: string }>`
  display: flex;
  flex-direction: row;
  width: ${(props) => `${props.width}`};
  height: 3rem;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.palette.lightGray.main};
`;

export const ListHeaderFieldWide = styled(TextLightSmallBold)`
  margin-left: 1rem;
  width: 40%;
`;

export const ListHeaderFieldNarrow = styled(TextLightSmallBold)`
  margin-left: 1rem;
  width: 20%;
`;

export const ListHeaderFieldTiny = styled(TextLightSmallBold)`
  margin-left: 1rem;
  width: 10%;
`;
