import React, { ReactElement } from 'react';
import { FixedSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { TimeEntry } from '../../time-tracking/data';
import { CustomerTimeEntryListItem } from './customer-time-entry-list-item';
import * as S from './customer-time-entry-list.styles';
import { CustomerTimeEntryListHeader } from './customer-time-entry-list-header';
import { useGetCustomerTimeEntriesQuery } from '../data/customers-api-slice';

export const CustomerTimeEntryList = (
  { customerId }: { customerId: string },
): ReactElement => {
  const res = useGetCustomerTimeEntriesQuery({ customerId });
  const {
    data: { count, results } = { count: 0, results: [] },
  } = res;

  return (
    <S.GridMainSection>
      <S.Container>
        <S.SectionHeader>Time Entries</S.SectionHeader>
        <AutoSizer>
          {({ width = 0 }) => (
            <>
              <CustomerTimeEntryListHeader listWidth={width} />
              <FixedSizeList<TimeEntry[]>
                itemSize={48}
                height={576}
                width={width}
                itemCount={count}
                overscanCount={5}
                itemData={results}
              >
                {CustomerTimeEntryListItem}
              </FixedSizeList>
            </>
          )}
        </AutoSizer>
      </S.Container>
    </S.GridMainSection>
  );
};
