import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CustomerDetailProfile, placeholderCustomer } from '../../customers';
import { RootState } from '../../../app/store';

const initialState: CustomerDetailProfile = placeholderCustomer;

export const customerProfileSlice = createSlice({
  name: 'customerProfile',
  initialState,
  reducers: {
    customerProfileChange: (state, action: PayloadAction<CustomerDetailProfile>) => action.payload,
    customerProfileFirstNameChange: (state, action: PayloadAction<string>) => {
      state.firstName = action.payload;
    },
    customerProfileLastNameChange: (state, action: PayloadAction<string>) => {
      state.lastName = action.payload;
    },
    customerProfileEmailChange: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    customerProfileIsCompanyChange: (state, action: PayloadAction<boolean>) => {
      state.isCompany = action.payload;
    },
    customerProfileCompanyNameChange: (state, action: PayloadAction<string>) => {
      state.companyName = action.payload;
    },
    customerProfileInvoicingEmailChange: (state, action: PayloadAction<string>) => {
      state.invoicingEmail = action.payload;
    },
    customerProfileAddressLine1Change: (state, action: PayloadAction<string>) => {
      state.addressLine1 = action.payload;
    },
    customerProfileAddressLine2Change: (state, action: PayloadAction<string>) => {
      state.addressLine2 = action.payload;
    },
    customerProfileCityChange: (state, action: PayloadAction<string>) => {
      state.city = action.payload;
    },
    customerProfilePostalCodeChange: (state, action: PayloadAction<string>) => {
      state.postalCode = action.payload;
    },
    customerProfileVatIdChange: (state, action: PayloadAction<string>) => {
      state.vatId = action.payload;
    },
    customerProfileCountryChange: (state, action: PayloadAction<string>) => {
      state.country = action.payload;
    },
    customerProfileAdditionalBillingInfoChange: (state, action: PayloadAction<string>) => {
      state.additionalBillingInfo = action.payload;
    },
  },
});

export const {
  customerProfileChange,
  customerProfileFirstNameChange,
  customerProfileLastNameChange,
  customerProfileEmailChange,
  customerProfileIsCompanyChange,
  customerProfileCompanyNameChange,
  customerProfileInvoicingEmailChange,
  customerProfileAddressLine1Change,
  customerProfileAddressLine2Change,
  customerProfileCityChange,
  customerProfilePostalCodeChange,
  customerProfileVatIdChange,
  customerProfileCountryChange,
  customerProfileAdditionalBillingInfoChange,
} = customerProfileSlice.actions;

export const selectCustomerProfile = (state: RootState) => state.customerProfile;

export const selectCustomerProfileFirstName = (
  state: RootState,
) => state.customerProfile.firstName;

export const selectCustomerProfileLastName = (
  state: RootState,
) => state.customerProfile.lastName;

export const selectCustomerProfileEmail = (
  state: RootState,
) => state.customerProfile.email;

export const selectCustomerProfileIsCompany = (
  state: RootState,
) => state.customerProfile.isCompany;

export const selectCustomerProfileCompanyName = (
  state: RootState,
) => state.customerProfile.companyName;

export const selectCustomerProfileInvoicingEmail = (
  state: RootState,
) => state.customerProfile.invoicingEmail;

export const selectCustomerProfileAddressLine1 = (
  state: RootState,
) => state.customerProfile.addressLine1;

export const selectCustomerProfileAddressLine2 = (
  state: RootState,
) => state.customerProfile.addressLine2;

export const selectCustomerProfileCity = (
  state: RootState,
) => state.customerProfile.city;

export const selectCustomerProfilePostalCode = (
  state: RootState,
) => state.customerProfile.postalCode;

export const selectCustomerProfileVatId = (
  state: RootState,
) => state.customerProfile.vatId;

export const selectCustomerProfileCountry = (
  state: RootState,
) => state.customerProfile.country;

export const selectCustomerProfileAdditionalBillingInfo = (
  state: RootState,
) => state.customerProfile.additionalBillingInfo;
