import React, { ReactElement, ReactNode } from 'react';
import { Button, Dialog as MuiDialog } from '@mui/material';
import * as S from '../dialog/dialog.styles';

export const SimpleDialog = (
  {
    isOpen = false, title, onCloseDialog, children,
  }: {
    isOpen?: boolean,
    title: string,
    onCloseDialog: () => void,
    children: ReactNode,
  },
): ReactElement => {
  const closed = false;
  const isDialogOpen = isOpen && !closed;

  return (
    <MuiDialog open={isDialogOpen}>
      <S.Container>
        <S.DialogHeader>
          {title}
        </S.DialogHeader>
        <S.ContentContainer>
          {children}
        </S.ContentContainer>
        <S.ActionsContainer>
          <Button
            variant="contained"
            color="white"
            onClick={onCloseDialog}
          >
            Close
          </Button>
        </S.ActionsContainer>
      </S.Container>
    </MuiDialog>
  );
};

SimpleDialog.defaultProps = {
  isOpen: false,
};
