export class Logger {
  static loggedEnvironments = [
    'DEVELOPMENT',
    // 'STAGING',
    // "PRODUCTION",
  ];

  private static isLogging() {
    const environment = process.env.REACT_APP_ENVIRONMENT ?? '';
    return this.loggedEnvironments.includes(environment);
  }

  static log(message: string) {
    if (Logger.isLogging()) {
      console.log(message);
    }
  }
}
