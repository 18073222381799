import React, { ReactElement } from 'react';
import { Button } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
  dialogClose,
  DialogResult,
  DialogStatus,
  selectDialogStatus,
  selectDialogTag,
} from './dialog-slice';

export const DialogActionsRowResult = (): ReactElement => {
  const dispatch = useAppDispatch();
  const tag = useAppSelector(selectDialogTag);
  const dialogStatus = useAppSelector(selectDialogStatus);

  const result = dialogStatus === DialogStatus.SUCCESS
    ? DialogResult.SUCCESS : DialogResult.FAILED;
  const onCloseClick = () => {
    dispatch(dialogClose({ tag, result }));
  };

  return (
    <Button
      variant="contained"
      color="white"
      onClick={onCloseClick}
    >
      Close
    </Button>
  );
};
