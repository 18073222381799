import React, { ReactElement } from 'react';
import * as S from '../shared-ui/content.styles';

export const StepCustomerRegistration = (): ReactElement => (
  <>
    <S.Header>
      Customer Registration
    </S.Header>
    <S.Content>
      <ul>
        <S.ContentText>
          <li>
            Customers also need to create an account to the Platform, so that they can setup their
            billing information, choose whether they prefer card or invoice payment,
            review and download past invoices and check the current balance and time entry logs.
          </li>
        </S.ContentText>
        <S.ContentText>
          <li>
            It is important that customers add all necessary information as these will be added to
            invoices. Customer&apos;s country will also define the VAT rate.
          </li>
        </S.ContentText>
        <S.ContentText>
          <li>
            Guide customers to register at
            {' '}
            <a
              href="https://platform.gaasly.com/register"
            >
              https://platform.gaasly.com/register
            </a>
          </li>
        </S.ContentText>
      </ul>
    </S.Content>
  </>
);
