import React, { ReactElement } from 'react';
import * as S from './styles';

export const ContractDisplaySection = (
  {
    title, description, link, contractName,
  }:
  { title: string, description: string, link: string, contractName: string },
): ReactElement => (
  <>
    <S.Header variant="h5">
      {title}
    </S.Header>
    <S.Text>
      {description}
    </S.Text>
    <S.ContractLink href={link}>
      Download
      {' '}
      {contractName}
    </S.ContractLink>
  </>
);
