import React, {
  Dispatch, ReactElement, useEffect, useState,
} from 'react';
import './edit-time-entry-dialog.css';
import { Dialog, IconButton, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import { CloseOutlined } from '@mui/icons-material';
import { timeInHours } from '../../../shared/utils';
import {
  TimeEntriesActions, TimeEntriesState, TimeEntryActionType, useEditTimeEntries,
  TimeEntry,
} from '../data';
import { SnackbarContent } from '../../../shared/models';
import { CustomerSelectSearch, GSnackbar } from '../../../shared/ui';
import { CustomerListProfile } from '../../customers';

export const EditTimeEntryDialog = (
  { state, dispatch }: {
    state: TimeEntriesState,
    dispatch: Dispatch<TimeEntriesActions>,
  },
): ReactElement => {
  const [entry, setEntry] = useState<TimeEntry>(state.edit.timeEntry);
  const [notification, setNotification] = useState<SnackbarContent>({
    open: false, text: 'Editing failed', severity: 'error',
  });

  const getErrors = () => state.edit.response?.errorDetails.getErrors() || {};
  const hasErrors = () => state.edit.response?.errorDetails?.hasErrors() || false;

  useEditTimeEntries(state, dispatch);

  useEffect(() => {
    const updatedEntry = state.edit.timeEntry;
    updatedEntry.workDoneDate = new Date(updatedEntry.workDoneDate);
    setEntry(updatedEntry);
  }, [state.edit]);

  useEffect(() => {
    setNotification({ ...notification, open: hasErrors() });
  }, [state.edit]);

  const close = () => { dispatch({ type: TimeEntryActionType.EDIT_CANCEL }); };
  const save = () => {
    dispatch({ type: TimeEntryActionType.EDIT, payload: entry });
  };

  const onWorkDoneDateChange = (event: any) => {
    setEntry({ ...entry, workDoneDate: new Date(event.target.value) });
  };
  const onTimeSpentChange = (event: any) => {
    const timeSpentMinutes = event.target.value * 60;
    setEntry({ ...entry, timeSpentMinutes });
  };
  const onCustomerChange = (customer: CustomerListProfile) => {
    // On first loading, use the price from the entry we are editing,
    // when changing customers, use the value from customers's hourlyPriceEur
    const price = entry.customerId ? customer.hourlyPriceEur : entry.pricePerHour;

    setEntry({
      ...entry,
      customerId: customer.customerId,
      pricePerHour: price,
    });
  };
  const onDescriptionChange = (event: any) => {
    setEntry({ ...entry, description: event.target.value });
  };
  const onPriceChange = (event: any) => {
    setEntry({ ...entry, pricePerHour: event.target.value });
  };

  return (
    <Dialog
      open={state.edit.isDialogOpen}
      onClose={close}
    >
      <div className="edit-time-entry-container">
        <IconButton className="edit-time-entry-close-button" onClick={close} size="large">
          <CloseOutlined />
        </IconButton>
        <h1 className="edit-time-entry-header">Edit time entry</h1>
        <form
          className="edit-time-entry-form"
          noValidate
          onSubmit={close}
        >
          <TextField
            id="date"
            label="Work done date"
            variant="outlined"
            type="date"
            className="edit-time-entry-date"
            InputLabelProps={{
              shrink: true,
            }}
            value={entry.workDoneDate.toISOString().split('T')[0]}
            onChange={onWorkDoneDateChange}
            error={Object.prototype.hasOwnProperty.call(getErrors(), 'workDoneDate')}
            helperText={getErrors().workDoneDate || ''}
          />
          <TextField
            id="time-entry-work-time"
            label="Work time in hours"
            variant="outlined"
            type="number"
            className="edit-time-entry-hours"
            InputLabelProps={{
              shrink: true,
            }}
            value={timeInHours(entry.timeSpentMinutes)}
            onChange={onTimeSpentChange}
            error={Object.prototype.hasOwnProperty.call(getErrors(), 'timeSpentMinutes')}
            helperText={getErrors().timeSpentMinutes || ''}
          />
          <div className="edit-time-entry-customer">
            <CustomerSelectSearch
              errors={state.edit.response?.errorDetails}
              defaultCustomer={entry.customer}
              setCustomer={onCustomerChange}
            />
          </div>
          <TextField
            id="time-entry-price-per-hour"
            label="Price per hour (€)"
            variant="outlined"
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            defaultValue={state.edit.timeEntry.pricePerHour}
            value={entry.pricePerHour}
            onChange={onPriceChange}
            className="edit-time-entry-price-per-hour"
            error={Object.prototype.hasOwnProperty.call(getErrors(), 'pricePerHour')}
            helperText={getErrors().pricePerHour || ''}
          />
          <TextField
            id="description"
            label="Description"
            variant="outlined"
            type="text"
            className="edit-time-entry-description"
            InputLabelProps={{
              shrink: true,
            }}
            defaultValue={state.edit.timeEntry.description}
            value={entry.description}
            onChange={onDescriptionChange}
            error={Object.prototype.hasOwnProperty.call(getErrors(), 'description')}
            helperText={getErrors().description || ''}
          />
        </form>
        <Button
          type="submit"
          fullWidth={false}
          variant="contained"
          color="primary"
          onClick={save}
          className="edit-time-entry-save-button"
          disabled={state.edit.isLoading}
        >
          Save
        </Button>
      </div>
      <GSnackbar content={notification} />
    </Dialog>
  );
};
