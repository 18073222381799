import React, { useEffect, useState } from 'react';
import { fetchTimeBalance } from './api';
import { StatefulResponse } from '../../../shared/models';
import { TimeBalance } from './types';

export const useTimeBalance = (
  loadTimeBalance: number,
  growthPartnerId: string,
): StatefulResponse<TimeBalance> => {
  const [state, setState] = useState<StatefulResponse<TimeBalance>>({
    isLoading: false,
    response: null,
  });

  useEffect(() => {
    setState({ ...state, isLoading: true });
    fetchTimeBalance(growthPartnerId).then((response) => {
      setState({ isLoading: false, response });
    });
  }, [loadTimeBalance]);

  return state;
};
