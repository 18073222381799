import React, { ReactElement, useEffect, useState } from 'react';
import { DetailResponse, StatefulResponse, SnackbarContent } from '../../../shared/models';
import { GSnackbar } from '../../../shared/ui';

export const ResetPasswordConfirmSnackbar = (
  { state }: { state: StatefulResponse<DetailResponse> },
): ReactElement => {
  const [content, setContent] = useState<SnackbarContent>({
    open: false, text: '', severity: 'info',
  });

  const closeNotification = () => setContent({ ...content, open: false });

  useEffect(() => {
    if (state.isLoading) {
      setContent({ open: true, text: 'Setting new password...', severity: 'info' });
    } else if (state.response?.success === false
        && state.response.errorDetails.getNonFieldError()) {
      const errorMessage = state.response?.errorDetails.getNonFieldError();
      setContent({ open: true, text: errorMessage, severity: 'error' });
    } else if (state.response?.success === true) {
      setContent({ open: true, text: 'New password has been set.', severity: 'info' });
      setTimeout(closeNotification, 1500);
    } else {
      closeNotification();
    }
  }, [state]);

  return (
    <GSnackbar content={content} />
  );
};
