import { apiSlice, PaginatedResponse } from '../../api';
import { TimeEntry } from '../../time-tracking/data';
import { ApiInvoice, CustomerDetailProfile } from './types';

const getCustomerTimeEntriesUrl = (
  { customerId, startDate, endDate }: { customerId: string, startDate: string, endDate: string },
): string => {
  let url = `api/customers/${customerId}/time-entries/`;
  if (startDate && endDate) {
    url = `api/customers/${customerId}/time-entries`
      .concat(`?start_date=${startDate}`)
      .concat(`&end_date=${endDate}`);
  }
  return url;
};

interface CustomerDetailArgs {
  customerId: string;
}

interface GetCustomerTimeEntriesArgs {
  customerId: string;
  startDate?: string;
  endDate?: string;
}

const customerAPISlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCustomerDetail:
      builder.query <CustomerDetailProfile, CustomerDetailArgs>({
        query: ({ customerId }) => ({
          url: `api/customers/${customerId}/`,
          method: 'GET',
        }),
      }),
    getCustomerTimeEntries:
      builder.query<PaginatedResponse<TimeEntry[]>, GetCustomerTimeEntriesArgs>({
        query: ({ customerId, startDate, endDate }) => ({
          url: `/api/customers/${customerId}/time-entries/`,
        }),
      }),
    getCustomerInvoices: builder.query<ApiInvoice[], CustomerDetailArgs>({
      query: ({ customerId }) => ({
        url: `api/customers/${customerId}/invoices/`,
      }),
    }),
    checkoutCustomer: builder.mutation<void, CustomerDetailArgs>({
      query: ({ customerId }) => ({
        url: `api/customers/${customerId}/checkout/`,
        method: 'POST',
      }),
    }),
  }),
});

export const {
  useGetCustomerDetailQuery,
  useGetCustomerTimeEntriesQuery,
  useGetCustomerInvoicesQuery,
  useCheckoutCustomerMutation,
} = customerAPISlice;
