import React from 'react';
import { Snackbar } from '@mui/material';
import Alert from '@mui/material/Alert';
import { SnackbarContent } from '../models';

export const GSnackbar = (
  { content }: { content: SnackbarContent },
) => {
  const { open, text, severity } = content;

  return (
    <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={open}>
      <Alert severity={severity}>
        {text}
      </Alert>
    </Snackbar>
  );
};
