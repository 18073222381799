import React, { ReactElement } from 'react';
import { ContractDisplaySection } from './contract-display-section';
import { UserContract } from '../data';

export const ContractDisplayGpUpdate = (
  { userContract }: {userContract: UserContract},
): ReactElement => (
  <ContractDisplaySection
    title="Subcontractor Agreement has been updated"
    description="We have updated the Subcontractor Agreement. Please review and agree on the
    new terms to continue. If you have any questions, please contact Kai or Jani."
    link={userContract.contract.url}
    contractName={userContract.contract.title}
  />
);
