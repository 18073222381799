import React, { ReactElement, useEffect, useState } from 'react';
import { SnackbarContent } from '../../../shared/models';
import { GSnackbar } from '../../../shared/ui';
import { MutationResult } from '../../api';
import { AuthToken } from '../data';

export const SignInSnackbar = (
  { signInResult }: { signInResult: MutationResult<AuthToken> },
): ReactElement => {
  const [content, setContent] = useState<SnackbarContent>({
    open: false, text: '', severity: 'info',
  });

  const { isLoading, isSuccess, isError } = signInResult;
  const closeNotification = () => {
    setContent({ open: false, text: '', severity: 'info' });
  };

  useEffect(() => {
    if (isLoading) {
      setContent({ open: true, text: 'Signing in...', severity: 'info' });
    } else if (!isLoading && isError) {
      setContent({ open: true, text: 'Something went wrong. Try again later.', severity: 'error' });
      setTimeout(closeNotification, 1500);
    } else {
      closeNotification();
    }
  }, []);

  return (
    <GSnackbar content={content} />
  );
};
