import React, { ReactElement } from 'react';
import { IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Logger } from '../../../shared/utils';
import * as S from './styles';

interface MenuButtonProps {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

export const MenuButton: React.FC<MenuButtonProps> = ({ onClick }): ReactElement => {
  Logger.log('MenuButton()');
  return (
    <IconButton onClick={onClick}>
      <S.MenuAvatar>
        <AddIcon />
      </S.MenuAvatar>
    </IconButton>
  );
};
