export const objectHasKey = (obj: any, key: string): boolean => {
  if (obj) {
    return Object.prototype.hasOwnProperty.call(obj, key);
  }
  return false;
};

/**
 * Gets value of the key in object if it exists. In case not, then returns undefined.
 * @param obj
 * @param key
 */
export const getValue = (obj: any, key: string): any => {
  if (obj && objectHasKey(obj, key)) {
    return obj[key as keyof typeof obj];
  }
  return undefined;
};

export const getMapKey = <K, V>(
  map: Map<K, V>, predicate: (value: V) => boolean,
): K | undefined => {
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of map.entries()) {
    if (predicate(value)) {
      return key;
    }
  }
  return undefined; // not found
};
