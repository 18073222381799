import React, { ReactElement } from 'react';
import * as S from './styles';
import { useAuthUser } from '../../auth';
import { CustomerDetailProfile } from '../../customers';

export const InvoiceDetail = (): ReactElement => {
  const { invoicingEmail } = useAuthUser() as CustomerDetailProfile;

  return (
    <S.Text>
      Invoice will be delivered to
      {' '}
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a href="" onClick={(event) => event.preventDefault()}>
        {invoicingEmail}
      </a>
      {' '}
      with payment details at the end of each month.
    </S.Text>
  );
};
