import React, { ReactElement, useEffect, useState } from 'react';
import {
  InvitationRegistrationResponse,
  StatefulResponse,
  SnackbarContent,
} from '../../../shared/models';
import { GSnackbar } from '../../../shared/ui';

export const InvitationRegisterSnackbar = (
  { state }: { state: StatefulResponse<InvitationRegistrationResponse> },
): ReactElement => {
  const [content, setContent] = useState<SnackbarContent>({
    open: false, text: '', severity: 'info',
  });

  const closeNotification = () => { setContent({ open: false, text: '', severity: 'info' }); };

  useEffect(() => {
    if (state.isLoading) {
      setContent({ open: true, text: 'Registering...', severity: 'info' });
    } else if (!state.isLoading && state.response?.success) {
      setContent({ open: false, text: 'Registered successfully', severity: 'info' });
      setTimeout(closeNotification, 1500);
    } else if (!state.response?.success && state.response?.errorDetails.hasNonFieldErrors()) {
      setContent({
        open: true, text: state.response?.errorDetails.getNonFieldError(), severity: 'error',
      });
      setTimeout(closeNotification, 1500);
    } else {
      closeNotification();
    }
  }, [state]);

  return (
    <GSnackbar content={content} />
  );
};
