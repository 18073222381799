import React, { ReactElement, ReactNode } from 'react';
import * as S from './styles';
import { StatefulResponse } from '../../../shared/models';

export const StatefulValueText = (
  { state, children }: {
    state: StatefulResponse<any>, children: ReactNode
  },
): ReactElement => {
  if (state.isLoading || !state.response) {
    return <S.ValueText>-</S.ValueText>;
  }
  return (
    <S.ValueText>
      {children}
    </S.ValueText>
  );
};
