import styled from 'styled-components';
import { Typography } from '@mui/material';

export const NotificationsContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 0;
  border: 1px solid rgb(232 232 232);
  width: 75vw;
`;

export const NotificationRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 0 0 0;
  height: 2.5rem;
  box-shadow: rgb(232 232 232) 0px -1px 0px 0px inset;
`;

export const NotificationText = styled(Typography)`
  font-size: 0.9rem;
  display: flex;
  flex-direction: row;
  text-align: center;
`;

export const NotificationDate = styled(NotificationText)`
  width: 10%;
  margin-left: 2rem;
  color: #555555;
`;

export const NotificationTitle = styled(NotificationText)`
  width: 90%;
`;
