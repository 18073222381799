import styled from 'styled-components';
import Typography from '@mui/material/Typography';
import { Button, Checkbox as MCheckbox } from '@mui/material';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Header = styled(Typography)`
  margin-top: 1rem;
`;

export const Text = styled(Typography)`
  margin-top: 2rem;
  width: 40vw;
  text-align: center;
`;

export const ContractLink = styled.a`
  margin-top: 1rem;
  text-align: center;
`;

export const CheckBoxContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 5rem;
  width: 40vw;
  align-items: center;
  justify-content: center;
`;

export const Checkbox = styled(MCheckbox)`
  margin-right: 0.5rem;
  color: ${({ theme }) => theme.palette.primary.main};
  &.Mui-checked {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

export const SubmitButton = styled(Button)`
  margin-top: 2rem;
  align-self: center;
  width: 20vw;
`;
