import React, { ReactElement } from 'react';
import * as S from './styles';
import { CreditCardIcon } from './credit-card-icon';
import { StripeCard } from '../data/types';
import { CreditCardNumberBlock } from './credit-card-number-block';

export const CreditCardDetailsInput = (
  { card }: {
      card: StripeCard
    },
): ReactElement => {
  const {
    last4, expMonth, expYear, brand,
  } = card;
  const hiddenNumbers = '****';
  return (
    <S.StripeCardDetailsContainer variant="outlined">
      <S.CreditCardNumbers>
        <CreditCardIcon brand={brand} />
        <CreditCardNumberBlock>{hiddenNumbers}</CreditCardNumberBlock>
        <CreditCardNumberBlock>{hiddenNumbers}</CreditCardNumberBlock>
        <CreditCardNumberBlock>{hiddenNumbers}</CreditCardNumberBlock>
        <CreditCardNumberBlock>{last4}</CreditCardNumberBlock>
      </S.CreditCardNumbers>
      <S.CreditCardNumbers>
        <CreditCardNumberBlock>
          {expMonth}
          /
          {expYear}
        </CreditCardNumberBlock>
      </S.CreditCardNumbers>
    </S.StripeCardDetailsContainer>
  );
};
