import React, { ReactElement } from 'react';
import { Logger } from '../../../shared/utils';
import * as S from '../shared-ui/content.styles';
import { PaymentMethodView } from '../../payments';

export const StepPaymentMethod = (): ReactElement => {
  Logger.log('StepPaymentMethod()');
  return (
    <>
      <S.Header>
        Payment Method
      </S.Header>
      <S.HorizontallyCenteredContent>
        <S.CenteredText>
          Choose your preferred payment method: credit card or invoice.
        </S.CenteredText>
        <S.CenterVertical>
          <PaymentMethodView
            isShowPayNow={false}
            width="42rem"
          />
        </S.CenterVertical>
      </S.HorizontallyCenteredContent>
    </>
  );
};
