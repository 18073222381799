import { useContext } from 'react';
import { WizardContext } from './wizard-context';
import { WizardValues } from './types';

export const useWizard = () => {
  const context = useContext(WizardContext);

  if (!context && process.env.NODE_ENV === 'development') {
    throw Error('Wrap your step with `Wizard`');
  } else {
    return context as WizardValues;
  }
};
