import React from 'react';
import styled from 'styled-components';
import {
  Button, TextField, Typography, TypographyProps,
} from '@mui/material';
import { GrayTypography } from '../../theme';

export const Paper = styled.div`
  margin-top: ${({ theme }) => theme.spacing(2)};
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 2rem;
  justify-content: space-between;
`;

export const TimeEntryInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
`;

export const SolidWrap = styled.div`
  flex-shrink: 0;
  flex-grow: 0;
`;

export const SmallField = styled(TextField)`
  flex-shrink: 2;
  width: 8rem;
`;

export const MidField = styled(TextField)`
  width: 12rem;
  flex-shrink: 1;
  flex-grow: 0
`;

export const LargeField = styled(TextField)`
  flex-shrink: 0;
  flex-grow: 4;
`;

export const InvoicedTag = styled(GrayTypography)`
  margin-left: 1rem;
  margin-right: 1rem;
`;

export const TimeInputBalanceField = styled(SolidWrap)`
  margin-left: 1.5rem;
  margin-right: 1.5rem;
`;

export const SubmitButton = styled(Button)`
  height: 2.5rem;
  margin: auto 1rem auto 1rem;
`;

interface ColorTypographyProps extends TypographyProps {
  color: string;
}

// eslint-disable-next-line react/jsx-props-no-spreading
export const CenteredText = styled(({ color, ...otherProps }: ColorTypographyProps) => <Typography {...otherProps} />)`
  text-align: center;
  font-weight: 500;
  color: ${(props) => props.color};
`;

export const TimeBalanceViewContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 0;
  width: 20vw;
  align-items: center;
  margin-bottom: 0;
  margin-left: 0;
`;

export const TimeBalanceText = styled(Typography)`
  font-size: 1.2rem;
  color: #555555;
`;

// eslint-disable-next-line react/jsx-props-no-spreading
export const TimeBalanceValue = styled(({ color, ...otherProps }: ColorTypographyProps) => <Typography {...otherProps} />)`
  font-size: 1.7rem;
  color: ${(props) => props.color};
`;
