import React, { ReactElement } from 'react';
import { DialogActionsRowInit } from './dialog-actions-row-init';
import { DialogStatus, selectDialogStatus } from './dialog-slice';
import { useAppSelector } from '../../../app/hooks';
import { DialogActionsRowLoading } from './dialog-actions-row-loading';
import { DialogActionsRowResult } from './dialog-actions-row-result';
import * as S from './dialog.styles';

export const DialogActionsRow = (): ReactElement => {
  const dialogStatus = useAppSelector(selectDialogStatus);

  let actionsRow: ReactElement = <DialogActionsRowInit />;
  if (dialogStatus === DialogStatus.LOADING) {
    actionsRow = <DialogActionsRowLoading />;
  }
  if (dialogStatus === DialogStatus.SUCCESS || dialogStatus === DialogStatus.ERROR) {
    actionsRow = <DialogActionsRowResult />;
  }

  return (
    <S.ActionsContainer>
      {actionsRow}
    </S.ActionsContainer>
  );
};
