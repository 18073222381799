import React from 'react';
import { CardElement } from '@stripe/react-stripe-js';

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      border: '2px solid black',
      color: 'black',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSize: '16px',
      '::placeholder': {
        color: 'black',
      },
    },
    invalid: {
      color: 'red',
      iconColor: 'red',
    },
  },
};

export default function StripeCardSection() {
  return (
    <div>
      <CardElement options={CARD_ELEMENT_OPTIONS} />
    </div>
  );
}
