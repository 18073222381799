import snakecaseKeys from 'snakecase-keys';
import { apiSlice } from '../../api';
import { bodyParser } from '../../api/api-slice';
import { Budget } from '../../customers/data/types';

export interface CreateBudgetArgs {
  customerId: string,
  budget: number
}

export interface GetBudgetsArgs {
  customerId: string,
  monthCount?: number | undefined
}

const budgetAPISlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createBudget: builder.mutation<void, CreateBudgetArgs>({
      query: ({ customerId, budget }) => ({
        url: 'api/budgets/',
        method: 'POST',
        body: bodyParser({ customerId, amount: budget }),
      }),
    }),
    getBudgets: builder.query<Budget[], GetBudgetsArgs>({
      query: ({ customerId, monthCount }) => {
        const params = new URLSearchParams(
          snakecaseKeys({ customerId, months: monthCount?.toString() ?? '12' }),
        );
        return {
          url: `api/budgets/?${params}`,
          method: 'GET',
        };
      },
    }),
  }),
});

export const {
  useCreateBudgetMutation,
  useGetBudgetsQuery,
} = budgetAPISlice;
