import React, { ReactElement } from 'react';
import * as S from './styles';

export const RemoveCard = (
  { onClick, disabled }: {
    onClick: () => void,
    disabled: boolean,
  },
): ReactElement => (
  <S.RemoveButton
    size="medium"
    onClick={onClick}
    disabled={disabled}
  >
    Remove
  </S.RemoveButton>
);
