import styled from 'styled-components';

export const a = 5;

export const Columns = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem;
`;
