import React, { ReactElement } from 'react';
import * as S from './styles';
import { useCustomerListItem } from '../data/customer-list-item-context';

export const ListItemEmail = (): ReactElement => {
  const { email } = useCustomerListItem();
  return (
    <S.ListItemEmail>
      {email}
    </S.ListItemEmail>
  );
};
