import styled, { css } from 'styled-components';
import {
  Button,
  IconButton, Paper, TextField, Tooltip, Typography,
} from '@mui/material';

export const Card = styled(Paper)`
  width: 11rem;
  height: 6rem;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-areas:
    "top-left top-middle top-right"
    "mdidle-left middle-middle middle-right"
    "bottom-left bottom-middle bottom-right";
  margin: 0 1.5rem 0 0;
`;

export const CardTitle = styled(Typography)`
  grid-area: top-left;
  min-width: 0;
  min-height: 0;
  justify-self: start; // row-axis
  align-self: start; // column-axis
  font-size: 0.875rem;
  padding: 0.25rem 0 0 0.5rem;
  font-weight: 400;
  color: ${({ theme }) => theme.palette.lightText.main};
`;

export const CardValue = styled(Typography)`
  grid-area: middle-middle;
  min-width: 0;
  min-height: 0;
  justify-self: center; // row-axis
  align-self: center; // column-axis
  font-size: 1.25rem;
  font-weight: 600;
  margin: auto auto;
  color: ${({ theme }) => theme.palette.darkText.main};
`;
export const CardToolTip = styled(Tooltip)`
  grid-area: top-right;
  min-width: 0;
  min-height: 0;
  width: 2rem;
  height: 2rem;
  justify-self: end;
  margin-right: 0.25rem;
`;

export const CardActionButton = styled(IconButton)`
  grid-area: bottom-right;
  min-width: 0;
  min-height: 0;
  width: 2rem;
  height: 2rem;
  justify-self: end;
  margin-right: 0.25rem;
  margin-bottom: 0.25rem;
`;

export const CardInputText = styled(TextField)`
  grid-area: middle-middle;
  min-width: 0;
  min-height: 0;
`;

export const CardMainButton = styled(Button)`
  grid-area: bottom-middle;
  min-width: 0;
  min-height: 0;
  justify-self: center;
  align-self: center;
  margin: auto auto;
  color: ${({ theme }) => theme.palette.darkText.main};
`;
