import { useEffect, useState } from 'react';
import { loadAuthUser } from '../../auth';
import { fetchUserContracts, signUserContract } from './api';
import { UserContract } from './types';
import { StatefulResponse } from '../../../shared/models';

export const useListUserContracts = (
  userId: string,
): StatefulResponse<UserContract[]> => {
  const [state, setState] = useState<StatefulResponse<UserContract[]>>({
    isLoading: false,
    response: null,
  });

  useEffect(() => {
    setState({ isLoading: true, response: null });
    fetchUserContracts(userId, false).then((response) => {
      setState({ isLoading: false, response });
    });
  }, [userId]);
  return state;
};

export const useSignUserContract = (
  userId: string,
  userContractId: string,
  isAgreedChecked: boolean,
  submitTs: number,
): StatefulResponse<UserContract> => {
  const [state, setState] = useState<StatefulResponse<UserContract>>({
    isLoading: false,
    response: null,
  });

  useEffect(() => {
    if (isAgreedChecked && submitTs > 0) {
      setState({ isLoading: true, response: null });
      signUserContract(userId, userContractId).then((response) => {
        loadAuthUser().then(() => {
          setState({ isLoading: false, response });
        });
      });
    }
  }, [userId, userContractId, isAgreedChecked, submitTs]);

  return state;
};
