import React, { createContext, ReactNode, useContext } from 'react';
import { CustomerListProfile } from './types';

export const CustomerListItemContext = createContext<CustomerListProfile>(
    {} as CustomerListProfile,
);

export const CustomerListItemProvider = (
  { customer, children }: { customer: CustomerListProfile, children: ReactNode },
) => (
  <CustomerListItemContext.Provider value={customer}>
    {children}
  </CustomerListItemContext.Provider>
);

export const useCustomerListItem = () => useContext(CustomerListItemContext);
