import React, { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import * as S from './styles';
import { GaaslyRoutes } from '../routes';

export const GrowthPartnerNavBar = (): ReactElement => {
  const navigate = useNavigate();

  const openTimeTracking = () => {
    navigate(GaaslyRoutes.timeTracking);
  };
  const openDashboard = () => {
    navigate(GaaslyRoutes.growthPartnerDashboard);
  };
  const openCustomerListing = () => {
    navigate(GaaslyRoutes.customersListing);
  };

  return (
    <div>
      <S.NavAction color="inherit" onClick={openDashboard}>
        Dashboard
      </S.NavAction>
      <S.NavAction color="inherit" onClick={openTimeTracking}>
        Time tracking
      </S.NavAction>
      <S.NavAction color="inherit" onClick={openCustomerListing}>
        Customers
      </S.NavAction>
    </div>
  );
};
