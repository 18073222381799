import {
  ApiCollectionParser, ApiMethod, ApiObjectParser, ApiService,
} from '../../api-legacy';
import { UserContract } from './types';
import { parseUrl } from '../../../shared/utils';
import { GaaslyResponse } from '../../../shared/models';
import { getAuthToken } from '../../auth/data';

const API_URL = process.env.REACT_APP_API_URL;
const USERS_URL = `${API_URL}/api/users/`;

export const getUserContractsUrl = (userId: string, isSigned?: boolean): string => {
  const queryParams = new Map<string, any>();
  if (isSigned !== undefined) {
    queryParams.set('isSigned', isSigned);
  }
  const baseUrl = `${USERS_URL}${userId}/contracts/`;
  return parseUrl(baseUrl, queryParams);
};

export const getUserContractSignUrl = (userId: string, userContractId: string): string => `${USERS_URL}${userId}/contracts/${userContractId}/sign/`;

export function fetchUserContracts(
  userId: string,
  isSigned?: boolean,
): Promise<GaaslyResponse<UserContract[]>> {
  const url = getUserContractsUrl(userId, isSigned);
  return new ApiService<UserContract[]>(url)
    .setMethod(ApiMethod.GET)
    .setAuth(getAuthToken())
    .setParser(new ApiCollectionParser())
    .request();
}

export function signUserContract(
  userId: string,
  userContractId: string,
): Promise<GaaslyResponse<UserContract>> {
  const url = getUserContractSignUrl(userId, userContractId);
  return new ApiService<UserContract>(url)
    .setMethod(ApiMethod.PUT)
    .setAuth(getAuthToken())
    .setParser(new ApiObjectParser())
    .request();
}
