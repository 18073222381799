import React, { ReactElement } from 'react';
import { IconButton } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import * as S from './card.styles';
import { roundToTwoDecimals } from '../../../shared/utils';

export const BalanceCard = ({ balance }: {balance: number}): ReactElement => {
  const roundedBalance = roundToTwoDecimals(balance);
  return (
    <S.Card>
      <S.CardTitle>Balance</S.CardTitle>
      <S.CardValue>
        {roundedBalance}
        €
      </S.CardValue>
      <S.CardToolTip title="Balance is calculated with 0% VAT.">
        <IconButton size="small">
          <InfoOutlined />
        </IconButton>
      </S.CardToolTip>
    </S.Card>
  );
};
