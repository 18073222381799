import React, { ReactElement } from 'react';
import { ListChildComponentProps } from 'react-window';
import { ListItem } from '@mui/material';
import * as S from './customer-time-entry-list-item.styles';
import { TimeEntry } from '../../time-tracking/data';
import { formatDate, timeInHours } from '../../../shared/utils';
import { GrayTag } from '../../core';

export const CustomerTimeEntryListItem = (
  props: ListChildComponentProps<TimeEntry[]>,
): ReactElement => {
  const { index, style, data } = props;

  const {
    workDoneDate, timeSpentMinutes, description, growthPartner, receiptUuid,
  } = data[index];

  const isInvoiced = () => receiptUuid !== null && receiptUuid !== undefined;

  return (
    <ListItem style={style} key={index} component="div" disablePadding>
      <S.Row>
        <S.FieldNarrow>{formatDate(new Date(workDoneDate))}</S.FieldNarrow>
        <S.FieldWide>{description}</S.FieldWide>
        <S.FieldNarrow>{`${growthPartner?.firstName} ${growthPartner?.lastName}`}</S.FieldNarrow>
        <S.FieldTiny>{`${timeInHours(timeSpentMinutes)}h`}</S.FieldTiny>
        <S.FieldTiny>
          {isInvoiced() && <GrayTag>Invoiced</GrayTag>}
        </S.FieldTiny>
      </S.Row>
    </ListItem>
  );
};
