import React, { ReactElement } from 'react';
import { Grid } from '@mui/material';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { SerializedError } from '@reduxjs/toolkit';
import * as S from './customer-profile.styles';
import { FieldSize, TextInputField } from '../shared-ui';
import {
  AdditionalBillingInfoInput,
  customerProfileAddressLine1Change,
  customerProfileAddressLine2Change,
  customerProfileCityChange,
  customerProfileCompanyNameChange,
  customerProfileEmailChange,
  customerProfileFirstNameChange,
  customerProfileInvoicingEmailChange,
  customerProfileLastNameChange,
  customerProfilePostalCodeChange,
  customerProfileVatIdChange,
  ProfileCountrySelect,
  selectCustomerProfileAddressLine1,
  selectCustomerProfileAddressLine2,
  selectCustomerProfileCity,
  selectCustomerProfileCompanyName,
  selectCustomerProfileEmail,
  selectCustomerProfileFirstName,
  selectCustomerProfileInvoicingEmail,
  selectCustomerProfileLastName,
  selectCustomerProfilePostalCode,
  selectCustomerProfileVatId,
} from './index';

export const CustomerProfileForm = (
  { error }: { error: FetchBaseQueryError | SerializedError | undefined },
): ReactElement => (
  <S.Columns>
    <S.Form>
      <Grid container spacing={1}>
        <TextInputField
          label="Company Name"
          fieldName="companyName"
          selector={selectCustomerProfileCompanyName}
          action={customerProfileCompanyNameChange}
          error={error}
          size={FieldSize.FULL}
        />
        <TextInputField
          label="First name"
          fieldName="firstName"
          selector={selectCustomerProfileFirstName}
          action={customerProfileFirstNameChange}
          error={error}
          size={FieldSize.HALF}
        />
        <TextInputField
          label="Last Name"
          fieldName="lastName"
          selector={selectCustomerProfileLastName}
          action={customerProfileLastNameChange}
          error={error}
          size={FieldSize.HALF}
        />
        <TextInputField
          label="Email"
          fieldName="email"
          selector={selectCustomerProfileEmail}
          action={customerProfileEmailChange}
          error={error}
        />
        <TextInputField
          label="Invoicing email"
          fieldName="invoicingEmail"
          selector={selectCustomerProfileInvoicingEmail}
          action={customerProfileInvoicingEmailChange}
          error={error}
        />
        <TextInputField
          label="Address line 1"
          fieldName="addressLine1"
          selector={selectCustomerProfileAddressLine1}
          action={customerProfileAddressLine1Change}
          error={error}
        />
      </Grid>
    </S.Form>
    <S.Form>
      <Grid container spacing={1}>
        <TextInputField
          label="Address line 2"
          fieldName="addressLine2"
          selector={selectCustomerProfileAddressLine2}
          action={customerProfileAddressLine2Change}
          error={error}
        />
        <TextInputField
          label="City"
          fieldName="city"
          selector={selectCustomerProfileCity}
          action={customerProfileCityChange}
          error={error}
          size={FieldSize.HALF}
        />
        <TextInputField
          label="Postal code"
          fieldName="postalCode"
          selector={selectCustomerProfilePostalCode}
          action={customerProfilePostalCodeChange}
          error={error}
          size={FieldSize.HALF}
        />
        <ProfileCountrySelect error={error} />
        <TextInputField
          label="European VAT ID"
          fieldName="vatId"
          selector={selectCustomerProfileVatId}
          action={customerProfileVatIdChange}
          error={error}
        />
        <AdditionalBillingInfoInput />
      </Grid>
    </S.Form>
  </S.Columns>
);
