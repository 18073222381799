import React, { ReactElement } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import * as S from './customer-invoice-list.styles';
import { Invoice } from '../data/types';
import { CustomerInvoiceListItem } from './customer-invoice-list-item';
import { CustomerInvoiceListHeader } from './customer-invoice-list-header';
import { useGetCustomerInvoicesQuery } from '../data/customers-api-slice';

export const CustomerInvoiceList = (
  { customerId }: { customerId: string },
): ReactElement => {
  const res = useGetCustomerInvoicesQuery({ customerId });
  const { data } = res;

  const invoices = data?.map((invoice) => new Invoice(invoice)) || [];

  return (
    <S.GridInvoicesSection>
      <S.Container>
        <S.SectionHeader>Invoices</S.SectionHeader>
        <AutoSizer>
          {({ width = 0 }) => (
            <>
              <CustomerInvoiceListHeader listWidth={width} />
              <FixedSizeList<Invoice[]>
                itemSize={48}
                height={245}
                width={width}
                itemCount={invoices.length}
                overscanCount={5}
                itemData={invoices}
              >
                {CustomerInvoiceListItem}
              </FixedSizeList>
            </>
          )}
        </AutoSizer>
      </S.Container>
    </S.GridInvoicesSection>
  );
};
