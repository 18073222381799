import styled from 'styled-components';
import { List, ListItem, ListItemText } from '@mui/material';
import { TextDark } from '../../theme/text.styles';

export const SubHeader = styled(TextDark)`
  font-size: 1.2rem;
`;

export const FieldList = styled(List)`
  padding: 0;
  list-style-type: disc;
  list-style-position: inside;
`;

export const StyledListItem = styled(ListItem)`
  padding: 0;

  &::before {
    content: '•'; // Unicode bullet character
    color: black; // Bullet color
    display: inline-block;
    width: 1em; // Space before bullet
    margin-left: 1em; // Shift bullet outside the text indent
  }
`;

export const StyledListItemText = styled(ListItemText)`
  & .MuiListItemText-primary {
    font-size: 0.875rem; // 14px
    font-weight: 400;
    color: ${({ theme }) => theme.palette.darkText.main};
  }
`;

export const ContractWarning = styled(TextDark)`
  font-size: 1rem;
`;
