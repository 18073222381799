import React, { ReactElement } from 'react';
import { CodeResponse, useGoogleLogin } from '@react-oauth/google';
import { Button } from '@mui/material';
import * as S from './styles';
import { useGrowthPartnerSignInMutation } from '../data/auth-api-slice';
import { SignInSnackbar } from './sign-in-snackbar';
import { AuthToken } from '../data';
import { MutationResult } from '../../api';

export const GoogleSignIn = (): ReactElement => {
  const [signIn, signInResult] = useGrowthPartnerSignInMutation();
  const googleSignIn = useGoogleLogin({
    onSuccess: (response: CodeResponse) => {
      signIn({ code: response.code || '' });
    },
    flow: 'auth-code',
  });

  return (
    <S.Container>
      <S.SignInSectionHeader>
        <S.HeaderText>
          Gaasly Platform
        </S.HeaderText>
        <S.DescriptionText>
          Growth Partner Sign In
        </S.DescriptionText>
      </S.SignInSectionHeader>

      <Button
        variant="contained"
        color="blue"
        onClick={() => googleSignIn()}
      >
        SIGN IN
      </Button>
      <S.Link href="/login" underline="hover">
        Looking for Customer Login?
      </S.Link>
      <SignInSnackbar signInResult={signInResult as MutationResult<AuthToken>} />
    </S.Container>
  );
};
