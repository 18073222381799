import React, { ReactElement } from 'react';
import { Navigate } from 'react-router-dom';
import { getStoreAuthUser } from '../auth/data/token-store';
import { Logger } from '../../shared/utils';
import { GaaslyRoutes } from './gaasly-routes';

export const RequireOnboardingFinished = (
  { children }: { children: ReactElement },
): ReactElement => {
  Logger.log('RequireOnboarding()');
  const authUser = getStoreAuthUser();
  if (authUser?.isGrowthPartner && !authUser.isOnboardingDone) {
    return <Navigate to={GaaslyRoutes.growthPartnerOnboarding} />;
  }
  if (authUser?.isCustomer && !authUser.isOnboardingDone) {
    return <Navigate to={GaaslyRoutes.customerOnboarding} />;
  }
  return children;
};
