import styled from 'styled-components';
import { Paper } from '@mui/material';
import { H1, TextDark } from '../../theme/text.styles';

export const ProfileContainer = styled(Paper)`
  display: flex;
  flex-direction: column;
  width: 45rem;
  height: 36rem;
  margin: 5rem auto;
`;

export const Header = styled(H1)`
  margin: auto 1rem;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid ${({ theme }) => theme.palette.lightGray.main};
  border-bottom: 1px solid ${({ theme }) => theme.palette.lightGray.main};
  background: #f6f8fb;
`;

export const CenteredText = styled(TextDark)`
  font-size: 1rem;
  margin: 1rem 3rem;
  text-align: center;
`;

export const ContentText = styled(TextDark)`
  font-size: 1rem;
  margin: 1rem 1rem 1rem 1rem;
`;
