import React, { ReactElement, ReactNode } from 'react';
import * as S from './styles';
import { StatefulValueText } from './stateful-value-text';
import { StatefulResponse } from '../../../shared/models';
import { TimeStats } from '../data';

export const GridItem = (
  { state, header, value }: {
    state: StatefulResponse<TimeStats>, header: string, value: ReactNode
  },
): ReactElement => (
  <S.GridItem>
    <S.DescriptionText variant="body1">
      {header}
    </S.DescriptionText>
    <StatefulValueText state={state}>
      {value}
    </StatefulValueText>
  </S.GridItem>
);
