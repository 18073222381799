import { GaaslyError } from '../../shared/models';
import { Logger } from '../../shared/utils';

export abstract class ApiParser<T> {
  abstract parseResponse(response: Response): Promise<unknown>

  // eslint-disable-next-line class-methods-use-this
  parseError(data: any): GaaslyError {
    Logger.log(`APIService parseErrors() ${JSON.stringify(data)}`);
    let error: GaaslyError;
    try {
      error = new GaaslyError(data);
    } catch (e) {
      Logger.log(`APIService error in parseErrors() ${JSON.stringify(e)}`);
      error = GaaslyError.getDefaultError();
    }
    return error;
  }

  // eslint-disable-next-line class-methods-use-this
  async parseAPIObject(response: Response): Promise<any> {
    let apiObject = {};
    try {
      apiObject = await response.json();
    } catch (e) {
      Logger.log('ApiService parseResponse no parseable body');
    }

    return apiObject;
  }
}
