import React, { ReactElement } from 'react';
import { TextField } from '@mui/material';
import Grid from '@mui/material/Grid';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { SerializedError } from '@reduxjs/toolkit';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { RootState } from '../../../app/store';
import { getValue, objectHasKey } from '../../core';

export const FieldSize = {
  HALF: 6,
  FULL: 12,
};

export type FieldSizeType = typeof FieldSize[keyof typeof FieldSize];

export const TextInputField = (
  {
    label,
    fieldName,
    selector = () => '',
    error,
    action = undefined,
    size = FieldSize.FULL,
    disabled = false,
  }: {
    label: string,
    fieldName: string,
    selector?: (state: RootState) => unknown,
    error: FetchBaseQueryError | SerializedError | undefined,
    action?: (value: any) => any,
    size?: FieldSizeType,
    disabled?: boolean,
  },
): ReactElement => {
  const id = label.replace(' ', '-').toLocaleLowerCase();
  const dispatch = useAppDispatch();
  const value = useAppSelector(selector);

  const errorData = getValue(error, 'data');
  const hasFieldError = objectHasKey(errorData, fieldName);
  const helperText = getValue(errorData, fieldName) as string;

  const onValueChange = (event: any) => {
    if (action) {
      const newValue = event.target.value;
      dispatch(action(newValue));
    }
  };

  return (
    <Grid item xs={size}>
      <TextField
        disabled={disabled}
        error={hasFieldError}
        helperText={helperText}
        name={id}
        id={id}
        variant="outlined"
        fullWidth
        label={label}
        value={value}
        onChange={onValueChange}
        sx={{
          '& .MuiInputBase-input': {
            backgroundColor: 'white !important', // Using !important to ensure the style is applied
          },
        }}
      />
    </Grid>
  );
};

TextInputField.defaultProps = {
  selector: () => '',
  action: undefined,
  size: FieldSize.FULL,
  disabled: false,
};
