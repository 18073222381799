import React, { ReactElement, useEffect } from 'react';
import {
  Grid, MenuItem, Select, SelectChangeEvent,
} from '@mui/material';
import Alert from '@mui/material/Alert';
import * as S from './styles';
import { getStoreAuthUser } from '../../auth/data/token-store';
import { CustomerDetailProfile } from '../../customers';
import { useGetPaymentMethodQuery, useUpdatePaymentMethodMutation } from '../data/payments-api-slice';
import { RtkQueryErrorSnackbar } from '../../core/rtk-query-error-snackbar';
import { PaymentMethod, PaymentProfile } from '../data/types';
import { MutationResult } from '../../api';

export const PaymentMethodSelect = (
  { paymentMethod, setPaymentMethod }: {
    paymentMethod: PaymentMethod | null,
    setPaymentMethod: (paymentMethod: PaymentMethod) => void,
  },
): ReactElement => {
  const { customerId } = getStoreAuthUser() as CustomerDetailProfile;
  const paymentMethodRes = useGetPaymentMethodQuery(customerId);
  const [updatePaymentMethod, updatePaymentMethodResult] = useUpdatePaymentMethodMutation();

  useEffect(() => {
    if (paymentMethodRes.isSuccess && paymentMethodRes.data) {
      setPaymentMethod(paymentMethodRes.data.paymentMethod);
    }
    if (updatePaymentMethodResult.isSuccess && updatePaymentMethodResult.data) {
      setPaymentMethod(updatePaymentMethodResult.data.paymentMethod);
    }
    if (updatePaymentMethodResult.isError) {
      paymentMethodRes.refetch();
    }
  }, [paymentMethodRes, updatePaymentMethodResult]);

  const onPaymentMethodChange = (
    event: SelectChangeEvent<PaymentMethod | null>,
  ) => {
    const newPaymentMethod = event.target.value as PaymentMethod;
    setPaymentMethod(newPaymentMethod);
    updatePaymentMethod(
      { customerId, paymentMethod: newPaymentMethod },
    );
  };

  return (
    <Grid item xs={3}>
      <S.FormControl size="small">
        <Select
          name="payment-method-select"
          id="payment-method-select"
          labelId="payment-method-select-label"
          variant="outlined"
          value={paymentMethod}
          onChange={onPaymentMethodChange}
        >
          {
            Object.values(PaymentMethod).map((method) => (
              <MenuItem value={method} key={method}>
                {method}
              </MenuItem>
            ))
          }
        </Select>
      </S.FormControl>
      <S.HelperText>Select payment method</S.HelperText>
      <RtkQueryErrorSnackbar
        results={[updatePaymentMethodResult as MutationResult<PaymentProfile>]}
      >
        <Alert severity="error">
          Failed to update payment method
        </Alert>
      </RtkQueryErrorSnackbar>
    </Grid>
  );
};
