import { CustomerDetailProfile, placeholderCustomer } from '../../customers';
import { GrowthPartnerProfile, placeHolderGrowthPartner } from '../../../shared/models';

export type CreateTimeEntryResponse = {
  timeEntry: TimeEntry,
  warnings?: any,
}

export type TimeEntry = {
  uuid: string,
  timeSpentMinutes: number,
  pricePerHour: number,
  workDoneDate: Date,
  lastModified: Date,
  description: string,
  customerId: string,
  customer: CustomerDetailProfile | null,
  growthPartner: GrowthPartnerProfile | null,
  receiptUuid: string | null,
}

export const placeholderTimeEntry: TimeEntry = {
  uuid: '',
  timeSpentMinutes: 60,
  pricePerHour: 80,
  workDoneDate: new Date(),
  lastModified: new Date(),
  description: '',
  customerId: '',
  customer: placeholderCustomer,
  growthPartner: placeHolderGrowthPartner,
  receiptUuid: null,
};

export type TimeBalance = {
  hourBalance: number,
}

export type TimeEntryRow = {
  id: string,
  workDoneDate: string,
  customerEmail: string,
  timeInHours: string
  pricePerHour: string,
  total: string,
  receiptUuid: string | null,
}
