import React, { ReactElement } from 'react';
import * as SC from '../../onboarding/shared-ui/content.styles';
import { useAppSelector } from '../../../app/hooks';
import { selectCustomerProfile } from './index';
import { MutationAction, MutationResult } from '../../api';
import { CustomerDetailProfile } from '../../customers';

export const CustomerAccountInfoSave = (
  { saveProfile, saveProfileRes }: {
    saveProfile: MutationAction<CustomerDetailProfile>,
    saveProfileRes: MutationResult<CustomerDetailProfile>
  },
): ReactElement => {
  const profile = useAppSelector(selectCustomerProfile);

  const {
    isLoading,
  } = saveProfileRes;

  const onSave = () => {
    saveProfile(profile);
  };

  return (
    <SC.LoadingButtonExtra
      variant="contained"
      color="blue"
      onClick={onSave}
      loading={isLoading}
      loadingPosition="start"
    >
      <span>Save</span>
    </SC.LoadingButtonExtra>
  );
};
