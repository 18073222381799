import React, { ReactElement } from 'react';
import * as S from './billing-info.styles';

export const MultiRowBox = (
  { field, value }: { field: string, value: string },
): ReactElement => (
  <S.MultiRowBox>
    <S.RowField>
      <S.TextLightSmall>{field}</S.TextLightSmall>
    </S.RowField>
    <S.TextDarkSmallBold>{value}</S.TextDarkSmallBold>
  </S.MultiRowBox>
);
