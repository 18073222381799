import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem;
`;

export const h2 = styled.h2`
  margin-bottom: 0;

`;
