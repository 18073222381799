import React, { ReactElement } from 'react';
import { Navigate } from 'react-router-dom';
import { getStoreAuthUser } from '../auth/data/token-store';
import { Logger } from '../../shared/utils';
import { GaaslyRoutes } from './gaasly-routes';

export const RequireContractsSigned = (
  { children }: { children: ReactElement, },
): ReactElement => {
  Logger.log('RequireContractsSigned()');
  const authUser = getStoreAuthUser();

  if (authUser?.hasPendingContracts) {
    return <Navigate to={GaaslyRoutes.userContracts} />;
  }

  return children;
};
