import React, { ReactElement } from 'react';
import { ContractDisplaySection } from './contract-display-section';
import { UserContract } from '../data';

export const ContractDisplayCustomerOnboarding = (
  { userContract }: {userContract: UserContract},
): ReactElement => {
  const { url, title } = userContract.contract;
  return (
    <ContractDisplaySection
      title=""
      description=""
      link={url}
      contractName={title}
    />
  );
};
