import React, { ReactElement, useEffect, useState } from 'react';
import { GSnackbar } from '../../../shared/ui';
import { SnackbarContent, StatefulResponse } from '../../../shared/models';

export const ConfirmEmailSnackbar = (
  { state }: { state: StatefulResponse<any> },
): ReactElement => {
  const [content, setContent] = useState<SnackbarContent>({
    open: false, text: '', severity: 'info',
  });

  const errorText = 'Email confirmation failed.The link may be invalid, expired, or already'
    + ' used. If already used, your email is confirmed, and your account is active.';

  useEffect(() => {
    if (!state.isLoading) {
      if (state.response?.success) {
        setContent({
          ...content, open: true, text: 'Email confirmed', severity: 'success',
        });
      } else if (!state.response?.success && state.response?.errorDetails.hasErrors()) {
        setContent({
          ...content,
          open: true,
          text: errorText,
          severity: 'error',
        });
      }
    }
  }, [state]);

  return (
    <GSnackbar content={content} />
  );
};
