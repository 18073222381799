import React, { ReactElement } from 'react';
import { Typography } from '@mui/material';
import * as S from './register.styles';

export const RegisterEmailSentPage = (): ReactElement => (
  <S.Paper>
    <Typography>
      Please check your email for email confirmation link to proceed.
    </Typography>
  </S.Paper>
);
