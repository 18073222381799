import { apiSlice } from '../../api';
import { CustomerDetailProfile } from '../../customers';
import { bodyParser } from '../../api/api-slice';
import { UserProfile } from '../../../shared/models';

const extendedApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCustomerProfile: builder.query<CustomerDetailProfile, void>({
      query: () => 'api/customers/me/',
    }),
    saveCustomerProfile: builder.mutation<CustomerDetailProfile, CustomerDetailProfile>({
      query: (profile) => ({
        url: 'api/customers/me/',
        method: 'PUT',
        body: bodyParser(profile),
      }),
    }),
    getGrowthPartnerProfile: builder.query<UserProfile, void>({
      query: () => 'api/growth-partners/me/',
    }),
    saveGrowthPartnerProfile: builder.mutation<UserProfile, UserProfile>({
      query: (profile) => ({
        url: 'api/growth-partners/me/',
        method: 'PUT',
        body: bodyParser(profile),
      }),
    }),
  }),
});

export const {
  useGetCustomerProfileQuery,
  useSaveCustomerProfileMutation,
  useGetGrowthPartnerProfileQuery,
  useSaveGrowthPartnerProfileMutation,
} = extendedApi;
