import { useEffect, useState } from 'react';
import { AuthToken, customerLogin } from './index';
import { StatefulResponse } from '../../../shared/models';

export const useCustomerLogin = (
  email: string,
  password: string,
  loginEvent: number,
): StatefulResponse<AuthToken> => {
  const [state, setState] = useState<StatefulResponse<AuthToken>>({
    isLoading: false,
    response: null,
  });

  useEffect(() => {
    if (loginEvent !== 0) {
      setState({ isLoading: true, response: null });
      customerLogin(email, password).then((response) => {
        setState({ isLoading: false, response });
      });
    }
  }, [loginEvent]);

  return state;
};
