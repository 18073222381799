import snakecaseKeys from 'snakecase-keys';
import { GaaslyCollectionResponse, GaaslyResponse } from '../../shared/models';
import { ApiParser } from './api-parser';
import { logout } from '../auth/data';
import { Logger } from '../../shared/utils';

export enum ApiMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE'
}

export class ApiService<T> {
  private DEFAULT_HEADERS = {
    Accept: 'application/json',
    'Content-Type': 'application/json;charset=UTF-8',
  };

  private url: string;

  private method: ApiMethod = ApiMethod.GET;

  private headers: Record<string, string> = this.DEFAULT_HEADERS;

  private authToken: string | null = null;

  private requestBody: any;

  private parser: ApiParser<T> | null = null;

  constructor(url: string) {
    this.url = url;
  }

  public setMethod(newMethod: ApiMethod): this {
    this.method = newMethod;
    return this;
  }

  public setHeaders(headers: Record<string, string>): this {
    this.headers = headers;
    return this;
  }

  public setAuth(newAuthToken: string): this {
    this.authToken = newAuthToken;
    return this;
  }

  public setRequestBody(requestBody: any): this {
    this.requestBody = requestBody;
    return this;
  }

  public setParser(parser: ApiParser<T>): this {
    this.parser = parser;
    return this;
  }

  public request(): Promise<GaaslyResponse<T>> {
    return this.doRequest()
      .then((response) => this.parser!.parseResponse(response))
      .catch((e) => GaaslyResponse.getDefaultError()) as Promise<GaaslyResponse<T>>;
  }

  public requestCollection(): Promise<GaaslyCollectionResponse<T>> {
    return this.doRequest()
      .then((response) => this.parser!.parseResponse(response))
      .catch((e) => GaaslyCollectionResponse.getDefaultError()) as
      Promise<GaaslyCollectionResponse<T>>;
  }

  private buildRequest(): RequestInit {
    if (this.authToken !== null) {
      Object.assign(this.headers, { Authorization: `Token ${this.authToken}` });
    }

    const requestInit: RequestInit = {
      method: this.method,
      headers: this.headers,
    };

    if (this.requestBody) {
      const body = snakecaseKeys(this.requestBody);
      requestInit.body = JSON.stringify(body);
      Logger.log(`requestBody ${requestInit.body}`);
    }
    return requestInit;
  }

  private doRequest(): Promise<Response> {
    if (this.parser === null) {
      throw new Error('Parser is not initialized. Use setParser() method to set the parser.');
    }
    const requestInit = this.buildRequest();

    return fetch(this.url, requestInit)
      .then((response) => this.handle401(response));
  }

  // eslint-disable-next-line class-methods-use-this
  private handle401(response: Response) {
    if (!response.ok && response.status === 401) {
      logout();
    }
    return response;
  }
}
