import { GaaslyResponse } from '../../shared/models';
import { ApiMethod, ApiService } from './api-service';
import { getAuthToken } from '../auth';
import { CustomerDetailProfile } from '../customers';
import { ApiObjectParser } from './api-object-parser';

const API_URL = process.env.REACT_APP_API_URL;
const PROFILE_URL = `${API_URL}/api/users/me/`;

export const fetchMyCustomerProfile = (): Promise<GaaslyResponse<CustomerDetailProfile>> => new
ApiService<CustomerDetailProfile>(PROFILE_URL)
  .setMethod(ApiMethod.GET)
  .setAuth(getAuthToken())
  .setParser(new ApiObjectParser<CustomerDetailProfile>())
  .request();
