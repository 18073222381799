import React, { ReactElement, useEffect } from 'react';
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import * as S from './step-contract.styles';
import * as SA from './content.styles';
import { Logger } from '../../../shared/utils';
import { useSignContractAndUpdateUserMutation } from '../../contracts/data/contracts-api-slice';
import { UserContract } from '../../contracts/data';

export const StepContractPendingContract = (
  {
    title,
    userId,
    userContract,
    isFetchingContracts,
    refetchContracts,
  }: {
    title: string,
    userId: string,
    userContract: UserContract | null,
    isFetchingContracts: boolean,
    refetchContracts: () => any
  },
): ReactElement => {
  Logger.log('StepContractPendingContract()');
  const [sign, signResponse] = useSignContractAndUpdateUserMutation();
  const [isChecked, setIsChecked] = React.useState(false);
  const handleCheckBoxChange = (event: any) => {
    setIsChecked(!isChecked);
  };

  const isLoading = isFetchingContracts || signResponse.isLoading;

  useEffect(() => {
    if (signResponse.isSuccess) {
      refetchContracts();
    }
  }, [signResponse]);

  return (
    <S.ContractContainer>
      <SA.CenteredText>
        Download and carefully read the
        {' '}
        {title}
        .
      </SA.CenteredText>
      <SA.CenteredText>
        <a href={userContract?.contract.url}>{title}</a>
      </SA.CenteredText>
      <S.CheckBoxRow>
        <S.CheckBox
          checked={isChecked}
          onChange={handleCheckBoxChange}
        />
        <SA.CenteredTextNoMargin>
          I confirm that I have read and agreed to terms of this
          {' '}
          {title}
          .
        </SA.CenteredTextNoMargin>
      </S.CheckBoxRow>
      <SA.LoadingButton
        variant="contained"
        color="blue"
        onClick={() => sign({ userId, userContractId: userContract?.uuid ?? '' })}
        disabled={!isChecked}
        loading={isLoading}
        loadingPosition="start"
        startIcon={<HandshakeOutlinedIcon />}
      >
        <span>Agree</span>
      </SA.LoadingButton>
    </S.ContractContainer>
  );
};
