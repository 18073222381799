import React, {
  Dispatch, ReactElement, useEffect, useState,
} from 'react';
import Button from '@mui/material/Button';
import {
  Dialog, DialogActions, DialogTitle,
} from '@mui/material';
import {
  TimeEntriesActions, TimeEntriesState, TimeEntryActionType, useDeleteTimeEntries,
} from '../data';
import { SnackbarContent } from '../../../shared/models';
import { GSnackbar } from '../../../shared/ui';

export const DeleteTimeEntryDialog = (
  { state, dispatch }: {
    state: TimeEntriesState,
    dispatch: Dispatch<TimeEntriesActions>,
  },
): ReactElement => {
  useDeleteTimeEntries(state, dispatch);
  const [notification, setNotification] = useState<SnackbarContent>({
    open: false, text: 'Deleting entry failed', severity: 'error',
  });

  const hasErrors = () => state.edit.response?.errorDetails?.hasErrors() || false;
  const close = () => { dispatch({ type: TimeEntryActionType.DELETE_CANCEL }); };
  const deleteEntry = () => { dispatch({ type: TimeEntryActionType.DELETE }); };

  useEffect(() => {
    setNotification({ ...notification, open: hasErrors() });
  }, [state.delete]);

  return (
    <Dialog
      open={state.delete.isDialogOpen}
      onClose={close}
    >
      <DialogTitle id="alert-dialog-title">
        Are you sure you wish to delete this entry?
      </DialogTitle>
      <DialogActions>
        <Button
          onClick={close}
          color="primary"
          disabled={state.delete.isLoading}
        >
          Cancel
        </Button>
        <Button
          onClick={deleteEntry}
          color="primary"
          autoFocus
          disabled={state.delete.isLoading}
        >
          Delete
        </Button>
      </DialogActions>
      <GSnackbar content={notification} />
    </Dialog>
  );
};
