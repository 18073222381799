import styled from 'styled-components';
import { Link as MuiLink, Paper } from '@mui/material';
import { H3, TextLight } from '../../theme/text.styles';

export const Container = styled(Paper)`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 17vw;
  height: 19vh;
  margin: 25vh auto auto auto;
  justify-content: end;
  align-items: center;
`;

export const SignInSectionHeader = styled(Paper)`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 15vw;
  height: 8vh;
  top: -3vh;
  left: 0;
  right: 0;
  justify-self: center;
  background-image: linear-gradient(to bottom left, #60A0EA, #3972E0);
`;

export const HeaderText = styled(H3)`
  margin-top: 1rem;
  color: white;
`;

export const DescriptionText = styled(TextLight)`
  color: white;
`;

export const Link = styled(MuiLink)`
  font-size: 0.75rem;
  margin-top: 0.5rem;
  margin-bottom: 1.75rem;
  color: #3972E0;
`;
