import React, { ReactElement } from 'react';
import { objectHasKey } from '../../core';
import * as S from './warnings-dialog.styles';

export const ContractWarning = (
  { warnings }: { warnings: any },
): ReactElement => {
  const hasContractWarning = objectHasKey(warnings, 'contract');

  return (
    <div>
      {hasContractWarning && (
        <S.ContractWarning>
          Inform customer that there is a pending contract that has to be approved.
        </S.ContractWarning>
      )}
    </div>
  );
};
