import styled from 'styled-components';
import Typography from '@mui/material/Typography';

// TODO styles this is duplicated, search for it and make reusable
export const Paper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top:${({ theme }) => theme.spacing(8)};
`;

export const Header = styled(Typography)`
  margin-top:${({ theme }) => theme.spacing(2)};
`;

// TODO styles this is duplicated, search for it and make reusable
export const Text = styled(Typography)`
  margin-top:${({ theme }) => theme.spacing(2)};
  text-align: center;
`;

// TODO styles this is duplicated, search for it and make reusable
export const GaaslyLogo = styled.div`
  width: 150px;
  height: 50px;
`;
