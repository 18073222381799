import { useEffect, useState } from 'react';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Stripe, StripeCardElement } from '@stripe/stripe-js';
import {
  deletePaymentMethods,
  fetchBalance,
  fetchStripePaymentMethods,
  fetchStripeSetupIntent,
  makePayment,
} from './api';
import { Balance, PaymentProfile, StripePaymentMethods } from './types';
import { GaaslyResponse, StatefulResponse } from '../../../shared/models';

export const useStripePaymentMethods = (
  trigger: number,
): StatefulResponse<StripePaymentMethods> => {
  const [state, setState] = useState<StatefulResponse<StripePaymentMethods>>({
    isLoading: false,
    response: null,
  });

  useEffect(() => {
    setState({ isLoading: true, response: null });
    fetchStripePaymentMethods().then((response) => {
      setState({ isLoading: false, response });
    });
  }, [trigger]);
  return state;
};

export const useSetupStripePaymentMethod = (submit: number): StatefulResponse<any> => {
  const stripe = useStripe();
  const elements = useElements();

  const cardElement = elements != null ? elements.getElement(CardElement) : null;

  const [state, setState] = useState<StatefulResponse<any>>({
    isLoading: false,
    response: null,
  });

  const setupPaymentProfile = async (stripeI: Stripe, cardElementI: StripeCardElement) => {
    const setupIResponse = await fetchStripeSetupIntent();
    const stripeResponse = await stripeI.confirmCardSetup(setupIResponse.data.clientSecret, {
      payment_method: {
        card: cardElementI,
      },
    });
    if (stripeResponse.error) {
      setState({ isLoading: false, response: GaaslyResponse.getDefaultError() });
    } else {
      setState({ isLoading: false, response: GaaslyResponse.getSuccessWithoutData() });
    }
  };

  useEffect(() => {
    if (submit > 0 && stripe && cardElement) {
      setState({ isLoading: true, response: null });
      setupPaymentProfile(stripe, cardElement);
    }
  }, [submit]);

  return state;
};

export const useDeleteStripePaymentMethods = (
  submit: number,
): StatefulResponse<PaymentProfile> => {
  const [state, setState] = useState<StatefulResponse<PaymentProfile>>({
    isLoading: false, response: null,
  });

  useEffect(() => {
    if (submit > 0) {
      setState({ isLoading: true, response: null });
      deletePaymentMethods().then((response) => {
        setState({ isLoading: false, response });
      });
    }
  }, [submit]);

  return state;
};

export const usePayNow = (trigger: number): StatefulResponse<any> => {
  const [state, setState] = useState<StatefulResponse<any>>({
    isLoading: false,
    response: null,
  });

  useEffect(() => {
    if (trigger > 0) {
      setState({ isLoading: true, response: null });
      makePayment().then((response) => {
        setState({ isLoading: false, response });
      });
    }
  }, [trigger]);

  return state;
};

export const useBalance = (trigger: number): StatefulResponse<Balance> => {
  const [state, setState] = useState<StatefulResponse<Balance>>({
    isLoading: false,
    response: null,
  });

  useEffect(() => {
    setState({ isLoading: true, response: null });
    fetchBalance().then((response) => {
      setState({ isLoading: false, response });
    });
  }, [trigger]);

  return state;
};
