import { createSlice } from '@reduxjs/toolkit';
import { dialogClose } from '../../core/dialog/dialog-slice';
import { RootState } from '../../../app/store';
import { BUDGET_DIALOG_TAG } from '../ui/customer-budget-dialog';

interface CustomerBudgetState {
  isBudgetDialogOpen: boolean;
}

const initialState: CustomerBudgetState = {
  isBudgetDialogOpen: false,
};

export const customerBudgetSlice = createSlice({
  name: 'customerBudget',
  initialState,
  reducers: {
    budgetDialogOpen: (state) => {
      state.isBudgetDialogOpen = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(dialogClose, (state, action) => {
      if (action.payload.tag === BUDGET_DIALOG_TAG) {
        state.isBudgetDialogOpen = false;
      }
    });
  },
});

export const {
  budgetDialogOpen,
} = customerBudgetSlice.actions;

export const selectIsBudgetDialogOpen = (
  state: RootState,
) => state.customerBudget.isBudgetDialogOpen;
