/* Stripe api-legacy types */
export enum PaymentMethod {
  CARD = 'card',
  INVOICE = 'invoice',
}

export type StripeSetupIntent = {
  customer: string,
  clientSecret: string,
}
export type StripePaymentMethods = {
  hasCreditCard: boolean
  object: string,
  data: StripePaymentMethod[],
  hasMore: boolean,
  url: string,
}
export type PaymentProfile = {
  customerId: string,
  stripeCustomer: string,
  paymentMethod: PaymentMethod,
  hasCreditCard: boolean
}

export type Balance = {
  balance: number,
  monthlyBudget: number,
  taxExempt: string,
  vatRequired: boolean,
  vatPercentage: number,
  vatMultiplier: number,
}

export type CustomerPaymentMethod = {
  paymentMethod: PaymentMethod
}

export type StripeAddress = {
  city: string | null,
  country: string | null,
  line1: string | null,
  line2: string | null,
  postalCode: string | null,
  state: string | null,
}

export type StripeBillingDetails = {
  address: StripeAddress,
  email: string | null,
  name: string | null,
  phone: string | null,
}

export enum StripeCardBrand {
  AMEX = 'amex',
  DINERS = 'diners',
  DISCOVER = 'discover',
  JCB = 'jcb',
  MASTERCARD = 'mastercard',
  UNIONPAY = 'unionpay',
  VISA = 'visa',
  UNKNOWN = 'unknown',
}

export type StripeCard = {
  brand: StripeCardBrand | null,
  last4: string | null,
  expMonth: number | null,
  expYear: number | null,
  funding: string | null,
  country: string | null,
}

export type StripePaymentMethod = {
  id: string,
  object: string,
  billingDetails: StripeBillingDetails,
  card: StripeCard
}

// RTKQuery
export type updatePaymentMethodArgs = {
  customerId: string,
  paymentMethod: PaymentMethod,
}
