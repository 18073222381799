import { Notification } from './types';

export const filterNotifications = (
  notifications: Notification[],
): Notification[] => {
  /* Filter out everything older than 7d */
  const now = new Date();
  const WEEK_IN_MILLIS = 7 * 24 * 60 * 60 * 1000;
  const timeWeekAgoInMillis = now.getTime() - WEEK_IN_MILLIS;
  const latestNotifications = notifications.filter((notification) => {
    const createdDate = new Date(notification.createdAt);
    return createdDate.getTime() > timeWeekAgoInMillis;
  });

  // Only one notification per type
  const filtered: Notification[] = [];
  for (let i = 0; i < latestNotifications.length; i += 1) {
    const typesInFiltered = filtered.map((notification) => notification.type);
    const notification = latestNotifications[i];
    if (!typesInFiltered.includes(notification.type)) {
      filtered.push(notification);
    }
  }

  return filtered;
};
