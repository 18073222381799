import React, { ReactElement } from 'react';
import * as S from './styles';

const ColumnField = (
  { header, text }: {
      header: string,
      text: string,
    },
): ReactElement => (
  <div>
    <S.BalanceHeader>{header}</S.BalanceHeader>
    <S.BalanceText>{text}</S.BalanceText>
  </div>
);

export default ColumnField;
