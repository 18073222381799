import React, { ReactElement } from 'react';
import { Logger } from '../../../shared/utils';
import { useAppSelector } from '../../../app/hooks';
import {
  selectIsChargeCreditCardDialogOpen,
  selectIsSendInvoiceDialogOpen,
} from '../customer-dashboard-checkout/customer-checkout-slice';
import { ChargeCreditCardDialog, SendInvoiceDialog } from '../customer-dashboard-checkout';
import { selectIsBudgetDialogOpen } from '../../customer-budget/data/customer-budget-slice';
import { CustomerBudgetDialog } from '../../customer-budget/ui';

interface CustomerDialogCompositeProps {
  customerId: string,
}

export const CustomerDialogComposite: React.FC<CustomerDialogCompositeProps> = (
  { customerId },
): ReactElement => {
  Logger.log('CustomerDialogComposite()');
  const isSendInvoiceDialogOpen = useAppSelector(selectIsSendInvoiceDialogOpen);
  const isChargeCreditCardDialogOpen = useAppSelector(selectIsChargeCreditCardDialogOpen);
  const isBudgetDialogOpen = useAppSelector(selectIsBudgetDialogOpen);
  /*
  It is important that we attach and detach these components from the view tree, for the
  reopening and clearing state of the dialog.
   */
  return (
    <>
      {isSendInvoiceDialogOpen && <SendInvoiceDialog customerId={customerId} />}
      {isChargeCreditCardDialogOpen && <ChargeCreditCardDialog customerId={customerId} />}
      {isBudgetDialogOpen && <CustomerBudgetDialog customerId={customerId} />}
    </>
  );
};
