import React, { ReactElement } from 'react';
import { TextField } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
  customerProfileAdditionalBillingInfoChange,
  selectCustomerProfileAdditionalBillingInfo,
} from '../data/customer-profile-slice';

export const AdditionalBillingInfoInput = (): ReactElement => {
  const dispatch = useAppDispatch();
  const additionalBillingInfo = useAppSelector(selectCustomerProfileAdditionalBillingInfo);

  const onAdditionalBillingInfoChange = (event: any) => {
    dispatch(customerProfileAdditionalBillingInfoChange(event.target.value));
  };

  return (
    <Grid item xs={12}>
      <TextField
        // error={Object.prototype.hasOwnProperty.call(errors, 'additionalBillingInfo')}
        // helperText={errors.additionalBillingInfo || ''}
        name="additional-billing-info"
        variant="outlined"
        fullWidth
        id="additional-billing-info"
        label="Additional Billing Info"
        value={additionalBillingInfo}
        multiline
        minRows={3}
        onChange={onAdditionalBillingInfoChange}
        sx={{
          '& .MuiInputBase-root': {
            backgroundColor: 'white !important', // Using !important to ensure the style is applied
          },
        }}
      />
    </Grid>
  );
};
