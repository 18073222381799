import styled from 'styled-components';
import { Avatar as MAvatar, Button } from '@mui/material';

export const Paper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${({ theme }) => theme.spacing(8)};
`;

export const Avatar = styled(MAvatar)`
  margin: ${({ theme }) => theme.spacing(1)};
  color: ${({ theme }) => theme.palette.secondary.main};
  background-color: ${({ theme }) => theme.palette.primary.main};
`;

// TODO styles this is duplicated, search for it
export const Form = styled.form`
  width: 100%;
  margin: ${({ theme }) => theme.spacing(1)};
`;

export const Submit = styled(Button)`
  margin: ${({ theme }) => theme.spacing(3, 0)};
`;
