import { GaaslyError, GaaslyResponse } from '../models';

export const toCamelCase = (input: string): string => input
  .toLowerCase()
  .replace(/([-_][a-z])/g, (group) => group
    .toUpperCase()
    .replace('-', '')
    .replace('_', ''));

export const toSnakeCase = (input: string): string => input
  .replace(/([A-Z])/g, (group) => `_${group.toLowerCase()}`);

export const parseUrl = (baseUrl: string, queryParams: Map<string, any>): string => {
  if (queryParams.size === 0) {
    return baseUrl;
  }
  const snakeCaseQueryParams = new Map<string, any>();
  queryParams.forEach((value: any, key: string) => {
    snakeCaseQueryParams.set(toSnakeCase(key), value);
  });
  let url = `${baseUrl}?`;
  snakeCaseQueryParams.forEach((value: any, key: string) => {
    if (url.substring(url.length - 1) !== '?') {
      url += '&';
    }
    url += `${key}=${value}`;
  });
  return url;
};

/**
 * @param response: GaaslyResponse | null
 */
export const getResponseErrors = (
  response: GaaslyResponse<any> | null,
): GaaslyError => response?.errorDetails || new GaaslyError(null);

export const strIsEmpty = (str: string): boolean => (!str || str.length === 0);

export const capitalizeFirstLetter = (
  str: string,
): string => str.charAt(0).toUpperCase() + str.slice(1);
