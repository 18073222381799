import React, { ReactElement } from 'react';
import * as S from '../shared-ui/content.styles';

export const StepTimeTracking = (): ReactElement => (
  <>
    <S.Header>
      Time Tracking
    </S.Header>
    <S.Content>
      <ul>
        <S.ContentText>
          <li>
            Log in hours on a daily basis when working. It is important to keep our time tracking
            up
            to date so that other team members are aware of how much of the customer&apos;s budget
            we have already spent.
          </li>
        </S.ContentText>
        <S.ContentText>
          <li>
            Guideline for the internal and the invoiced work is that the invoiced hours should be
            at the minimum 80% of your work time.
          </li>
        </S.ContentText>
        <S.ContentText>
          <li>
            Time entries can be edited and deleted as long as those are not invoiced. Once time
            entry has been invoiced, the entry is locked and can not be edited or deleted.
          </li>
        </S.ContentText>
        <S.ContentText>
          <li>
            Invoicing is done automatically on the last day of the month at 23:00 GMT. Remember to
            check before the end of the month that your time entries are in order.
          </li>
        </S.ContentText>
        <S.ContentText>
          <li>
            Price per hour for time entries is the amount that we charge from customers, not
            your hourly rate. Platform also remembers the previously logged price for each
            customer and suggests this when you choose a customer in time tracking.
          </li>
        </S.ContentText>
        <S.ContentText>
          <li>
            When logging internal hours, use 80€/h price.
          </li>
        </S.ContentText>
      </ul>
    </S.Content>
  </>
);
