import React, { ReactElement, useEffect, useState } from 'react';
import * as S from './styles';
import { NotificationItem } from './notification-item';
import { useNotifications, Notification, filterNotifications } from '../data';

export const NotificationsView = (): ReactElement => {
  const notificationsResponse = useNotifications();
  const [notifications, setNotifications] = useState<Notification[]>([]);

  useEffect(() => {
    if (!notificationsResponse.isLoading && notificationsResponse.response) {
      const filteredNotifications = filterNotifications(notificationsResponse.response.data);
      // const filteredNotifications = filterNotifications(getNotifications());
      setNotifications(filteredNotifications);
    }
  }, [notificationsResponse]);

  const items = notifications.map((notification) => (
    <NotificationItem
      key={notification.uuid}
      notification={notification}
    />
  ));

  if (items.length > 0) {
    return (
      <S.NotificationsContainer>
        {items}
      </S.NotificationsContainer>
    );
  }
  return <div />;
};
