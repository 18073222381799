import React, { ReactElement } from 'react';
import { Logger } from '../../../shared/utils';
import { CustomerProfileView } from './customer-profile-view';
import * as S from './styles';

export const CustomerProfilePage = (): ReactElement => {
  Logger.log('CustomerPage()');
  // TODO onboarding, check if we should remove this...
  return (
    <S.ProfileContainer>
      <CustomerProfileView />
    </S.ProfileContainer>
  );
};
