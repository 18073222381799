import { AlertColor } from '@mui/material/Alert';
import { GaaslyResponse } from './gaasly-response';

export type UserProfile = {
  userId: string,
  firstName: string,
  lastName: string,
  email: string,
  isCustomer: boolean,
  isGrowthPartner: boolean,
  emailChanged: boolean,
  newEmail?: string,
  isOnboardingDone: boolean,
  hasPendingContracts: boolean,
  hasSignedContracts: boolean,
}

export const placeholderUser: UserProfile = {
  userId: '',
  firstName: '',
  lastName: '',
  email: '',
  isCustomer: false,
  isGrowthPartner: false,
  emailChanged: false, // In case email change has been requested in request
  newEmail: undefined, // value for the requested new email address if change was requested
  isOnboardingDone: false,
  hasPendingContracts: false,
  hasSignedContracts: false,
};

export interface GrowthPartnerProfile extends UserProfile {
  growthPartnerId: string;
}

export const placeHolderGrowthPartner: GrowthPartnerProfile = {
  ...placeholderUser,
  growthPartnerId: '',
};

export type StatefulResponse<T> = {
  isLoading: boolean,
  response: GaaslyResponse<T> | null,
}
export type InvitationRegistrationResponse = {
  detail: string,
  email: string,
}

export type SnackbarContent = {
  open: boolean,
  text: string,
  severity: AlertColor,
}
export type InvitationRegistrationInfo = {
  key: string,
  password1: string,
  password2: string
}
export type DetailResponse = {
  detail: string
}
export type Pair<T, S> = {
  key: T,
  value: S,
}
