import React, { ReactElement } from 'react';
import { Alert } from '@mui/lab';
import { ErrorSnackbar } from '../../../shared/ui';
import { StatefulResponse } from '../../../shared/models';

export const RemoveCardErrorSnackbar = (
  { state }: {
   state: StatefulResponse<any>
  },
): ReactElement => (
  <ErrorSnackbar states={[state]}>
    <Alert severity="error">
      Failed to remove the card
    </Alert>
  </ErrorSnackbar>
);
