import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import { dialogClose } from '../../core/dialog/dialog-slice';
import { SEND_INVOICE_DIALOG_TAG } from './send-invoice-dialog';
import { CHARGE_CREDIT_CARD_DIALOG_TAG } from './charge-credit-card-dialog';

interface CustomerCheckoutState {
  isSendInvoiceDialogOpen: boolean;
  isChargeCreditCardDialogOpen: boolean;
}

const initialState: CustomerCheckoutState = {
  isSendInvoiceDialogOpen: false,
  isChargeCreditCardDialogOpen: false,
};

export const customerCheckoutSlice = createSlice({
  name: 'customerCheckout',
  initialState,
  reducers: {
    sendInvoiceDialogOpen: (state) => {
      state.isSendInvoiceDialogOpen = true;
    },
    creditCardChargeDialogOpen: (state) => {
      state.isChargeCreditCardDialogOpen = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(dialogClose, (state, action) => {
        if (action.payload.tag === SEND_INVOICE_DIALOG_TAG) {
          state.isSendInvoiceDialogOpen = false;
        }
        if (action.payload.tag === CHARGE_CREDIT_CARD_DIALOG_TAG) {
          state.isChargeCreditCardDialogOpen = false;
        }
      });
  },
});

export const {
  sendInvoiceDialogOpen,
  creditCardChargeDialogOpen,
} = customerCheckoutSlice.actions;

export const selectIsSendInvoiceDialogOpen = (
  state: RootState,
) => state.customerCheckout.isSendInvoiceDialogOpen;

export const selectIsChargeCreditCardDialogOpen = (
  state: RootState,
) => state.customerCheckout.isChargeCreditCardDialogOpen;

export default customerCheckoutSlice.reducer;
