import React, { ReactElement, ReactNode } from 'react';
import * as S from './styles';

export const CreditCardNumberBlock = (
  { children }: {
      children: ReactNode
    },
): ReactElement => (
  <S.CreditCardNumberBlock>
    {children}
  </S.CreditCardNumberBlock>
);
