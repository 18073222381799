import React from 'react';
import { MutationAction, MutationResult } from '../../api';
import * as S from '../../onboarding/shared-ui/content.styles';
import { CustomerAccountInfoSave } from './customer-account-info-save';
import { CustomerProfileForm } from './customer-profile-form';
import { CustomerDetailProfile } from '../../customers';
import { Logger } from '../../../shared/utils';
import { ProfileEmailChangedDialog } from './profile-email-changed-dialog';

interface CustomerProfileContentProps {
  saveProfile: MutationAction<CustomerDetailProfile>,
  saveProfileRes: MutationResult<CustomerDetailProfile>
  error: any;
}

export const CustomerAccountInfoContent: React.FC<CustomerProfileContentProps> = (
  {
    saveProfile,
    saveProfileRes,
    error,
  },
) => {
  Logger.log('CustomerProfileContent()');
  return (
    <>
      <S.Header>
        Account and Billing Information
      </S.Header>
      <S.Content>
        <S.CenteredText>
          We&apos;ll use this information to issue invoices
          and determine the appropriate tax rate.
        </S.CenteredText>
        <CustomerProfileForm error={error} />
        <CustomerAccountInfoSave
          saveProfile={saveProfile}
          saveProfileRes={saveProfileRes as MutationResult<CustomerDetailProfile>}
        />
      </S.Content>
      <ProfileEmailChangedDialog
        saveResponse={saveProfileRes as MutationResult<CustomerDetailProfile>}
      />
    </>
  );
};
