import styled from 'styled-components';
import { AppBar, Button } from '@mui/material';

export const NavBar = styled(AppBar)`
  background-color: ${({ theme }) => theme.palette.background.default}
`;

export const NavActions = styled.div`
  display: flex;
  margin-left: auto;
  margin-right: 0;
`;

export const NavAction = styled(Button)`
  margin-left: 8px;
  margin-right: 8px;
  color: ${({ theme }) => theme.palette.secondary.contrastText}; // Text color
`;

export const GaaslyLogo = styled.div`
  width: 150px;
  height: 50px;

`;
