import React, { ReactElement } from 'react';
import * as S from './customer-invoice-list-header.styles';
import { Logger } from '../../../shared/utils';

export const CustomerInvoiceListHeader = (
  { listWidth }: {listWidth: number},
): ReactElement => {
  const headerWidth = listWidth;
  return (
    <S.ListHeaderRow width={`${headerWidth}px`}>
      <S.ListHeaderAmountField>AMOUNT</S.ListHeaderAmountField>
      <S.ListHeaderField />
      <S.ListHeaderBigField>INVOICE NUMBER</S.ListHeaderBigField>
      <S.ListHeaderField>DUE</S.ListHeaderField>
      <S.ListHeaderField>CREATED</S.ListHeaderField>
      <S.ListHeaderTinyField />
    </S.ListHeaderRow>
  );
};
