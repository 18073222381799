import React, { ReactElement, useEffect, useState } from 'react';
import {
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
} from '@mui/material';
import Button from '@mui/material/Button';
import { MutationResult } from '../../api';
import { CustomerDetailProfile } from '../../customers';

export const ProfileEmailChangedDialog = (
  { saveResponse }: {
    saveResponse: MutationResult<CustomerDetailProfile>
  },
): ReactElement => {
  const [isOpen, setIsOpen] = useState(false);
  const { newEmail } = saveResponse.data ?? 'Your new email address';

  useEffect(() => {
    if (saveResponse.isSuccess && saveResponse.data.emailChanged) {
      setIsOpen(true);
    }
  }, [saveResponse]);

  const onClose = () => {
    setIsOpen(false);
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Email confirmation</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-email_confirmation">
          To finish changing your email address, we have sent an email to
          {' '}
          {newEmail}
          . Please click the link in email to complete the process.
        </DialogContentText>
        <DialogActions>
          <Button
            onClick={onClose}
            color="primary"
          >
            OK
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};
