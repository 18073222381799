import styled from 'styled-components';
import Typography from '@mui/material/Typography';
import { Button, TextField as MTextField } from '@mui/material';

// TODO styles this is duplicated, search for it and make reusable
export const Paper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing(8)};
`;

// TODO styles this is duplicated, search for it and make reusable
export const Header = styled(Typography)`
  margin-top: ${({ theme }) => theme.spacing(3)};
`;

// TODO styles this is duplicated, search for it and make reusable
export const Text = styled(Typography)`
  margin-top: ${({ theme }) => theme.spacing(2)};
  text-align: center;
`;

// TODO styles this is duplicated, search for it
export const GaaslyLogo = styled.div`
  width: 150px;
  height: 50px;
`;

// TODO styles this is duplicated, search for it
export const Form = styled.form`
  width: 100%;
  margin: ${({ theme }) => theme.spacing(1)};
`;

// TODO styles this is duplicated, search for it
export const TextField = styled(MTextField)`
  margin-top: ${({ theme }) => theme.spacing(2)};
`;

// TODO styles this is duplicated, search for it
export const Submit = styled(Button)`
  margin: ${({ theme }) => theme.spacing(2, 0, 2)};
  color:
`;
