import styled from 'styled-components';
import {
  Button as MButton,
  FormControl as MFormControl,
  FormHelperText,
  Paper,
} from '@mui/material';
import Typography from '@mui/material/Typography';

/* PaymentMethodPage choosing payment method */
export const PaymentMethodView = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Container = styled(Paper)<{ width: string }>`
  width: ${(props) => props.width};
  margin: 1rem auto 1rem auto;
  padding: 1rem;
`;

export const HorizontalFlexBox = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Header = styled.h3`
  margin: 0
`;

export const FormControl = styled(MFormControl)`
  margin: 1rem 0 0 0;
  display: flex;
  text-align: left;
  width: 30%;
`;

export const HelperText = styled(FormHelperText)`
  margin-left: 0.5rem;
`;

export const Text = styled.div`
  width: 80%;
  margin: 0.5rem;
`;

/* CardPaymentMethodView */
export const CardPaymentContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 1rem 0 0 0;
`;

export const Button = styled(MButton)`
  height: 2rem;
  margin: auto;
`;

/* StripeCardSetupView */
export const SetupStripeCardContainer = styled(Paper)`
  display: flex;
  flex-direction: column;
  width: 80%;
  padding: 1rem;
`;

export const RemoveButton = styled(MButton)`
  height: 2rem;
  color: ${({ theme }) => theme.palette.red.main};
  margin: auto 1rem;
`;

/* StripeCardDetailsView */
export const StripeCardDetailsContainer = styled(Paper)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 80%;
  height: 6rem;
  padding: 1rem;
`;

export const SaveButton = styled(MButton)`
  height: 2rem;
  color: ${({ theme }) => theme.palette.green.main};
  margin: auto 1rem;
`;

export const CreditCardNumbers = styled.div`
  display: flex;
  flex-direction: row;
  margin: auto 1rem auto 0;
`;

export const CreditCardNumberBlock = styled(Typography)`
  margin-left: 1rem;
  font-size: 16px;
`;

export const CreditCardLogo = styled.img`
  margin: auto;
`;

/* Balance & Pay now view */
export const PayNowContainer = styled(Paper)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 80%;
  margin: 1rem 0 0 0;
  padding: 1rem;
`;

export const PayNowButton = styled(Button)`
  color: ${({ theme }) => theme.palette.secondary.dark};
  margin: auto 0;
`;

export const BalanceDetailsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 55%;
`;

export const BalanceHeader = styled.h4`
  margin: 0 0 0.5rem 0;
  color: rgb(104, 108, 136);
`;

export const BalanceText = styled(Typography)`
  margin: auto 0.5rem auto 0;
  color: rgb(39, 54, 84);
  font-weight: 450;
`;

export const VatText = styled(Typography)`
  margin: auto 0;
  color: rgb(104, 108, 136);
`;
