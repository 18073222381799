import React, { ReactElement } from 'react';
import { Button } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
  dialogClose,
  DialogResult,
  positiveAction,
  selectDialogNegativeActionText,
  selectDialogPositiveActionText,
  selectDialogTag,
} from './dialog-slice';

export const DialogActionsRowInit = (): ReactElement => {
  const positiveActionText = useAppSelector(selectDialogPositiveActionText);
  const negativeActionText = useAppSelector(selectDialogNegativeActionText);
  const tag = useAppSelector(selectDialogTag);
  const dispatch = useAppDispatch();

  const onPositiveActionClick = () => {
    dispatch(positiveAction({ tag }));
  };
  const onCancelClick = () => {
    dispatch(dialogClose({ tag, result: DialogResult.CANCEL }));
  };

  return (
    <>
      <Button
        variant="contained"
        color="white"
        onClick={onCancelClick}
      >
        {negativeActionText}
      </Button>
      <Button
        variant="contained"
        color="blue"
        onClick={onPositiveActionClick}
      >
        {positiveActionText}
      </Button>
    </>
  );
};
