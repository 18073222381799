import React, { ReactElement } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { isLoggedIn } from '../auth';

// export const RequireAuth: React.FC<CustomRouteProps> = (
//   { children },
// ): ReactElement => {
//   // TODO use prop type. Remove redirectTo and write in here?
//   // Add this to "private routes...
//   Logger.log('RequireAuth()');
//   // TODO router check if we need to call getStoreAuthUser()?
//   const authUser = getStoreAuthUser();
//   return isLoggedIn() ? children : <Navigate to="/login" />;
// };

export const RequireAuth = (
  { children }: { children: ReactElement },
): ReactElement => {
  const location = useLocation();

  if (!isLoggedIn()) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};
