import React, { ReactElement } from 'react';
import * as S from './footer.styles';
import { NextButton } from './next-button';
import { PreviousButton } from './previous-button';

export const Footer = (): ReactElement => (
  <S.FooterContainer>
    <PreviousButton />
    <NextButton />
  </S.FooterContainer>
);
