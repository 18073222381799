import { addMonths, format } from 'date-fns';

/**
 * Return current month and year in form of "September 2024"
 */
export const currentMonthYearVerbose = (): string => {
  const monthNames = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December',
  ];

  const now = new Date();
  const monthName = monthNames[now.getMonth()];
  const year = now.getFullYear();
  return `${monthName} ${year}`;
};

/**
 * Returns monthYear in format of 09-2024 and returns current month as default if delta
 * parameter is not defined.
 * @param delta of the month. Ie. if we are now living 10-2024, then passing -1 would return 09-2024
 */
export const getMonthYear = (delta = 0): string => {
  const now = new Date();
  const newDate = addMonths(now, delta);
  return format(newDate, 'MM-yyyy');
};
