import React, { ReactElement, useEffect, useState } from 'react';
import { GSnackbar } from '../../../shared/ui';
import { SnackbarContent, StatefulResponse } from '../../../shared/models';
import { AuthToken } from '../data';

export const RegisterSnackbar = (
  { state }: { state: StatefulResponse<AuthToken> },
): ReactElement => {
  const [content, setContent] = useState<SnackbarContent>({
    open: true, text: '', severity: 'info',
  });

  const closeNotification = () => {
    setContent({ open: false, text: '', severity: 'info' });
  };

  useEffect(() => {
    if (state.isLoading) {
      setContent({ open: true, text: 'Registering...', severity: 'info' });
    } else if (state.response?.success) {
      setContent({ open: false, text: '', severity: 'info' });
    } else if (!state.response?.success && state.response?.errorDetails.hasNonFieldErrors()) {
      setContent({
        open: true, text: state.response?.errorDetails.getNonFieldError(), severity: 'error',
      });
      setTimeout(closeNotification, 1500);
    } else {
      closeNotification();
    }
  }, [state]);

  return (
    <GSnackbar content={content} />
  );
};
