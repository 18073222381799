import React, { ReactElement, useState } from 'react';
import * as S from '../features/gp-dashboard/ui/styles';
import { GrowthPartnerHolidaysSection, GrowthPartnerStatsSection } from '../features/gp-dashboard';
import { GrowthPartnerProfile } from '../shared/models';
import { GrowthPartnerSearchSelect } from '../features/growth-partner-select';
import { useAuthUser } from '../features/auth/data';

export const GrowthPartnerDashboardPage = (): ReactElement => {
  const authGrowthPartner = useAuthUser() as GrowthPartnerProfile;
  const [growthPartnerFilter, setGrowthPartnerFilter] = useState<
    string>(authGrowthPartner.growthPartnerId);

  const onGrowthPartnerFilterChange = (selectedFilter: string) => {
    setGrowthPartnerFilter(selectedFilter);
  };

  return (
    <S.DashboardPage>
      <S.DashboardTop>
        <GrowthPartnerSearchSelect
          defaultGrowthPartner={authGrowthPartner}
          setGrowthPartnerFilter={onGrowthPartnerFilterChange}
        />
      </S.DashboardTop>

      <GrowthPartnerStatsSection growthPartnerFilter={growthPartnerFilter} />
      <GrowthPartnerHolidaysSection growthPartnerFilter={growthPartnerFilter} />
    </S.DashboardPage>
  );
};
