import React, { ReactElement } from 'react';
import { StatefulDialog } from '../../core/dialog';
import { DialogStatus } from '../../core/dialog/dialog-slice';
import { useCheckoutCustomerMutation } from '../data/customers-api-slice';
import { useInitDialog, useUpdateDialogStatus } from '../../core/dialog/use-dialog';
import { useCheckout } from './use-checkout';

export const CHARGE_CREDIT_CARD_DIALOG_TAG = 'charge-credit-card';

export const ChargeCreditCardDialog = (
  { customerId }: { customerId: string },
): ReactElement => {
  const [checkout, result] = useCheckoutCustomerMutation();

  const dialogDetails = {
    tag: CHARGE_CREDIT_CARD_DIALOG_TAG,
    isOpen: true,
    status: DialogStatus.INITIAL,
    title: 'Charge Credit Card',
    loadingText: 'Processing payment...',
    errorText: 'Credit card payment failed. Please try again later.',
    successText: 'Credit card payment successful.',
    positiveActionText: 'Charge',
    negativeActionText: 'Cancel',
  };

  useCheckout(customerId, checkout);
  useUpdateDialogStatus(result);
  useInitDialog(dialogDetails, CHARGE_CREDIT_CARD_DIALOG_TAG);

  return (
    <StatefulDialog>
      {/* eslint-disable-next-line react/no-unescaped-entities */}
      Charge Customer's credit card. Note that all un-invoiced time entries
      will be automatically included in the charge. Once the time entries are charged, they
      become immutable to maintain accurate billing records.
    </StatefulDialog>
  );
};
