import React, { ReactElement } from 'react';
import { Toolbar } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { logout, useAuthUser } from '../auth';
import { ElevationScroll } from './elevation-scroll';
import { GaaslyRoutes, NonNavBarRouters } from '../routes';
import { CustomerNavBar } from './customer-nav-bar';
import { GrowthPartnerNavBar } from './growth-partner-nav-bar';
import * as S from './styles';
import { useIsNavBar } from './use-is-nav-bar';

export const isNavBarVisible = (url: string): boolean => {
  const index = NonNavBarRouters.indexOf(url);
  const isNavBarUrl = index < 0;
  return isNavBarUrl;
};

export const NavBar = (): ReactElement => {
  const navigate = useNavigate();
  const user = useAuthUser();
  const isVisible = useIsNavBar();

  const onLogout = () => {
    logout().then(() => {
      navigate(GaaslyRoutes.login);
    });
  };

  return (
    <div>
      {user !== null && isVisible
        && (
          <ElevationScroll>
            <S.NavBar>
              <Toolbar>
                <S.GaaslyLogo />

                <S.NavActions>
                  {user.isCustomer && <CustomerNavBar />}
                  {user.isGrowthPartner && <GrowthPartnerNavBar />}
                  <S.NavAction color="inherit" onClick={onLogout}>Logout</S.NavAction>
                </S.NavActions>

              </Toolbar>
            </S.NavBar>
          </ElevationScroll>
        )}
    </div>
  );
};
