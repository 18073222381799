import styled from 'styled-components';
import { Typography } from '@mui/material';

const Tag = styled(Typography)`
  display: inline-block;
  margin: auto;
  padding: 0.3rem;
  font-size: 0.75rem;
  line-height: 0.75rem;
  font-weight: 500;
  border-radius: 5px;
`;

export const GrayTag = styled(Tag)`
  color: #626674;
  background-color: #ECEEF1;
`;

export const BlueTag = styled(Tag)`
  color: #285ab8;
  background-color: #d6f4f5;
`;

export const GreenTag = styled(Tag)`
  color: #377028;
  background-color: #ddf6c7;
`;

export const RedTag = styled(Tag)`
  color: #aa324c;
  background-color: #fbe8f1;
`;

export const GrayTag2 = styled(Tag)`
  color: #626674;
  background-color: #ECEEF1;
`;
