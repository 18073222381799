import styled from 'styled-components';
import { TextLightSmallBold } from '../../theme/text.styles';

export const ListHeaderRow = styled.div<{ width: string }>`
  display: flex;
  flex-direction: row;
  width: ${(props) => `${props.width}`};
  align-items: center;
  justify-content: space-between;
  height: 3rem;
  border-bottom: 1px solid ${({ theme }) => theme.palette.lightGray.main};
`;

export const ListHeaderAmountField = styled(TextLightSmallBold)`
  width: 15%;
  text-align: right;
  margin-right: 1rem;
`;

export const ListHeaderField = styled(TextLightSmallBold)`
  width: 17%;
  margin-left: 1rem;
`;

export const ListHeaderBigField = styled(TextLightSmallBold)`
  margin-left: 1rem;
  width: 25%;
`;

export const ListHeaderTinyField = styled(TextLightSmallBold)`
  margin-left: 1rem;
  width: 7%;
`;
