import styled from 'styled-components';
import { H2 } from '../../theme/text.styles';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1rem 0;
`;

export const DialogHeader = styled(H2)`
  margin-left: 1rem;
`;

export const ContentContainer = styled.div`
  display: flex;
  min-width: 24rem;
  min-height: 8rem;
  margin: 1rem 0;
  padding: 2rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  border-top: 1px solid ${({ theme }) => theme.palette.lightGray.main};
  border-bottom: 1px solid ${({ theme }) => theme.palette.lightGray.main};
  color: ${({ theme }) => theme.palette.lightText.main};
  background: #f6f8fb;
`;

export const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 1rem;
  min-height: 1rem;
  gap: 1rem;
`;
