import React, { ReactElement } from 'react';
import * as S from './sidebar.styles';
import { useWizard } from '../../core';
import { SidebarCompletedItem } from './sidebar-completed-item';
import { SidebarCurrentItem } from './sidebar-current-item';
import { SidebarUpcomingItem } from './sidebar-upcoming-item';

export const Sidebar = (): ReactElement => {
  const { stepMap, activeStepIndex } = useWizard();

  const sidebarElements = Array.from(
    stepMap,
  ).map(([key, title]) => {
    const stepNumber = key + 1;

    if (key < activeStepIndex) {
      return <SidebarCompletedItem>{title}</SidebarCompletedItem>;
    }
    if (key === activeStepIndex) {
      return <SidebarCurrentItem stepNumber={stepNumber}>{title}</SidebarCurrentItem>;
    }
    return <SidebarUpcomingItem stepNumber={stepNumber}>{title}</SidebarUpcomingItem>;
  });

  return (
    <S.SideContainer>
      {sidebarElements}
    </S.SideContainer>
  );
};
