import React, { ReactElement } from 'react';
import { Menu } from '@mui/material';
import { CustomerDetailProfile } from '../data';
import { MenuButton } from './menu-button';
import { Logger } from '../../../shared/utils';
import { useMenuItems } from './use-menu-items';

interface CustomerDashboardMenuProps {
  customer: CustomerDetailProfile
}

// TODO budget, remove the prop drilling and use redux (or context)

export const CustomerDashboardMenu: React.FC<CustomerDashboardMenuProps> = (
  { customer },
): ReactElement => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = () => setAnchorEl(null);

  const menuItems = useMenuItems({ customer, closeMenu });
  Logger.log(`menuItems size ${menuItems.length}`);

  switch (menuItems.length) {
    case 0:
      return (<div />);
    default:
      return (
        <>
          <MenuButton onClick={openMenu} />
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={closeMenu}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            {menuItems}
          </Menu>
        </>
      );
  }
};
