import styled from 'styled-components';
import { Button, FormControl as MFormControl, Typography } from '@mui/material';

export const Header = styled.div`
  margin-top: ${({ theme }) => theme.spacing(8)};
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1.5rem;
`;

export const ProfileView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Form = styled.form`
  width: 100%;
`;

export const Submit = styled(Button)`
    margin: ${({ theme }) => theme.spacing(2, 0, 2)};
`;

export const LeftAlignedText = styled(Typography)`
  font-size: large;
  margin-left: 2rem;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  text-align: left;
`;

export const RightAlignedText = styled(Typography)`
  font-size: large;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  margin-right: 2rem;
  text-align: right;
`;

export const FormControl = styled(MFormControl)`
  margin: 0;
  display: flex;
  text-align: left;
`;
