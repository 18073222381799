import React, { ReactElement } from 'react';
import * as S from './billing-info.styles';
import { RowField } from './billing-info.styles';

export const Row = (
  { field, value }: {field: string, value: string},
): ReactElement => (
  <S.Row>
    <RowField>
      <S.TextLightSmall>{field}</S.TextLightSmall>
    </RowField>
    <S.TextDarkSmallBold>{value}</S.TextDarkSmallBold>
  </S.Row>
);
