import React, { ReactElement, useState } from 'react';
import * as S from './styles';
import { dateToIsoString } from '../../../shared/utils';
import { useStats } from '../data';
import { TimeStatsTop } from './time-stats-top';
import { TimeStatsGrid } from './time-stats-grid';

function getDefaultStartDate(): string {
  const date = new Date();
  const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
  return dateToIsoString(firstDayOfMonth);
}

function getDefaultEndDate() {
  const date = new Date();
  const lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  return dateToIsoString(lastDayOfMonth);
}

export const GrowthPartnerStatsSection = (
  { growthPartnerFilter }: { growthPartnerFilter: string },
): ReactElement => {
  const [startDate, setStartDate] = useState<string>(getDefaultStartDate);
  const [endDate, setEndDate] = useState<string>(getDefaultEndDate);
  const state = useStats(growthPartnerFilter, startDate, endDate);

  return (
    <div>
      <S.PaperContainer>
        <TimeStatsTop
          defaultStartDate={startDate}
          defaultEndDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
        />
        <TimeStatsGrid state={state} />
      </S.PaperContainer>
    </div>
  );
};
