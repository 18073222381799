import React, { ReactElement } from 'react';
import { CustomerDetailProfile } from '../../customers';
import { MutationResult } from '../../api';
import { useCustomerProfile } from '../data/use-customer-profile';
import { CustomerProfileForm } from './customer-profile-form';
import { CustomerAccountInfoSave } from './customer-account-info-save';
import * as S from './styles';
import { ProfileEmailChangedDialog } from './profile-email-changed-dialog';

export const CustomerProfileView = (): ReactElement => {
  const {
    saveProfile,
    saveProfileRes,
    error,
  } = useCustomerProfile();

  return (
    <>
      <S.Header>
        Account and Billing Information
      </S.Header>
      <S.Content>
        <S.ContentText>
          We&apos;ll use this information to issue invoices
          and determine the appropriate tax rate.
        </S.ContentText>
        <CustomerProfileForm error={error} />
      </S.Content>
      {/* <S.Actions> */}
      <CustomerAccountInfoSave
        saveProfile={saveProfile}
        saveProfileRes={saveProfileRes as MutationResult<CustomerDetailProfile>}
      />
      <ProfileEmailChangedDialog
        saveResponse={saveProfileRes as MutationResult<CustomerDetailProfile>}
      />
    </>
  );
};
