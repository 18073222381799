import { ApiMethod, ApiObjectParser, ApiService } from '../../api-legacy';
import {
  Balance, PaymentProfile, StripePaymentMethods, StripeSetupIntent,
} from './types';
import { GaaslyResponse } from '../../../shared/models';
import { getAuthToken } from '../../auth';

const API_URL = process.env.REACT_APP_API_URL;
const PAYMENT_METHODS_URL = `${API_URL}/api/payments/payment-methods/`;
const BALANCE_URL = `${API_URL}/api/payments/balance/`;
const PAYMENT_SETUP_INTENT_URL = `${API_URL}/api/payments/setup-intent/`;
const MAKE_PAYMENT_URL = `${API_URL}/api/payments/charge/`;

export function deletePaymentMethods(): Promise<GaaslyResponse<PaymentProfile>> {
  return new ApiService<PaymentProfile>(`${PAYMENT_METHODS_URL}`)
    .setMethod(ApiMethod.DELETE)
    .setAuth(getAuthToken())
    .setParser(new ApiObjectParser<PaymentProfile>())
    .request();
}

export function fetchBalance(): Promise<GaaslyResponse<Balance>> {
  return new ApiService<Balance>(BALANCE_URL)
    .setMethod(ApiMethod.GET)
    .setAuth(getAuthToken())
    .setParser(new ApiObjectParser<Balance>())
    .request();
}

export function fetchStripePaymentMethods(): Promise<GaaslyResponse<StripePaymentMethods>> {
  return new ApiService<StripePaymentMethods>(PAYMENT_METHODS_URL)
    .setMethod(ApiMethod.GET)
    .setAuth(getAuthToken())
    .setParser(new ApiObjectParser<StripePaymentMethods>())
    .request();
}

export function fetchStripeSetupIntent(): Promise<GaaslyResponse<StripeSetupIntent>> {
  return new ApiService<StripeSetupIntent>(`${PAYMENT_SETUP_INTENT_URL}`)
    .setMethod(ApiMethod.GET)
    .setAuth(getAuthToken())
    .setParser(new ApiObjectParser<StripeSetupIntent>())
    .request();
}

export function makePayment(): Promise<GaaslyResponse<any>> {
  return new ApiService<any>(MAKE_PAYMENT_URL)
    .setMethod(ApiMethod.GET)
    .setAuth(getAuthToken())
    .setParser(new ApiObjectParser<any>())
    .request();
}
