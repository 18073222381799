import { GaaslyError } from './gaasly-error';

export class GaaslyResponse<T> {
  success: boolean;

  status: number;

  data: T;

  errorDetails: GaaslyError;

  constructor(success: boolean, status: number, data: T, error: GaaslyError) {
    this.success = success;
    this.status = status;
    this.data = data;
    this.errorDetails = error;
  }

  isError() {
    const HTTP_ERROR_STATUS = 400;
    if (this.status >= HTTP_ERROR_STATUS) {
      return true;
    }
    return false;
  }

  static getDefaultError(): GaaslyResponse<any> {
    return new GaaslyResponse(
      false,
      400,
      null,
      GaaslyError.getDefaultError(),
    );
  }

  static getSuccessWithoutData(): GaaslyResponse<any> {
    return new GaaslyResponse<any>(
      true,
      200,
      null,
      new GaaslyError(null),
    );
  }
}
