import { Grid } from '@mui/material';
import React, { ReactElement } from 'react';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { CountrySelect } from '../../country-select/ui';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
  customerProfileCountryChange,
  selectCustomerProfileCountry,
} from '../data/customer-profile-slice';
import { getValue, objectHasKey } from '../../core';

export const ProfileCountrySelect = (
  { error }: {
    error: FetchBaseQueryError | SerializedError | undefined
  },
): ReactElement => {
  const dispatch = useAppDispatch();
  const country = useAppSelector(selectCustomerProfileCountry);

  const onCountryChange = (code: string) => {
    dispatch(customerProfileCountryChange(code));
  };

  const errorData = getValue(error, 'data');
  const isError = objectHasKey(errorData, 'country');
  const errorText = getValue(errorData, 'country') as string ?? '';

  return (
    <Grid item xs={12}>
      <CountrySelect
        selectedCountryCode={country}
        setCountryCode={onCountryChange}
        isError={isError}
        errorText={errorText}
      />
    </Grid>
  );
};
