import React, { ReactElement, ReactNode } from 'react';
import { Snackbar } from '@mui/material';
import { MutationResult, QueryResult } from '../../api';

export const RtkQueryErrorSnackbar = <T, >(
  { results, children }: {
    results: [QueryResult<T> | MutationResult<T>],
    children: ReactNode,
  },
): ReactElement => {
  const hasErrors = results.map(
    (result) => result.isError,
  ).includes(true);

  return (
    <Snackbar open={hasErrors}>
      <div>{children}</div>
    </Snackbar>
  );
};
