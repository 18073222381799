import { useEffect } from 'react';
import { useWizard } from '../../core';
import { MutationResult } from '../../api';

export const useNextBlockedHandler = (
  result: MutationResult<any>,
) => {
  const { setIsNextBlocked } = useWizard();
  const {
    isLoading,
    isSuccess,
  } = result;

  useEffect(() => {
    const isBlocked = isLoading || !isSuccess;
    setIsNextBlocked(isBlocked);
    return () => {
      setIsNextBlocked(false);
    };
  }, [result]);
};
