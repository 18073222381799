import React, { ReactElement } from 'react';
import * as S from './styles';
import { Logger } from '../../shared/utils';

export const PrivacyPolicy = (): ReactElement => {
  Logger.log('PrivacyPolicy()');
  return (
    <S.Container>
      <h1>Privacy Policy</h1>
      <S.h2>1. Controller and Contact Information</S.h2>
      <p>
        This web application is operated by Voizy Oy (Gaasly) registered in Finland at Wallininkuja
        4 B 53, 00530 Helsinki. For questions regarding privacy, contact us at
        {' '}
        <a
          href="mailto:jani@gaasly.com"
        >
          jani@gaasly.com
        </a>
      </p>

      <S.h2>2. Data Collection and Use</S.h2>
      <p>We collect only the necessary information for invoicing purposes:</p>
      <ul>
        <li>Personal Information: Name, email, and billing address.</li>
        <li>Billing Information: Details provided for invoicing and payment.</li>
      </ul>
      <p>
        This data is processed exclusively for managing and issuing invoices. No other data is
        collected, and we do not use cookies.
      </p>

      <S.h2>3. Legal Basis for Processing</S.h2>
      <p>
        Processing personal data is necessary to fulfill the contractual obligations associated with
        our invoicing process under GDPR Article 6(1)(b).
      </p>

      <S.h2>4. Data Retention</S.h2>
      <p>
        We retain personal data for as long as necessary to fulfill invoicing obligations and comply
        with legal requirements.
      </p>
      <S.h2>5. Data Sharing</S.h2>
      <p>
        Data is not shared with third parties except as required by law or as needed to process
        transactions through secure payment processors.
      </p>

      <S.h2>6. Data Security</S.h2>
      <p>
        We implement industry-standard security measures to protect user data. However, please be
        aware that no online transmission is 100% secure.
      </p>

      <S.h2>7. Rights of Data Subjects</S.h2>
      <p>
        Under GDPR, you have rights to access, rectify, and request the deletion of your data.
        Contact us at
        {' '}
        <a href="mailto:jani@gaasly.com">jani@gaasly.com</a>
        {' '}
        to exercise these rights.
      </p>

      <S.h2>8. Changes to this Policy</S.h2>
      <p>
        We may update this Privacy Policy as needed to reflect changes in our practices or legal
        requirements. Notifications of any significant changes will be posted within the
        application.
      </p>
    </S.Container>
  );
};
