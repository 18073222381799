import React, { ReactElement, ReactNode } from 'react';
import { useAppSelector } from '../../../app/hooks';
import { DialogStatus, selectDialogStatus } from './dialog-slice';
import { DialogContentLoading } from './dialog-content-loading';
import { DialogContentError } from './dialog-content-error';
import { DialogContentSuccess } from './dialog-content-success';
import { DialogContentInitial } from './dialog-content-initial';

export const DialogContent = (
  { children }: { children: ReactNode },
): ReactElement => {
  const status = useAppSelector(selectDialogStatus);

  let content = <DialogContentInitial>{children}</DialogContentInitial>;

  if (status === DialogStatus.LOADING) {
    content = <DialogContentLoading />;
  }
  if (status === DialogStatus.ERROR) {
    content = <DialogContentError />;
  }
  if (status === DialogStatus.SUCCESS) {
    content = <DialogContentSuccess />;
  }

  return content;
};
