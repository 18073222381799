import { Dispatch, useEffect } from 'react';
import { TimeEntriesActions, TimeEntriesState, TimeEntryActionType } from './reducers';
import { TimeEntry } from './types';
import {
  deleteTimeEntry, fetchGpTimeEntries, logTimeEntry, updateCustomerHourlyPrice, updateTimeEntry,
} from './api';
import { CustomerListProfile } from '../../customers';
import { Logger } from '../../../shared/utils';

export const useSubmitTimeEntries = (
  timeEntry: TimeEntry,
  customer: CustomerListProfile,
  state: TimeEntriesState,
  dispatch: Dispatch<TimeEntriesActions>,
): void => {
  useEffect(() => {
    if (state.create.isLoading) {
      // Round the time entry's timeSpentMinute to upper integer
      const roundedTimeEntry = {
        ...timeEntry,
        timeSpentMinutes: Math.ceil(timeEntry.timeSpentMinutes),
      };

      logTimeEntry(roundedTimeEntry).then((response) => {
        dispatch({ type: TimeEntryActionType.CREATE_DONE, payload: response });
      });
      if (roundedTimeEntry.pricePerHour !== customer.hourlyPriceEur) {
        updateCustomerHourlyPrice(roundedTimeEntry.pricePerHour, customer.customerId);
      }
    }
  }, [state.create]);
};

export const useListTimeEntries = (
  growthPartnerId: string,
  state: TimeEntriesState,
  dispatch: Dispatch<TimeEntriesActions>,
): void => {
  useEffect(() => {
    dispatch({ type: TimeEntryActionType.LOAD_LIST });
  }, []);

  useEffect(() => {
    if (state.list.isLoading) {
      fetchGpTimeEntries(growthPartnerId).then((response) => {
        dispatch({ type: TimeEntryActionType.LOAD_LIST_DONE, payload: response });
      });
    }
  }, [state.list, growthPartnerId]);
};

export const useDeleteTimeEntries = (
  state: TimeEntriesState,
  dispatch: Dispatch<TimeEntriesActions>,
): void => {
  useEffect(() => {
    if (state.delete.isLoading) {
      deleteTimeEntry(state.delete.timeEntry.uuid).then((response) => {
        dispatch({ type: TimeEntryActionType.DELETE_DONE, payload: response });
      });
    }
  }, [state.delete]);
};

export const useEditTimeEntries = (
  state: TimeEntriesState,
  dispatch: Dispatch<TimeEntriesActions>,
): void => {
  useEffect(() => {
    if (state.edit.isLoading) {
      updateTimeEntry(state.edit.timeEntry).then((response) => {
        const action = response.errorDetails.hasErrors()
          ? TimeEntryActionType.EDIT_ERROR : TimeEntryActionType.EDIT_DONE;
        dispatch({ type: action, payload: response });
      });
    }
  }, [state.edit]);
};
