import React, { ReactElement } from 'react';
import HowToRegOutlinedIcon from '@mui/icons-material/HowToRegOutlined';
import { useNavigate } from 'react-router-dom';
import * as SC from '../../core/buttons/button.styles';
import { useWizard } from '../../core';
import { getAuthUserDefaultPath } from '../../routes';
import { useAuthUser } from '../../auth';
import { useOnboardingDoneAndUpdateUserMutation } from '../data';

export const NextButton = (): ReactElement => {
  const authUser = useAuthUser();
  const [onboardingDone, { isLoading }] = useOnboardingDoneAndUpdateUserMutation();
  const navigate = useNavigate();
  const { nextStep, isLastStep, isNextBlocked } = useWizard();

  const handleNext = () => {
    if (isLastStep) {
      handleFinishOnboarding();
    } else {
      nextStep();
    }
  };

  const handleFinishOnboarding = () => {
    onboardingDone().unwrap().then(() => {
      navigate(getAuthUserDefaultPath(authUser));
    });
  };

  return (
    <>
      {!isLastStep
        && (
          <SC.Button
            variant="contained"
            color="blue"
            onClick={() => handleNext()}
            disabled={isNextBlocked}
          >
            Next
          </SC.Button>
        )}
      {isLastStep
        && (
          <SC.LoadingButton
            variant="contained"
            color="blue"
            onClick={() => handleNext()}
            disabled={isNextBlocked}
            loadingPosition="start"
            startIcon={<HowToRegOutlinedIcon />}
            loading={isLoading}
          >
            Finish
          </SC.LoadingButton>
        )}
    </>
  );
};
