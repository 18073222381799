import React, { ReactElement, ReactNode } from 'react';
import * as S from './sidebar.styles';

export const SidebarCompletedItem = ({ children }: { children: ReactNode }): ReactElement => (
  <S.Row>
    <S.CheckedIcon />
    <S.CompletedText>
      {children}
    </S.CompletedText>
  </S.Row>
);
