import React, { ReactElement, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Container, CssBaseline } from '@mui/material';
import { useResetPasswordConfirm } from '../data';
import * as S from './styles';
import { ResetPasswordConfirmSnackbar } from './reset-password-confirm-snackbar';
import { GaaslyRoutes } from '../../routes';
import { GaaslyError } from '../../../shared/models';

type ResetPasswordParams = {
  uid: string
  token: string
}

export const ResetPasswordConfirmView = (): ReactElement => {
  const params = useParams();
  const navigate = useNavigate();

  const resetParams: ResetPasswordParams = JSON.parse(JSON.stringify(params));
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const [reset, setReset] = useState(0);

  const onPassword1Change = (event: any) => setPassword1(event.target.value);
  const onPassword2Change = (event: any) => setPassword2(event.target.value);
  const state = useResetPasswordConfirm(
    resetParams.uid,
    resetParams.token,
    password1,
    password2,
    reset,
  );

  const resetPasswordConfirm = (event: any) => {
    if (event) {
      event.preventDefault();
    }
    setReset(new Date().getTime());
  };

  const openLogin = () => {
    navigate(GaaslyRoutes.login);
  };

  useEffect(() => {
    if (state.response?.success === true) {
      setTimeout(openLogin, 1500);
    }
  }, [state]);

  const getErrorDetails = ():
    GaaslyError => state.response?.errorDetails || new GaaslyError(null);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <S.Paper>
        <S.GaaslyLogo />
        <S.Header variant="h5">
          Reset password
        </S.Header>
        <S.Form
          noValidate
          onSubmit={resetPasswordConfirm}
        >
          <S.TextField
            error={Object.prototype.hasOwnProperty.call(getErrorDetails().getErrors(), 'newPassword1')}
            helperText={getErrorDetails().getErrors().newPassword1 || ''}
            name="newPassword1"
            variant="outlined"
            fullWidth
            id="newPassword1"
            label="New password"
            type="password"
            value={password1}
            onChange={onPassword1Change}
          />
          <S.TextField
            error={Object.prototype.hasOwnProperty.call(getErrorDetails().getErrors(), 'newPassword2')}
            helperText={getErrorDetails().getErrors().newPassword2 || ''}
            name="newPassword2"
            variant="outlined"
            fullWidth
            id="newPassword2"
            label="New password again"
            type="password"
            value={password2}
            onChange={onPassword2Change}
          />
          <S.Submit
            type="submit"
            fullWidth
            variant="contained"
            color="secondary"
          >
            Reset my password
          </S.Submit>
        </S.Form>
      </S.Paper>
      <ResetPasswordConfirmSnackbar state={state} />
    </Container>
  );
};
