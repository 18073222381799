import React, { ReactElement, useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import * as S from './styles';
import { useSignUserContract, UserContract } from '../data';
import { ContractPageSnackbar } from './contract-page-snackbar';
import { UserProfile } from '../../../shared/models';

export const ContractSignSection = (
  { user, userContract, onAgreed }: {
      user: UserProfile,
      userContract: UserContract,
      onAgreed: () => void,
    },
): ReactElement => {
  const [submitTs, setSubmitTs] = useState <number>(0);
  const [isAgreed, setIsAgreed] = useState<boolean>(false);
  const signatureState = useSignUserContract(user.userId, userContract?.uuid || '', isAgreed, submitTs);

  const submitSignature = () => setSubmitTs(new Date().getTime());
  const onIsAgreedChanged = () => {
    setIsAgreed(!isAgreed);
    setSubmitTs(0);
  };

  useEffect(() => {
    if (
      !signatureState.isLoading
        && signatureState.response != null
        && signatureState.response.success) {
      onAgreed();
    }
  }, [signatureState]);

  return (
    <>
      <S.CheckBoxContainer>
        <S.Checkbox
          checked={isAgreed}
          onChange={onIsAgreedChanged}
        />
        <Typography>
          I confirm that I have read and agreed to terms of this
          {' '}
          {userContract?.contract.title}
          .
        </Typography>
      </S.CheckBoxContainer>
      <S.SubmitButton
        type="submit"
        fullWidth
        variant="contained"
        color="secondary"
        onClick={submitSignature}
      >
        Agree
      </S.SubmitButton>
      <ContractPageSnackbar
        signatureState={signatureState}
        isAgreed={isAgreed}
        submitTs={submitTs}
      />
    </>
  );
};
