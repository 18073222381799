import styled from 'styled-components';
import { Button as MuiButton } from '@mui/material';
import { LoadingButton as MuiLoadingButton } from '@mui/lab';

export const Button = styled(MuiButton)`
  height: 2.5rem;
  margin: 1rem;
  padding: 0.5rem;
`;

export const LoadingButton = styled(MuiLoadingButton)`
  height: 2.5rem;
  margin: 1rem;
  padding: 0.5rem;
`;
