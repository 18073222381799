import React, { ReactElement, useEffect, useState } from 'react';
import { CreateTimeEntryResponse } from '../data';
import { CustomerBudgetDialog } from '../../customer-budget';
import { objectHasKey } from '../../core';
import { CustomerActionNeededDialog } from './customer-action-needed-dialog';
import { GaaslyResponse } from '../../../shared/models';

interface TimeTrackingWarningsDialogCompositeArgs {
  create: {
    isLoading: boolean,
    response: GaaslyResponse<CreateTimeEntryResponse> | null,
  }
}

export const TimeTrackingWarningsDialogComposite:
  React.FC<TimeTrackingWarningsDialogCompositeArgs> = (
    { create },
  ): ReactElement => {
    const [isBudgetWarning, setIsBudgetWarning] = useState<boolean>(false);
    const [isCustomerActionNeededWarning, setIsCustomerActionNeededWarning] = useState(false);

    const { response } = create;
    const warnings = response?.data.warnings;

    useEffect(() => {
    // Remove dialogs always when request is sent to 'reset' the dialogs
      if (create.isLoading) {
        setIsBudgetWarning(false);
        setIsCustomerActionNeededWarning(false);
      } else {
      // Budget Warning Dialog open / close
        const isBudgetW = objectHasKey(warnings, 'budget');
        setIsBudgetWarning(isBudgetW);

        // Customer Action Needed Dialog open / close
        const isCustomerActionNeededW = !isBudgetW && warnings;
        setIsCustomerActionNeededWarning(isCustomerActionNeededW);
      }
    }, [create]);

    const customerId = response?.data.timeEntry.customerId ?? '';

    return (
      <>
        {isBudgetWarning && <CustomerBudgetDialog customerId={customerId} />}
        {isCustomerActionNeededWarning && <CustomerActionNeededDialog create={create} />}
      </>
    );
  };
