import React, { ReactElement } from 'react';
import * as S from './styles';
import { CardMissingIndicator } from './card-missing-indicator';
import { ListItemPaymentMethodOption } from './list-item-payment-method-option';

export const ListItemPaymentMethod = (): ReactElement => (
  <S.ListItemPaymentMethod>
    <ListItemPaymentMethodOption />
    <CardMissingIndicator />
  </S.ListItemPaymentMethod>
);
