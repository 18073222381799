import React, { ReactElement } from 'react';
import * as S from './styles';

export const CustomerListHeader = (): ReactElement => (
  <S.CustomerListHeader>
    <S.ListHeaderName>Name</S.ListHeaderName>
    <S.ListHeaderEmail>Email</S.ListHeaderEmail>
    <S.ListHeaderPaymentMethod>Payment Method</S.ListHeaderPaymentMethod>
  </S.CustomerListHeader>
);
