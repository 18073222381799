import { useEffect } from 'react';
import { useAppDispatch } from '../../../app/hooks';
import { isApiErrorResponse } from '../../api/api-slice';
import {
  dialogClose,
  dialogOpen,
  DialogResult,
  dialogStatusUpdate,
  errorTextUpdate,
  getDialogStatus,
} from './dialog-slice';

export const useUpdateDialogStatus = (
  result: any,
) => {
  const dispatch = useAppDispatch();
  const { error } = result;

  useEffect(() => {
    if (isApiErrorResponse(error)) {
      const errorText = 'Error: '.concat(error.data.detail);
      dispatch(errorTextUpdate(errorText));
    }
    const newDialogStatus = getDialogStatus(result);
    dispatch(dialogStatusUpdate(newDialogStatus));
  }, [result]);
};
export const useInitDialog = (
  dialogDetails: any,
  tag: string,
) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(dialogOpen(dialogDetails));

    return () => {
      dispatch(dialogClose({
        tag,
        result: DialogResult.CANCEL,
      }));
    };
  }, []);
};
