import { ApiMethod, ApiService, ApiObjectParser } from '../../api-legacy';
import { ResetPasswordConfirmRequestBody, ResetPasswordRequestBody } from './types';
import { DetailResponse, GaaslyResponse } from '../../../shared/models';

const API_URL = process.env.REACT_APP_API_URL;
const RESET_PASSWORD_URL = `${API_URL}/api/auth/password/reset/`;
const RESET_PASSWORD_CONFIRM_URL = `${API_URL}/api/auth/password/reset/confirm/`;

export function resetPassword(email: string): Promise<GaaslyResponse<DetailResponse>> {
  const payload: ResetPasswordRequestBody = { email };
  return new ApiService<DetailResponse>(RESET_PASSWORD_URL)
    .setMethod(ApiMethod.POST)
    .setRequestBody(payload)
    .setParser(new ApiObjectParser<DetailResponse>())
    .request();
}

export function confirmPasswordReset(
  uid: string,
  token: string,
  newPassword1: string,
  newPassword2: string,
): Promise<GaaslyResponse<DetailResponse>> {
  const payload: ResetPasswordConfirmRequestBody = {
    uid, token, newPassword1, newPassword2,
  };
  return new ApiService<DetailResponse>(RESET_PASSWORD_CONFIRM_URL)
    .setMethod(ApiMethod.POST)
    .setRequestBody(payload)
    .setParser(new ApiObjectParser<DetailResponse>())
    .request();
}
