import React, { ReactElement } from 'react';
import { ListChildComponentProps } from 'react-window';
import { ListItem } from '@mui/material';
import { Invoice } from '../data/types';
import * as S from './customer-invoice-list-item.styles';
import { InvoiceStatusTag } from './invoice-status-tag';

export const CustomerInvoiceListItem = (
  props: ListChildComponentProps<Invoice[]>,
): ReactElement => {
  const { index, style, data } = props;
  const invoice = data[index];

  return (
    <ListItem style={style} key={index} component="div" disablePadding>
      <S.ListItem>
        <S.AmountField>
          {invoice.getFormattedAmount()}
        </S.AmountField>
        <S.Field>
          <InvoiceStatusTag status={invoice.getStatus()} />
        </S.Field>
        <S.BigField>
          {invoice.getInvoiceNumber()}
        </S.BigField>
        <S.Field>
          {invoice.getFormattedDueDate()}
        </S.Field>
        <S.Field>
          {invoice.getFormattedCreatedDate()}
        </S.Field>
        <S.LinkField href={invoice.getInvoicePdfUrl()}>pdf</S.LinkField>
      </S.ListItem>
    </ListItem>
  );
};
