import { createTheme } from '@mui/material/styles';
import { green, red } from '@mui/material/colors';

export const theme = createTheme({
  palette: {
    primary: { main: '#5771E5' },
    // primary: { main: '#F5AD3E' },
    secondary: { main: '#FFFFFF', contrastText: '#1E170B' },
    white: { main: '#FFFFFF', contrastText: '#344767' },
    red: { main: red[700], contrastText: '#FAFAFA' },
    green: { main: green[700] },
    blue: {
      // main: blue[700],
      main: '#5771E5',
      contrastText: '#FAFAFA',
    },
    background: {
      default: '#FAFAFA',
      // default: '#ECEFF3',
    },
    // New stylings
    darkText: { main: '#344767' },
    lightText: { main: '#7b809a' },
    lightGray: { main: '#ECEFF3' },
  },
  components: {
    MuiPaper: {
      defaultProps: {
        elevation: 2,
      },
    },
  },
  shape: {
    borderRadius: 8,
  },
});

declare module '@mui/material/styles' {
  interface Palette {
    white: Palette['primary'];
    red: Palette['primary'];
    green: Palette['primary'];
    blue: Palette['primary'];
    darkText: Palette['primary'];
    lightText: Palette['primary'];
    lightGray: Palette['primary'];
  }

  interface PaletteOptions {
    white: PaletteOptions['primary'];
    red: PaletteOptions['primary'];
    green: PaletteOptions['primary'];
    blue: PaletteOptions['primary'];
    darkText: PaletteOptions['primary'];
    lightText: PaletteOptions['primary'];
    lightGray: PaletteOptions['primary'];
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    blue: true;
    red: true;
    white: true;
    darkText: true;
  }
}
