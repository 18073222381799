import React, { ReactElement } from 'react';
import { StepWelcome } from './step-welcome';
import { Footer } from '../shared-ui/footer';
import * as S from './onboarding.styles';
import { Sidebar } from '../shared-ui/sidebar';
import { Wizard } from '../../core';
import { StepTimeTracking } from './step-time-tracking';
import { StepCustomerRegistration } from './step-customer-registration';
import { StepContract } from '../shared-ui/step-contract';

const onboardingSteps = (): Map<number, string> => {
  const stepMap = new Map<number, string>();
  stepMap.set(0, 'Welcome!');
  stepMap.set(1, 'Time Tracking');
  stepMap.set(2, 'Customer Registration');
  stepMap.set(3, 'Subcontractor Agreement');
  return stepMap;
};

export const GrowthPartnerOnboarding = (): ReactElement => {
  const steps = onboardingSteps();
  return (
    <S.WizardContainer>
      <Wizard
        footer={<Footer />}
        header={<Sidebar />}
        stepMap={steps}
      >
        <StepWelcome />
        <StepTimeTracking />
        <StepCustomerRegistration />
        <StepContract title="Subcontractor Agreement" />
      </Wizard>
    </S.WizardContainer>
  );
};
