import camelcaseKeys from 'camelcase-keys';
import { ApiParser } from './api-parser';
import { GaaslyError, GaaslyResponse } from '../../shared/models';

export class ApiObjectParser<T> extends ApiParser<T> {
  async parseResponse(response: Response): Promise<GaaslyResponse<T>> {
    let apiObject = await this.parseAPIObject(response);

    if (!response.ok) {
      const error = this.parseError(apiObject);
      return new GaaslyResponse(false, response.status, apiObject, error);
    }

    apiObject = camelcaseKeys(apiObject, { deep: true });
    const modelObject: T = apiObject as T;
    return new GaaslyResponse<T>(true, response.status, modelObject, new GaaslyError(null));
  }
}
