/* eslint-disable implicit-arrow-linebreak */
import React, { ReactElement } from 'react';
import * as S from './warnings-dialog.styles';

export const BillingInfoWarning = (
  { warnings }: { warnings: any },
): ReactElement => {
  const { contract, ...rest } = warnings;
  const infoWarnings = Object.keys(rest);
  const formatted = infoWarnings.map((field) =>
    // Split the string at each uppercase letter and replace underscores with spaces
    field.replace(/_/g, ' ').replace(/([A-Z])/g, ' $1')
      // Trim the result and split into words
      .trim().split(' ')
      // Capitalize the first letter of each word and join them with a space
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' '));

  return (
    <div>
      {formatted.length > 0 && (
        <>
          <S.SubHeader>
            Ask customer to correct the following information:
          </S.SubHeader>
          <S.FieldList>
            {formatted.map((item) => (
              <S.StyledListItem key={item}>
                <S.StyledListItemText>
                  {item}
                </S.StyledListItemText>
              </S.StyledListItem>
            ))}
          </S.FieldList>
        </>
      )}
    </div>
  );
};
