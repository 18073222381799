import React, { ReactElement, ReactNode } from 'react';
import { Dialog as MuiDialog } from '@mui/material';
import { DialogTitle } from './dialog-title';
import { DialogContent } from './dialog-content';
import { useAppSelector } from '../../../app/hooks';
import { selectDialogIsOpen } from './dialog-slice';
import * as S from './dialog.styles';
import { DialogActionsRow } from './dialog-actions-row';

export const StatefulDialog = (
  { children }: { children: ReactNode },
): ReactElement => {
  const isOpen = useAppSelector(selectDialogIsOpen);

  return (
    <MuiDialog open={isOpen}>
      <S.Container>
        <DialogTitle />
        <DialogContent>
          {children}
        </DialogContent>
        <DialogActionsRow />
      </S.Container>
    </MuiDialog>
  );
};
