import React, { Dispatch, ReactElement } from 'react';
import { IconButton } from '@mui/material';
import { GridCellParams } from '@mui/x-data-grid';
import { TimeEntriesActions, TimeEntryActionType } from '../data';

export const TimeEntryGridActionButton = (
  {
    params, dispatch, icon, action,
  }: {
    params: GridCellParams,
    dispatch: Dispatch<TimeEntriesActions>,
    icon: ReactElement,
    action: 'edit'| 'delete'
  },
): ReactElement => {
  const dispatchAction = action === 'edit'
    ? TimeEntryActionType.EDIT_OPEN : TimeEntryActionType.DELETE_OPEN;

  const onClick = () => {
    const clickedId = params.id as string;
    dispatch({ type: dispatchAction, payload: clickedId });
  };

  return (
    <IconButton onClick={onClick} size="large">
      {icon}
    </IconButton>
  );
};
