import React, { ReactElement } from 'react';
import * as S from './styles';

export const TimeStatsTop = (
  {
    defaultStartDate, defaultEndDate, setStartDate, setEndDate,
  }: {
    defaultStartDate: string,
    defaultEndDate: string,
    setStartDate: (date: string) => void,
    setEndDate: (date: string) => void,
  },
): ReactElement => {
  const onStartDateChange = (event: any) => {
    setStartDate(event.target.value);
  };
  const onEndDateChange = (event: any) => {
    setEndDate(event.target.value);
  };

  return (
    <S.TimeStatsTop>
      <S.SmallHeader variant="caption">
        Time Stats
      </S.SmallHeader>

      <S.DatePicker
        id="start-date"
        label="Start Date"
        variant="outlined"
        type="date"
        InputLabelProps={{
          shrink: true,
        }}
        size="small"
        defaultValue={defaultStartDate}
        onChange={onStartDateChange}
      />
      <S.DatePicker
        id="end-date"
        label="End Date"
        variant="outlined"
        type="date"
        InputLabelProps={{
          shrink: true,
        }}
        size="small"
        defaultValue={defaultEndDate}
        onChange={onEndDateChange}
      />
    </S.TimeStatsTop>
  );
};
