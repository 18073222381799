import styled from 'styled-components';
import { TextDark, TextLight } from '../../theme/text.styles';

export const ListItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 3rem;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.palette.lightGray.main};
`;

export const AmountField = styled(TextDark)`
  width: 15%;
  text-align: right;
  font-weight: 500;
  margin-right: 1rem;
`;

export const Field = styled(TextDark)`
  width: 17%;
  margin-left: 1rem;
`;
export const BigField = styled(TextDark)`
  margin-left: 1rem;
  width: 25%;
`;

export const LinkField = styled.a`
  margin-left: 1rem;
  width: 7%;
  text-align: left;
`;
