import { ApiCollectionParser, ApiMethod, ApiService } from '../../api-legacy';
import { Notification } from './types';
import { GaaslyResponse } from '../../../shared/models';
import { getAuthToken } from '../../auth/data';

const API_URL = process.env.REACT_APP_API_URL;
const NOTIFICATIONS_URL = `${API_URL}/api/notifications/`;

export const fetchNotifications = ():
  Promise<GaaslyResponse<Notification[]>> => new ApiService<Notification[]>(NOTIFICATIONS_URL)
  .setMethod(ApiMethod.GET)
  .setAuth(getAuthToken())
  .setParser(new ApiCollectionParser<Notification[]>())
  .request();
