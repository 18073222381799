import React, { ReactElement } from 'react';

import { Divider, List } from '@mui/material';
import { HolidayEntry, HolidayStats, useHolidays } from '../data';
import * as S from './styles';
import { formatDate, roundToOneDecimal } from '../../../shared/utils';
import { StatefulResponse } from '../../../shared/models';

function getHolidayStats(holidaysState: StatefulResponse<HolidayStats>): ReactElement {
  let accumulatedHolidays = '-';
  let usedHolidays = '-';
  let holidayBalance = '-';
  if (holidaysState.response) {
    accumulatedHolidays = `${roundToOneDecimal(
      holidaysState.response?.data.accumulatedHolidays ?? 0,
    )}d`;
    usedHolidays = `${roundToOneDecimal(
      holidaysState.response?.data.usedHolidays ?? 0,
    )}d`;
    holidayBalance = `${roundToOneDecimal(
      holidaysState.response?.data.holidayBalance ?? 0,
    )}d`;
  }

  return (
    <S.HolidayStatsGrid>
      <S.DescriptionText variant="body1">
        Accumulated
      </S.DescriptionText>
      <S.DescriptionText variant="body1">
        Used
      </S.DescriptionText>
      <S.DescriptionText variant="body1">
        Balance
      </S.DescriptionText>
      <S.ValueText variant="body1">
        {accumulatedHolidays}
      </S.ValueText>
      <S.ValueText variant="body1">
        {usedHolidays}
      </S.ValueText>
      <S.ValueText variant="body1">
        {holidayBalance}
      </S.ValueText>
    </S.HolidayStatsGrid>
  );
}

function holidayEntryToListItem(entry: HolidayEntry): ReactElement {
  return (
    <S.HolidaysListItem button={false as any}>
      <S.ListItemLeft>
        {formatDate(new Date(entry.workDoneDate))}
      </S.ListItemLeft>
      <S.ListItemMiddle>
        {entry.firstName}
        {' '}
        {entry.lastName}
      </S.ListItemMiddle>
      <S.ListItemRight>
        {entry.timeSpentMinutes / 60}
        h
      </S.ListItemRight>
    </S.HolidaysListItem>
  );
}

function getHolidayList(holidaysState: StatefulResponse<HolidayStats>): ReactElement {
  const upcomingHolidays = holidaysState.response?.data.upcomingHolidays ?? [];
  const listItems = upcomingHolidays.map(holidayEntryToListItem);
  const listItemsWithDividers: ReactElement[] = [];
  listItems.forEach((item, index) => {
    listItemsWithDividers.push(<Divider />);
    listItemsWithDividers.push(item);
  });
  return (
    <S.HolidaysListContainer>
      <List>
        {listItemsWithDividers}
      </List>
    </S.HolidaysListContainer>
  );
}

export const GrowthPartnerHolidaysSection = (
  { growthPartnerFilter }: { growthPartnerFilter: string },
): ReactElement => {
  const holidaysState = useHolidays(growthPartnerFilter);

  return (
    <div>
      <S.PaperContainer>
        <S.SmallHeader variant="caption">Holidays</S.SmallHeader>
        {getHolidayStats(holidaysState)}
      </S.PaperContainer>
      <S.PaperContainer>
        <S.SmallHeader variant="caption">
          Upcoming Holidays
        </S.SmallHeader>
        {getHolidayList(holidaysState)}
      </S.PaperContainer>

    </div>
  );
};
