import React, { ReactElement, useEffect, useState } from 'react';
import * as S from './styles';
import { CreditCardDetailsInput } from './credit-card-details-input';
import { RemoveCard } from './remove-card';
import { RemoveCardErrorSnackbar } from './remove-card-error-snackbar';
import { useDeleteStripePaymentMethods } from '../data';
import { StripePaymentMethod } from '../data/types';

export const StripeCardDetails = (
  { stripePaymentMethod, onReloadStripePaymentMethods } : {
      stripePaymentMethod: StripePaymentMethod,
      onReloadStripePaymentMethods: () => void,
    },
): ReactElement => {
  const [submitRemove, setSubmitRemove] = useState<number>(0);
  const removeState = useDeleteStripePaymentMethods(submitRemove);

  const onRemove = () => {
    setSubmitRemove(new Date().getTime());
  };

  useEffect(() => {
    if (!removeState.isLoading && removeState.response?.success) {
      onReloadStripePaymentMethods();
    }
  }, [removeState]);

  return (
    <S.CardPaymentContainer>
      <CreditCardDetailsInput card={stripePaymentMethod.card} />
      <RemoveCard onClick={onRemove} disabled={removeState.isLoading} />
      <RemoveCardErrorSnackbar state={removeState} />
    </S.CardPaymentContainer>
  );
};

export default StripeCardDetails;
