import React, { ReactElement } from 'react';
import * as S from '../shared-ui/content.styles';
import { useAuthUser } from '../../auth';

export const StepWelcome = (): ReactElement => {
  const authUser = useAuthUser();
  return (
    <>
      <S.Header>
        Welcome!
      </S.Header>
      <S.Content>
        <S.MarginedTextSmall>
          Welcome to the team
          {' '}
          {authUser?.firstName}
          .
        </S.MarginedTextSmall>
        <S.MarginedTextSmall>
          Next we have few steps for you to familiarize yourself with Gaasly&apos;s platform. The
          Platform is designed to help us handle timetracking, invoicing and keeping track of the
          key metrics.
        </S.MarginedTextSmall>
        <S.MarginedTextSmall>
          Carefully read through the next steps to understand the key features in the Platform and
          also your responsibilities regarding it.
        </S.MarginedTextSmall>
        <S.MarginedTextSmall>
          At the end of the onboarding there is subcontractor agreement for you. Take your time,
          download it, carefully read it through and once done, tick the checkbox as a sign of
          agreement (this does not need to be completed in the same session). If you have any
          questions regarding the subcontractor agreement, please reach out to us.
        </S.MarginedTextSmall>
      </S.Content>
    </>
  );
};
