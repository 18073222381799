import React, { ReactElement } from 'react';
import * as S from './styles';
import VisaIcon from '../../../images/visa.png';
import MasterCardIcon from '../../../images/master_card.png';
import { StripeCardBrand } from '../data/types';

export const CreditCardIcon = (
  { brand }: {brand: StripeCardBrand | null},
): ReactElement => {
  switch (brand) {
    case StripeCardBrand.VISA: {
      return <S.CreditCardLogo src={VisaIcon} alt="Credit card logo" width={30} height={10} />;
    }
    case StripeCardBrand.MASTERCARD: {
      return <S.CreditCardLogo src={MasterCardIcon} alt="Credit card logo" width={30} height={21} />;
    }
    default: {
      return <div />;
    }
  }
};
