import React, { useEffect, useRef, useState } from 'react';
import lodash from 'lodash';
import { fetchGrowthPartners } from './api';
import { GrowthPartnerProfile, StatefulResponse } from '../../../shared/models';

export const useGrowthPartners = (
  searchKeyword: string,
): StatefulResponse<GrowthPartnerProfile[]> => {
  const [state, setState] = useState<StatefulResponse<GrowthPartnerProfile[]>>({
    isLoading: false,
    response: null,
  });

  // TODO growth partners add the search keyword
  const delayedSearch = useRef(
    lodash.debounce((keyword) => {
      fetchGrowthPartners().then((response) => {
        setState({ isLoading: false, response });
      });
    }, 500),
  );
  useEffect(() => {
    setState({ isLoading: true, response: null });
    delayedSearch.current(searchKeyword);
  }, [searchKeyword]);
  return state;
};
