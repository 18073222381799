import { BehaviorSubject, Subject } from 'rxjs';
import { useEffect, useState } from 'react';
import {
  GaaslyResponse,
  GrowthPartnerProfile,
  InvitationRegistrationInfo,
  InvitationRegistrationResponse,
  UserProfile,
} from '../../../shared/models';
import {
  fetchUserProfile,
  invitationRegister,
  login as loginRequest,
  logout as logoutRequest,
  register as registerRequest,
} from './api';
import {
  getStoreAuthToken,
  getStoreAuthUser,
  hasValidAuthToken,
  removeAuthToken,
  removeAuthUser,
  storeAuthToken,
  storeAuthUser,
} from './token-store';
import { AuthToken, RegistrationInfo } from './types';
import { CustomerDetailProfile } from '../../customers';

const authenticatedUserSubject:
  Subject<UserProfile | CustomerDetailProfile | null> = new BehaviorSubject<
  UserProfile | CustomerDetailProfile | null>(getStoreAuthUser());
const authUserObservable = authenticatedUserSubject.asObservable();

export const useAuthUser = (): null | UserProfile | CustomerDetailProfile => {
  const [authUser, setAuthUser] = useState<null | UserProfile | CustomerDetailProfile>(
    getStoreAuthUser,
  );
  useEffect(() => {
    const subscription = authUserObservable.subscribe((userProfile) => {
      setAuthUser(userProfile);
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [authUser]);
  return authUser;
};

export const updateAuthDetails = async (authToken: string) => {
  storeAuthToken(authToken);
  const profileResponse = await loadAuthUser();
  return profileResponse;
};

export async function loadAuthUser():
  Promise<GaaslyResponse<GrowthPartnerProfile | CustomerDetailProfile | UserProfile>> {
  const profileResponse = await fetchUserProfile();
  if (profileResponse.success) {
    updateAuthUser(profileResponse.data);
  }
  return profileResponse;
}

/**
 * This is a customer register method using email & password
 * @param user
 * @param invitation whether user has been invitated to register. Default = false
 */
export async function customerRegister(
  user: RegistrationInfo,
): Promise<GaaslyResponse<AuthToken>> {
  const registerResponse: GaaslyResponse<AuthToken> = await registerRequest(user);
  return registerResponse;
}

export async function inviteRegister(
  data: InvitationRegistrationInfo,
): Promise<GaaslyResponse<InvitationRegistrationResponse>> {
  const registerResponse:
    GaaslyResponse<InvitationRegistrationResponse> = await invitationRegister(data);
  return registerResponse;
}

/**
 * This is a customer login using email & password
 * @param email
 * @param password
 */
export async function customerLogin(
  email: string,
  password: string,
): Promise<GaaslyResponse<AuthToken>> {
  const loginResponse: GaaslyResponse<AuthToken> = await loginRequest(email, password);
  if (loginResponse.success) {
    storeAuthToken(loginResponse.data.key);
    const profileResponse = await loadAuthUser();
  }
  return loginResponse;
}

export const getAuthToken = (): string => getStoreAuthToken();
export const isLoggedIn = (): boolean => hasValidAuthToken();

/**
 * Sends logout request to backend in case we have valid token and removes authenticated user and
 * stored auth-service token from the local storage.
 */
export async function logout(): Promise<boolean> {
  if (hasValidAuthToken()) {
    // Remove the token from backend if we have valid token
    await logoutRequest();
  }
  logoutClient();
  // Consider logout successful always as we will anyways remove token/user
  return true;
}

/* private */

/**
 * Removes authenticated user and the stored auth-service token, but does not send logout request to
 * backend.
 */
export function logoutClient() {
  removeAuthToken();
  updateAuthUser(null);
}

export const updateAuthUser = (user: UserProfile | CustomerDetailProfile | null) => {
  if (user) {
    storeAuthUser(user);
  } else {
    removeAuthUser();
  }
  authenticatedUserSubject.next(user);
};
